<template>
    <tit-primary :sc="sc" :tit="tit" :titTxt="titTxt"></tit-primary>
    <!-- <div class="mb-70 mb-lg-30">
        <div class="tit-wrap">
            <small>{{ eng }}</small>
            <h2 class="text-60 text-lg-50 text-md-34">{{ kor }}</h2>
        </div>
    </div> -->
</template>

<script>
import { SERVICE_PROGRAM_CATEGORIES } from "@/assets/variables";
import TitPrimary from "@/components/client/dumb/tit-primary.vue";
export default {
    props: {
        category: String,
    },
    components: {
        TitPrimary,
    },
    computed: {
        sc() {
            if (this.category.toUpperCase() == SERVICE_PROGRAM_CATEGORIES.UNIT.value) return "ordinary suport";
            if (this.category.toUpperCase() == SERVICE_PROGRAM_CATEGORIES.TOTAL.value) return "public SUPORT";
            else return;
        },
        tit() {
            if (this.category.toUpperCase() == SERVICE_PROGRAM_CATEGORIES.UNIT.value) return "상시지원";
            if (this.category.toUpperCase() == SERVICE_PROGRAM_CATEGORIES.TOTAL.value) return "공모지원";
            else return;
        },
        titTxt() {
            if (this.category.toUpperCase() == SERVICE_PROGRAM_CATEGORIES.UNIT.value) return "표면실장(SMT), 조립, 검사, 회로설계 등 개별공정단위 지원 담당자컨택 ▶ 스케줄확인(장비+인력) ▶ 지원진행(상시)";
            if (this.category.toUpperCase() == SERVICE_PROGRAM_CATEGORIES.TOTAL.value) return "회로설계, PCB제작, 제조/조립 , 펌웨어, 전시 등 전 과정을 원스톱으로 지원";
            else return;
        },
    },
};
</script>

<style></style>
