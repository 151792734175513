var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "게시판",
      "tabActive": "건의합니다"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "건의합니다"
    }
  }, [_vm.$route.params._proposal ? _c('proposal-view') : _vm.$route.query.mode === 'input' ? _c('proposal-input') : _c('proposal-list')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }