<template>
    <client-page>
        <sub-visual sh="소개" image="/images/about/visual.jpg" />

        <page-section class="page-section--first" subtitle="Vison" title="비전">
            <v-card outlined rounded="pill" max-width="1020" class="mx-auto pa-16 pa-md-40">
                <v-row>
                    <template v-for="item in visions">
                        <v-col cols="12" md="4" :key="item.text">
                            <v-sheet rounded="circle" :color="item.color" class="pa-24 overflow-visible">
                                <v-responsive :aspect-ratio="1 / 1" class="overflow-visible">
                                    <v-sheet rounded="circle" class="font-size-20 text-pre-wrap text-center line-height-16 box-shadow">
                                        <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                            <v-row class="row--lg ma-0">
                                                <v-col cols="12">
                                                    {{ item.text }}
                                                </v-col>
                                            </v-row>
                                        </v-responsive>
                                    </v-sheet>
                                </v-responsive>
                            </v-sheet>
                        </v-col>
                    </template>
                </v-row>
            </v-card>
        </page-section>

        <page-section class="grey lighten-5" title="핵심가치" subtitle="Core Value">
            <v-card rounded="pill" flat class="pa-24 pa-md-42 tit-wrap tit-wrap--lg">
                <v-row justify="center" align="center">
                    <v-col cols="12" md="auto">
                        <div class="tit tit--sm font-weight-bold text-center">슬로건</div>
                    </v-col>
                    <v-col cols="auto">
                        <v-img max-width="406" width="100%" src="/images/about/business/core-value.svg" alt="사람과 사람으로 연결된 하나의 이음공동체 금산" />
                    </v-col>
                </v-row>
            </v-card>
            <div class="core-value-image">
                <v-row justify="center">
                    <template v-for="index in 3">
                        <v-col cols="6" md="4" :key="index">
                            <v-img :src="`/images/about/business/value-${index}.jpg`" class="mx-auto" />
                        </v-col>
                    </template>
                </v-row>
            </div>
        </page-section>

        <page-section title="활성화체계" subtitle="Promotion System">
            <div class="system-title pt-28 pt-md-40 pb-24 pb-md-32">
                마을에서 <span class="primary--text">새로운</span> 나의 역할을 찾다. <span class="primary--text">新</span>활력 금산살이 3⁺
            </div>
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({
        visions: [
            {
                color: "primary lighten-4",
                text: "다양한 일상생활 분야의\r\n주민공동체 활동을 통한\r\n삶의 질 향상",
            },
            {
                color: "secondary lighten-4",
                text: "기존 주민조직 간\r\n연계강화 및 지역사회의\r\n연결망 구축",
            },
            {
                color: "tertiary lighten-4",
                text: "중간지원조직의 체계화와\r\n지원 시스템 확립",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.box-shadow {
    box-shadow: 0 0 14px 0 rgba(9, 20, 48, 0.1);
}
.system-title {
    font-family: "GmarketSans";
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    text-align: center;
}
@media (min-width: 768px) {
    .core-value-image {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -50vw;
            width: 150vw;
            height: 200px;
            background-color: #fff;
        }
    }
    .system-title {
        font-size: 26px;
    }
}
</style>
