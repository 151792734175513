var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "프로그램",
      "tabActive": "프로그램 신청"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('program-view-card', _vm._b({
    scopedSlots: _vm._u([{
      key: "action-btn",
      fn: function () {
        return [_c('program-application-apply-btn', _vm._b({
          attrs: {
            "x-large": "",
            "rounded": "",
            "color": "secondary"
          }
        }, 'program-application-apply-btn', {
          program: _vm.program
        }, false))];
      },
      proxy: true
    }])
  }, 'program-view-card', {
    program: _vm.program
  }, false)), _c('v-divider', {
    staticClass: "grey darken-4 mt-30 mt-md-60 mb-20 mb-md-40",
    staticStyle: {
      "border-top-width": "2px"
    }
  }), _vm._l(_vm.program.subjects, function (subject) {
    return [_c('rental-subject-card', _vm._b({
      key: subject._id,
      staticClass: "mb-20 mb-md-40",
      attrs: {
        "isListItem": "",
        "whiteSpacePreLine": "",
        "readonly": ""
      }
    }, 'rental-subject-card', {
      subject
    }, false))];
  }), _c('v-sheet', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.program.detail)
    }
  })])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }