<template>
    <div class="tit-button__button">
        <v-btn x-small v-if="isApplied" rounded disabled elevation="0">신청완료</v-btn>
        <v-btn x-small v-else-if="isOnGoing" rounded elevation="0" color="primary" @click="goApply">신청하기</v-btn>
        <v-btn x-small v-else rounded disabled elevation="0">마감</v-btn>
        <!-- <button v-if="isApplied" class="button button--small button--disabled min-w120 min-md-wauto">신청완료</button>
        <button v-else-if="isOnGoing" class="button button--small button--primary min-w120 min-md-wauto" @click="goApply">신청하기</button>
        <button v-else class="button button--small button--disabled min-w120 min-md-wauto">마감</button> -->
    </div>
</template>

<script>
export default {
    props: ["program"],
    computed: {
        scope() {
            return this.$store.state.payload.scope || [];
        },
        isOnGoing() {
            return this.program.status == "모집중";
        },
        isAuthorized() {
            return this.scope.includes("organization");
        },
        isApplied() {
            return this.program?._applicants?.includes(this.$store.state.payload._user);
        },
    },
    methods: {
        goApply() {
            if (this.isAuthorized) this.$router.push(`${this.$route.path}/apply`);
            else alert("권한이 없습니다.");
        },
    },
};
</script>

<style></style>
