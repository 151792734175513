<template>
    <client-page>

        <sub-visual sh="게시판" tabActive="공지사항" />

        <page-section :title=" !$route.params._notification ? '공지사항' : ''" class="page-section--first page-section--last">
            <!-- 1. 게시판 리스트: 베이직 스킨 -->
            <notification-list v-if="!$route.params._notification"></notification-list>
            <notification-view v-else></notification-view>
            <!-- 2. 게시판 작성: 베이직 스킨 -->
            <!-- <board-basic-input></board-basic-input> -->
            <!-- 3. 게시판 뷰: 베이직 스킨 -->
            <!-- <board-basic-view></board-basic-view> -->
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
		NotificationList,
		NotificationView,
    },
};
</script>