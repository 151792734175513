var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소개",
      "tabActive": "이용안내"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "이용안내"
    }
  }, [_c('page-section', {
    attrs: {
      "small": ""
    }
  }, [_c('p', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v(_vm._s(_vm.info))])]), _c('page-section', {
    attrs: {
      "small": "",
      "title": "시설이용"
    }
  }, [_c('v-sheet', {
    staticClass: "mb-30 mb-md-50 mb-lg-80"
  }, [_c('div', {
    staticClass: "chip secondary mb-12 mb-md-24"
  }, [_c('p', [_vm._v("일반(개인, 기업) 회원")])]), _c('v-card', {
    attrs: {
      "flat": "",
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16 pa-md-24"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-md": "space-between"
    }
  }, [_c('step', {
    attrs: {
      "idx": "1",
      "title": "회원가입",
      "icon": "/images/sub/about/information/information-icon1-1.svg",
      "color": "secondary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-secondary"
  })]), _c('step', {
    attrs: {
      "idx": "2",
      "title": "기본장비 교육",
      "icon": "/images/sub/about/information/information-icon1-2.svg",
      "color": "secondary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-secondary"
  })]), _c('step', {
    attrs: {
      "idx": "3",
      "title": "멤버십 발급",
      "icon": "/images/sub/about/information/information-icon1-3.svg",
      "color": "secondary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-secondary"
  })]), _c('step', {
    attrs: {
      "idx": "4",
      "title": "보험증서 등록",
      "icon": "/images/sub/about/information/information-icon1-4.svg",
      "color": "secondary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-secondary"
  })]), _c('step', {
    attrs: {
      "idx": "5",
      "title": "장비·프로그램 예약",
      "icon": "/images/sub/about/information/information-icon1-5.svg",
      "color": "secondary"
    }
  })], 1)], 1)])], 1), _c('v-sheet', [_c('div', {
    staticClass: "chip primary mb-12 mb-md-24"
  }, [_c('p', [_vm._v("입주사 및 유관기업")])]), _c('v-card', {
    attrs: {
      "flat": "",
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16 pa-md-24"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-md": "space-between"
    }
  }, [_c('step', {
    attrs: {
      "idx": "1",
      "title": "입주사 선정",
      "icon": "/images/sub/about/information/information-icon2-1.svg",
      "color": "primary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-primary"
  })]), _c('step', {
    attrs: {
      "idx": "2",
      "title": "회원가입",
      "icon": "/images/sub/about/information/information-icon2-2.svg",
      "color": "primary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-primary"
  })]), _c('step', {
    attrs: {
      "idx": "3",
      "title": "기본장비 교육",
      "icon": "/images/sub/about/information/information-icon2-3.svg",
      "color": "primary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-primary"
  })]), _c('step', {
    attrs: {
      "idx": "4",
      "title": "멤버십 발급",
      "icon": "/images/sub/about/information/information-icon2-4.svg",
      "color": "primary"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-primary"
  })]), _c('step', {
    attrs: {
      "idx": "5",
      "title": "장비·시설·프로그램<br class='d-none d-xl-block' /> 예약",
      "icon": "/images/sub/about/information/information-icon2-5.svg",
      "color": "primary"
    }
  })], 1)], 1)])], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm--last",
    attrs: {
      "small": "",
      "title": "멤버십 안내"
    }
  }, [_c('v-sheet', {
    staticClass: "mb-n20 mb-md-n40"
  }, _vm._l(_vm.membership, function (item) {
    return _c('v-card', {
      key: item.title,
      staticClass: "mb-20 mb-md-40",
      attrs: {
        "rounded": "",
        "flat": "",
        "color": item.bgColor
      }
    }, [_c('div', {
      staticClass: "pa-20 pa-md-30 pa-lg-40"
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      class: 'icon-rounded ' + item.color
    }, [_c('v-img', {
      attrs: {
        "src": item.icon,
        "max-width": "100",
        "aspect-ratio": 1 / 1,
        "contain": ""
      }
    })], 1)]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('h5', {
      staticClass: "tit tit--sm mb-6 mb-md-10"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "chip chip--sm white mb-12 mb-md-20"
    }, [_c('p', {
      staticClass: "line-height-15"
    }, [_vm._v(" " + _vm._s(item.sc) + " ")])]), _c('div', {
      staticClass: "pl-16 pl-md-20"
    }, [_vm._l(item.info, function (detail) {
      return _c('p', {
        key: detail,
        staticClass: "bar-txt page-text page-text--lg grey--text line-height-15"
      }, [_vm._v(" - " + _vm._s(detail) + " ")]);
    }), item.infoDetail ? _c('v-sheet', {
      staticClass: "mt-12 mt-lg-20",
      attrs: {
        "color": "transparent",
        "max-width": "700"
      }
    }, [_c('v-row', {
      staticClass: "mx-n8",
      attrs: {
        "no-gutters": ""
      }
    }, _vm._l(item.infoDetail, function (detail) {
      return _c('v-col', {
        key: detail,
        staticClass: "px-8",
        attrs: {
          "cols": "auto"
        }
      }, [_c('p', {
        staticClass: "bar-txt page-text page-text--lg grey--text line-height-15"
      }, [_vm._v(" - " + _vm._s(detail) + " ")])]);
    }), 1)], 1) : _vm._e()], 2)])], 1)], 1)]);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }