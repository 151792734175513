<template>
    <ul class="tab-list tab-list--inline tab-list--underline justify-start mb-60 mb-md-90 mb-lg-120">
        <li :class="{on: category=='unit'}">
            <router-link class="button" to="/service/supports/unit"><span>상시 지원 신청</span></router-link>
        </li>
        <li :class="{on: category=='total'}">
            <router-link class="button" to="/service/supports/total"><span>공모 지원 신청</span></router-link>
        </li>
    </ul>
</template>

<script>
export default {
props : {
    category : {type :String, default : ""},
},
  components: {
  },
};
</script>

<style></style>
