<template>
    <div :class="spacing">
        <div :class="'icon-priamry d-flex align-center justify-center '+size" :style="'background-color:'+ bgColor +';'">
            <v-img :src="icon" contain max-height="72" v-if="size == 'icon-priamry--lg'"></v-img>
            <v-img :src="icon" contain max-height="54" v-else></v-img>
        </div>
    </div>
</template>

<script>

export default{
    props : {
        spacing : {type :String, default : ""},
        size : {type :String, default : ""},
        bgColor : {type :String, default : ""},
        icon : {type :String, default : ""},
    },
	components: {

	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style scoped>
.icon-priamry{
    min-width: 100px;
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
}
.icon-priamry--lg{
    min-width: 120px;
    width: 120px;
    height: 120px;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-priamry{
        min-width: 124px;
        width: 124px;
        height: 124px;
    }
    .icon-priamry--lg{
        min-width: 154px;
        width: 154px;
        height: 154px;
    }
}
@media (min-width:1200px){
}

</style>