<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual visual="제조지원·교육"></sub-visual>
        <!-- 내비게이션 -->
        <service-navigation-row />

        <section class="section">
            <v-container>

                    <service-support-category :category="category" />

                    <service-support-title :category="category" />

                    <div class="board-container">
                        <div role="table" class="board-table board-table--view">
                            <div role="rowgroup">
                                <div role="row">
                                    <div role="columnheader">
                                        <div class="tit-button-wrap">
                                            <div class="tit-button__tit">
                                                {{ program.title }}
                                            </div>
                                            <service-support-status v-bind="{ program }" />
                                        </div>
                                    </div>
                                </div>
                                <div role="row">
                                    <div role="cell">
                                        <ul class="board-view-data">
                                            <li>
                                                신청기간 : <span class="d-inline-block">{{ applicationDuration }}</span>
                                            </li>
                                            <li>
                                                지원기간 : <span class="d-inline-block">{{ serviceDuration }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div role="row" v-if="program.files?.length">
                                    <div role="cell">
                                        <div class="board-view-file">
                                            <span class="head">
                                                <svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 409 513.6" style="enable-background:new 0 0 409 513.6;" xml:space="preserve">
                                                    <path d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82
                                                    c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8
                                                    M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7
                                                    c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"/>
                                                </svg>
                                                첨부파일 :
                                            </span>
                                            <ul class="lists">
                                                <li v-for="file in program.files" :key="file._id" class="ml-20">
                                                    <a :href="file.url" download> {{ file.fileName }} </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div role="row">
                                    <div role="cell">
                                        <div class="board-content">
                                            <div v-html="program.content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="board-buttons d-flex justify-end mt-20 mt-lg-30">
                            <v-btn @click="$router.go(-1)" small outlined color="grey" class="h-lg-40px"><span class="color-grey">List</span></v-btn>
                            <!-- <a class="button button--border-primary" @click="$router.go(-1)"><span>목록</span></a> -->
                            <div v-show="false" class="right">
                                <a class="button button--border" href=""><span>수정</span></a>
                                <a class="button button--border" href=""><span>답글</span></a>
                                <a class="button button--primary" href=""><span>삭제</span></a>
                                <!-- <a class="button button--border" href=""><span>취소</span></a>
                                <a class="button button--primary" href=""><span>확인</span></a> -->
                            </div>
                        </div>
                    </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ServiceSupportTitle from "@/components/client/service/service-support-title.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";
import ServiceSupportStatus from '@/components/client/service/service-support-status.vue';
import ServiceSupportCategory from '@/components/client/service/service-support-category.vue';

export default {
    components: {
        SubVisual,
        ClientPage,
        ServiceSupportTitle,
        ServiceNavigationRow,
        ServiceSupportStatus,
        ServiceSupportCategory,
    },
    props: ["category", "_program"],
    data: () => ({
        program: {},
    }),
    computed: {
        applicationDuration() {
            const { applicationStartsAt, applicationEndsAt } = this.program;

            if (applicationStartsAt && applicationEndsAt) {
                const startsAt = dayjs(applicationStartsAt).format("YYYY.MM.DD");
                const endsAt = dayjs(applicationEndsAt).format("YYYY.MM.DD");

                return `${startsAt} - ${endsAt}`;
            } else return "-";
        },
        serviceDuration() {
            const { serviceStartsAt, serviceEndsAt } = this.program;

            if (serviceStartsAt && serviceEndsAt) {
                const startsAt = dayjs(serviceStartsAt).format("YYYY.MM.DD");
                const endsAt = dayjs(serviceEndsAt).format("YYYY.MM.DD");

                return `${startsAt} - ${endsAt}`;
            } else return "-";
        },
    },
    mounted() {
        this.search().then(this.reroute);
    },
    methods: {
        async search() {
            try {
                const { program } = await api.v1.services.get({ _id: this._program });
                this.program = program;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async reroute() {
            let path = `/service/supports/${this.program?.category?.toLowerCase?.()}/${this._program}`;
            if (this.$route.path != path) this.$router.replace({ path });
        },
    },
};
</script>
