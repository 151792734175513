var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type == 'equipments' ? _c('div', [_c('h4', {
    staticClass: "tit tit--md"
  }, [_vm._v("메이커스 장비예약")]), _c('div', {
    staticClass: "page-text page-text--lg line-height-15 mt-20 mt-md-40",
    domProps: {
      "innerHTML": _vm._s(_vm.rental.equipment.title.replace(/\n/g, '<br>'))
    }
  })]) : _vm.type == 'facilities' ? _c('div', [_c('h4', {
    staticClass: "tit tit--md"
  }, [_vm._v("시설 및 공간예약")]), _c('div', {
    staticClass: "page-text page-text--lg line-height-15 mt-20 mt-md-40",
    domProps: {
      "innerHTML": _vm._s(_vm.rental.facility.title.replace(/\n/g, '<br>'))
    }
  })]) : _vm.type == 'intro' ? _c('div', [_c('div', {
    staticClass: "page-text page-text--lg line-height-15 mt-20 mt-md-40",
    domProps: {
      "innerHTML": _vm._s(_vm.rental.intro.title.replace(/\n/g, '<br>'))
    }
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }