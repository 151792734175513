var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('table-primary', {
    attrs: {
      "caption": ""
    }
  }, [_c('table-row', {
    attrs: {
      "title": "문의제목"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "문의내용",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "이름"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "이름을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer, "name", $$v);
      },
      expression: "form.writer.name"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "이메일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xx-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "이메일을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.email1,
      callback: function ($$v) {
        _vm.email1 = $$v;
      },
      expression: "email1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-combobox', {
    attrs: {
      "prefix": "@",
      "items": _vm.emailItems,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.email2,
      callback: function ($$v) {
        _vm.email2 = $$v;
      },
      expression: "email2"
    }
  })], 1)], 1)], 1), _c('table-row', {
    attrs: {
      "title": "문의내용"
    }
  }, [_c('v-textarea', {
    attrs: {
      "placeholder": "문의 내용을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "agreement-card mt-30 mt-lg-60",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "pa-16 pa-md-20"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 수집 및 이용에 동의합니다.",
      "hide-details": ""
    },
    model: {
      value: _vm.term,
      callback: function ($$v) {
        _vm.term = $$v;
      },
      expression: "term"
    }
  })], 1), _c('v-col', [_c('p', {
    staticClass: "font-size-14 pl-4",
    staticStyle: {
      "color": "#E91E63"
    }
  }, [_vm._v("(필수)")])])], 1)], 1), _c('v-col', {
    staticClass: "d-flex justify-end d-sm-block",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "grey lighten-1"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.privacy.open();
      }
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("내용보기")])])], 1)], 1)], 1)]), _c('v-layout', {
    staticClass: "v-btn--group",
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "rounded": "",
      "dark": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.send
    }
  }, [_c('span', [_vm._v("문의하기")])])], 1), _c('dialog-privacy', {
    ref: "privacy"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }