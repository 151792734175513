<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual visual="제조지원·교육"></sub-visual>
        <service-navigation-row />

        <section class="section">
            <v-container>

                    <section class="page-section">
                        <tit-primary
                        sc="SERVICE"
                        tit="지원서비스 안내"
                        titTxt="스마트소형가전의 설계~개발~제조를 직접 진행합니다. 설계 엔지니어, 소프트웨어, 장비, 인프라를 구축하였고 개발, 제조를 위해 부품선정, 회로설계, 아트웍, 보드제작, 펌웨어개발, 디버깅, 측정평가 지원이 가능합니다.">
                        </tit-primary>

                        <v-row class="row--large">
                            <v-col cols="12" sm="6" md="4">
                                <div class="w-100 h-100 rounded border pa-20 pa-md-30 py-lg-64 d-flex align-center flex-column">
                                    <sub-icon-primary
                                    spacing="mb-14 mb-lg-26"
                                    bgColor="#f7f8fa"
                                    icon="/images/sub/service/about/s1-icon1-1.svg">
                                    </sub-icon-primary>
                                    <p class="page-text color-grey text-center">
                                        가성비 높은 대체부품 적용을 위한 <br class="d-none d-lg-block">
                                        설계, 보드 제작이 가능합니다.
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <div class="w-100 h-100 rounded border pa-20 pa-md-30 py-lg-64 d-flex align-center flex-column">
                                    <sub-icon-primary
                                    spacing="mb-14 mb-lg-26"
                                    bgColor="#f7f8fa"
                                    icon="/images/sub/service/about/s1-icon1-2.svg">
                                    </sub-icon-primary>
                                    <p class="page-text color-grey text-center">
                                        성능, 기능, 가격향상을 위한 재설계, <br class="d-none d-lg-block">
                                        보드 제작이 가능합니다.
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4">
                                <div class="w-100 h-100 rounded border pa-20 pa-md-30 py-lg-64 d-flex align-center flex-column">
                                    <sub-icon-primary
                                    spacing="mb-14 mb-lg-26"
                                    bgColor="#f7f8fa"
                                    icon="/images/sub/service/about/s1-icon1-3.svg">
                                    </sub-icon-primary>
                                    <p class="page-text color-grey text-center">
                                        회로설계, 보드제작은 담당자 문의 <br class="d-none d-lg-block">
                                        후 상시지원으로 가능합니다.
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </section>

                    <section class="page-section page-section--last">
                        <v-row class="row--large">
                            <v-col cols="12" sm="6">
                                <div class="rounded h-100 pa-20 pa-md-30 py-lg-52 px-lg-60 d-flex flex-column flex-md-row align-center" style="background-color: #f7f8fa">
                                    <sub-icon-primary
                                    spacing="mb-14 mb-md-0 mr-md-14 mr-lg-30"
                                    bgColor="#2e60ef"
                                    icon="/images/sub/service/about/s1-icon2-1.svg">
                                    </sub-icon-primary>
                                    <div class="text-center text-md-left">
                                        <h4 class="tit--sm font-weight-bold mb-4 mb-lg-10">
                                            지원제품
                                        </h4>
                                        <p class="page-text color-grey">
                                            스마트소형가전으로 가볍고 작고 저가이며 주기가
                                            빠르고 사용자반응이 적용된 중간기술기반의
                                            아이디어 소형가전입니다. 
                                        </p>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <div class="rounded h-100 pa-20 pa-md-30 py-lg-52 px-lg-60 d-flex flex-column flex-md-row align-center" style="background-color: #f7f8fa">
                                    <sub-icon-primary
                                    spacing="mb-14 mb-md-0 mr-md-14 mr-lg-30"
                                    bgColor="#2e60ef"
                                    icon="/images/sub/service/about/s1-icon2-2.svg">
                                    </sub-icon-primary>
                                    <div class="text-center text-md-left">
                                        <h4 class="tit--sm font-weight-bold mb-4 mb-lg-10">
                                            지원대상
                                        </h4>
                                        <p class="page-text color-grey">
                                            중소기업, 스타트업, 엔지니어 등 스마트 소형가전의
                                            개발,제조를 진행 중 이거나 계획중인 곳으로 구체적
                                            아이템이 있어야 합니다.
                                        </p>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </section>

            </v-container>
        </section>

        <section class="section py-0 py-lg-60 section--support">
            <v-container>
                <div class="rounded rounded-lg-0">
                    <v-row>
                        <v-col cols="12" lg="4" class="color-white">
                            <div class="pa-20 pa-md-30 pa-lg-0 section--supprot__item" style="background-color: #2e60ef">
                                <h3 class="tit font-weight-bold line-height-1 mb-8 mb-lg-14">
                                    지원 방법
                                </h3>
                                <p class="page-text">
                                    지원은 토탈지원과 상시지원이 있습니다.
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="8" class="page-text mt-n10 mt-lg-0">
                            <div class="pa-20 pa-md-30 pa-lg-0 pl-lg-30 pl-xl-60 section--supprot__item" style="background-color: #ebf5ff"> 
                                <div class="mb-10 mb-lg-22">
                                    <div class="rounded-tit d-flex align-center justify-center text-center line-height-1 mb-6 mb-lg-10">
                                        <p class="color-white">토탈지원</p>
                                    </div>
                                    <p class="color-grey">
                                        개발~전조~전시 전 과정을 공모를 통해 선정후 지원합니다. 
                                    </p>
                                </div>
                                <div>
                                    <div class="rounded-tit  d-flex align-center justify-center text-center line-height-1 mb-6 mb-lg-10">
                                        <p class="color-white">상시지원</p>
                                    </div>
                                    <p class="color-grey">
                                        설계 SMT, 제조, 조립, 디버깅, 펌웨어, 부품선정, 컨설팅, 전시 등 기업이 필요한 부분만 담당자 배정에 의해 상시 지원합니다.
                                    </p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>

        <section class="section">
            <v-container>
                    <tit-primary
                    sc="process"
                    tit="초도양산 및 상품화 지원 절차">
                    </tit-primary>

                    <div class="rounded border pa-20 pa-md-30 py-lg-92">
                        <v-img src="/images/sub/service/about/s3-img.jpg" max-width="1156" class="d-none d-md-block w-100 mx-auto"></v-img>
                        <v-img src="/images/sub/service/about/s3-img-mo.jpg" max-width="596" class="d-block d-md-none w-100 mx-auto"></v-img>
                    </div>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";
import TitPrimary from "@/components/client/dumb/tit-primary.vue";
import SubIconPrimary from "@/components/client/sub/sub-icon-primary.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        ServiceNavigationRow,
        TitPrimary,
        SubIconPrimary,
    },
};
</script>

<style scoped>
.rounded-tit{
    min-width: 70px;
    width: 70px;
    height: 28px;
    border-radius: 14px;
    background-color: #2e60ef;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .rounded-tit{
        min-width: 105px;
        width: 105px;
        height: 32px;
        border-radius: 16px;
    }
}
@media (min-width:1200px){
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .section--support{
        background-image: url(/images/sub/service/about/s2-bg.jpg);
        background-position: left 44% center;
    }
    .section--supprot__item{
        background-color: transparent !important;
    }
}
@media (min-width:1300px){
    .section--support{
        background-size: 1920px 100%;
        background-position: center;
    }
}

</style>
