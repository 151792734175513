var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "장비 및 공간",
      "tabActive": "전문제조장비"
    }
  }), _c('page-section', {
    staticClass: "page-section--first pb-40 pb-md-80 pb-lg-100",
    attrs: {
      "title": "전문제조장비"
    }
  }, [_c('div', {
    staticClass: "mb-12 mb-md-20"
  }, [_c('h4', {
    staticClass: "tit"
  }, [_vm._v("장비 구성")])]), _c('v-divider', {
    staticClass: "grey darken-4 mb-12 mb-md-20",
    staticStyle: {
      "border-top-width": "2px"
    }
  }), _c('rental-subject-title', _vm._b({
    attrs: {
      "type": "intro"
    }
  }, 'rental-subject-title', {
    rental: _vm.rental
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--last pt-40 pt-md-80 pt-lg-100 grey lighten-5"
  }, [_c('v-tabs', {
    staticClass: "v-tabs--primary",
    attrs: {
      "color": "secondary"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.equipment, function (item) {
    return _c('v-tab', {
      key: item.title
    }, [_vm._v(_vm._s(item.title))]);
  }), 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.equipment, function (item) {
    return _c('v-tab-item', {
      key: item.title
    }, [_c('v-row', {
      staticClass: "my-n20 my-md-n30"
    }, _vm._l(item.children, function (child) {
      return _c('v-col', {
        key: child.title,
        staticClass: "py-20 py-md-30",
        attrs: {
          "cols": "6",
          "md": "4"
        }
      }, [_c('v-img', {
        staticClass: "w-100 rounded",
        attrs: {
          "src": child.image,
          "max-width": "440",
          "aspect-ratio": 1 / 1
        }
      }), _c('h4', {
        staticClass: "tit mt-12 mt-md-20 text-center"
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 1)], 1);
  }), 1), _c('v-card', {
    staticClass: "mt-30 mt-md-60",
    attrs: {
      "color": "white",
      "flat": "",
      "rounded": "xs"
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-24 py-md-30 py-lg-40"
  }, [_c('p', {
    staticClass: "page-text page-text--lg font-weight-bold secondary--text line-height-1 mb-4 mb-md-8"
  }, [_vm._v("장비사용문의 : 02 - 6388-6192")]), _c('p', {
    staticClass: "page-text grey--text line-height-15"
  }, [_vm._v("전문장비는 관리자 문의 후 지원서비스를 받으실 수 있습니다.")])])]), _c('v-layout', {
    staticClass: "mt-30 mt-md-60",
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "href": "https://edaom.kr/rental/equipments",
      "target": "_blank",
      "x-large": "",
      "rounded": "",
      "color": "secondary"
    }
  }, [_vm._v("전자제조 지원신청")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }