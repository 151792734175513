var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-button__button"
  }, [_vm.isApplied ? _c('v-btn', {
    attrs: {
      "x-small": "",
      "rounded": "",
      "disabled": "",
      "elevation": "0"
    }
  }, [_vm._v("신청완료")]) : _vm.isOnGoing ? _c('v-btn', {
    attrs: {
      "x-small": "",
      "rounded": "",
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": _vm.goApply
    }
  }, [_vm._v("신청하기")]) : _c('v-btn', {
    attrs: {
      "x-small": "",
      "rounded": "",
      "disabled": "",
      "elevation": "0"
    }
  }, [_vm._v("마감")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }