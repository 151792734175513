var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "제조지원·교육"
    }
  }), _c('service-navigation-row'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('service-program-tit'), _c('div', {
    staticClass: "board-container"
  }, [_c('service-program-input', _vm._b({
    model: {
      value: _vm.applicant,
      callback: function ($$v) {
        _vm.applicant = $$v;
      },
      expression: "applicant"
    }
  }, 'service-program-input', {
    user: _vm.user,
    program: _vm.program
  }, false)), _c('div', {
    staticClass: "button-group"
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-lg-200px",
    attrs: {
      "large": "",
      "outlined": "",
      "rounded": "",
      "color": "grey",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "color-grey"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "min-w-120px min-w-lg-200px",
    attrs: {
      "large": "",
      "rounded": "",
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('span', [_vm._v("신청하기")])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }