<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual visual="제조지원·교육"></sub-visual>
        <!-- 내비게이션 -->
        <service-navigation-row />

        <section class="section">
            <v-container>

                <service-support-category :category="category" />

                <service-support-title :category="category" />

                <service-support-description-unit v-if="category == 'unit'" />
                <service-support-description-total v-if="category == 'total'" />                

                <div class="board-container">

                    <service-support-table :programs="programs" :baseIndex="baseIndex" :category="category" />                
                    <div class="scroll-responsive d-sm-none"><span>좌·우로 스크롤하여 내용을 확인해주세요</span><i class="icon icon-scroll"></i></div>
                    
                    <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>

                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import { SERVICE_PROGRAM_CATEGORIES } from "@/assets/variables";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
import ServiceSupportTitle from "@/components/client/service/service-support-title.vue";
import ServiceSupportTable from "@/components/client/service/service-support-table.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";
import ServiceSupportCategory from '@/components/client/service/service-support-category.vue';
import ServiceSupportDescriptionUnit from '@/components/client/service/service-support-description-unit.vue';
import ServiceSupportDescriptionTotal from '@/components/client/service/service-support-description-total.vue';

export default {
    components: {
        SubVisual,
        ClientPage,
        PaginationComponent,
        ServiceSupportTitle,
        ServiceSupportTable,
        ServiceNavigationRow,
        ServiceSupportCategory,
        ServiceSupportDescriptionUnit,
        ServiceSupportDescriptionTotal,
    },
    props: {
        category: { type: String, default: SERVICE_PROGRAM_CATEGORIES.UNIT.value.toLowerCase() },
    },

    computed: {
        filter() {
            return {
                category: this.category.toUpperCase(),
            };
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit);
        },
        baseIndex() {
            return this.totalCount - this.skip;
        },
    },
    data() {
        return {
            programs: [],

            page: +this.$route.query.page || 1,
            limit: 10,
            totalCount: 0,
        };
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        $route() {
            this.reroute().then(this.search);
        },
    },
    methods: {
        async reroute() {
            let path = `/service/supports/${this.category}`;
            if (this.$route.path != path) this.$router.replace({ path });
        },
        async search() {
            try {
                const {
                    summary: { totalCount },
                    programs,
                } = await api.v1.services.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: { ...this.filter },
                });

                this.totalCount = totalCount;
                this.programs = programs;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
