<template>
    <v-card flat v-bind="{ to }" class="rental-subject-card v-card--noactive">
        <v-row>
            <v-col cols="12" md="3">
                <v-img :aspect-ratio="1 / 1" :src="subject?.thumb?.url" max-width="324" class="rounded border w-100">
                    <template #placeholder>
                        <v-overlay absolute z-index="0" opacity=".1"> <v-icon>mdi-image</v-icon> </v-overlay>
                    </template>
                </v-img>
            </v-col>
            <v-col cols="12" md="9">
                <div class="pl-lg-20">
                    <v-row class="row--x-small">
                        <v-col cols="12" sm="">
                            <p class="page-text font-weight-semibold secondary--text mb-4" :class="isListItem ? 'page-text--sm' : ''">
                                {{ subject.code }}
                            </p>
                            <h4 class="tit" :class="isListItem ? 'line-height-15' : 'tit--md'">
                                {{ subject?.name }}
                            </h4>
                        </v-col>
                        <v-col v-if="isListItem && !readonly" cols="12" sm="auto">
                            <v-layout justify-end>
                                <template v-if="disabled">
                                    <v-btn x-large rounded color="secondary" disabled> 사용예약이 중지된 설비입니다 </v-btn>
                                </template>
                                <template v-else-if="!isRentableForUser">
                                    <v-btn x-large rounded color="secondary" disabled> {{ notRentableMessage }} </v-btn>
                                </template>
                                <v-btn v-else x-large rounded color="secondary">사용예약</v-btn>
                            </v-layout>
                        </v-col>
                    </v-row>
                    <v-divider v-if="isListItem" class="mt-16 mt-md-20" />
                    <div class="mb-n4 mt-16 mt-md-24">
                        <info-row-inline v-for="{ title, info } of descriptions" :key="title" v-bind="{ title, info }" :class="{ 'white-space-pre-line': whiteSpacePreLine }" />
                        <p :class="{ 'page-text page-text--lg grey--text': true, 'white-space-pre-line': whiteSpacePreLine }">{{ subject?.desc }}</p>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { initRentalSubject, RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import InfoRowInline from "@/components/client/sub/info-row-inline.vue";
// import ImgsOverlayList from "@/components/client/dumb/imgs-overlay-list.vue";

export default {
    components: {
        InfoRowInline,
        // ImgsOverlayList,
    },
    props: {
        subject: { type: Object, default: initRentalSubject },

        readonly: { type: Boolean, default: false },
        isListItem: { type: Boolean, default: false },
        whiteSpacePreLine: { type: Boolean, default: false },
    },
    computed: {
        ...mapGetters(["isBanned"]),

        disabled() {
            return !this.subject.rental.isActive;
        },

        _equipments__rentable() {
            return this.$store.state.user?.blacksmith?.["_equipments__rentable"] || [];
        },

        isUserFacilityActive() {
            return this.$store.state.user?.blacksmith?.facility?.isActive ?? false;
        },

        isRentableForUser() {
            switch (this.subject?.type) {
                case RENTAL_SUBJECT_TYPES.EQUIPMENTS.value: {
                    if (this.isBanned) return false;
                    return this._equipments__rentable.includes(this.subject._id);
                }
                case RENTAL_SUBJECT_TYPES.FACILITIES.value: {
                    return this.isUserFacilityActive;
                }
                default: {
                    return true;
                }
            }
        },

        notRentableMessage() {
            switch (this.subject?.type) {
                case RENTAL_SUBJECT_TYPES.EQUIPMENTS.value: {
                    if (this.isBanned) return "사용금지";
                    return "장비운영교육 이수가 필요합니다.";
                }

                case RENTAL_SUBJECT_TYPES.FACILITIES.value: {
                    return "예약 권한이 필요합니다.";
                }
            }
        },

        to() {
            if (this.readonly || this.disabled || !this.isRentableForUser) return;
            else return `/rental/${this.subject?.type?.toLowerCase?.()}/${this.subject?._id}`;
        },
        imgs() {
            return (this.subject?.photos || []).map(({ url }) => url);
        },
        descriptions() {
            const items = [
                { title: "사용 장소", info: this.subject?.place },
                // { title: "사용 용도", info: this.subject?.desc },
            ];
            return items.filter(({ info }) => info);
        },
    },
};
</script>

<style lang="scss" scoped>
.rental-subject-card {
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: var(--v-secondary-lighten5) !important;
        color: var(--v-secondary-base) !important;
    }
}

.white-space-pre-line {
    white-space: pre-line;
}
</style>