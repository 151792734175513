var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소개",
      "tabActive": "입주현황"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "입주현황"
    }
  }, [_c('page-section', {
    attrs: {
      "small": "",
      "title": "입주신청 개요"
    }
  }, [_c('v-divider', {
    staticClass: "grey darken-4 mb-12 mb-md-20",
    staticStyle: {
      "border-top-width": "2px"
    }
  }), _vm._l(_vm.info, function (item) {
    return _c('v-sheet', {
      key: item.title,
      staticClass: "mt-6 mt-md-14"
    }, [_c('v-row', {
      staticClass: "row--x-small"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "page-text page-text--lg font-weight-medium grey--text text--darken-3"
    }, [_vm._v(_vm._s(item.title))])]), _c('v-col', [_c('p', {
      staticClass: "page-text page-text--lg grey--text"
    }, [_vm._v(_vm._s(item.info))])])], 1)], 1);
  })], 2), _c('page-section', {
    attrs: {
      "small": "",
      "title": "지원내용"
    }
  }, [_c('v-row', _vm._l(_vm.support, function (item) {
    return _c('v-col', {
      key: item.icon,
      attrs: {
        "cols": "12",
        "sm": "4"
      }
    }, [_c('v-card', {
      staticClass: "h-100",
      attrs: {
        "flat": "",
        "rounded": "",
        "color": "grey lighten-5"
      }
    }, [_c('v-layout', {
      staticClass: "pa-20 pa-md-30 pa-lg-40 text-center word-keep-all",
      attrs: {
        "column": "",
        "align-center": "",
        "justify-center": "",
        "fill-height": ""
      }
    }, [_c('v-img', {
      staticClass: "mb-12 mb-md-24",
      attrs: {
        "src": item.icon,
        "width": "50",
        "max-height": "50",
        "aspect-ratio": 1 / 1,
        "contain": ""
      }
    }), _c('p', {
      staticClass: "page-text page-text--lg line-height-15",
      domProps: {
        "innerHTML": _vm._s(item.info)
      }
    }), item.caption ? _c('p', {
      staticClass: "page-text grey--text mt-4"
    }, [_vm._v(" " + _vm._s(item.caption) + " ")]) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1), _c('page-section', {
    attrs: {
      "small": "",
      "title": "입주기업 현황"
    }
  }, [_c('company-list')], 1), _c('page-section', {
    staticClass: "page-section--sm--last",
    attrs: {
      "small": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "href": _vm.href,
      "target": "_blank",
      "x-large": "",
      "rounded": "",
      "color": "secondary"
    }
  }, [_vm._v("입주신청")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }