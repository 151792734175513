var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('page-section', {
    attrs: {
      "small": ""
    }
  }, [_c('div', {
    staticClass: "floor-image"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/about/facilities/facilities-img.svg",
      "max-width": "1360",
      "aspect-ratio": 1360 / 350
    }
  }), _c('div', {
    staticClass: "floor-image__btns"
  }, _vm._l(_vm.imageTabs, function (imageTab, index) {
    return _c('button', {
      key: index,
      style: 'width: ' + imageTab.width + '%; height: ' + imageTab.height + '%; top: ' + imageTab.top + '%; left: ' + imageTab.left + '%;',
      on: {
        "click": function ($event) {
          _vm.activeTabIndex = index;
        }
      }
    });
  }), 0)], 1)]), _c('page-section', {
    staticClass: "page-section--sm--last",
    attrs: {
      "small": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "w-lg-420px"
  }, [_c('div', {
    staticClass: "w-100 grey lighten-5 mb-16 mb-lg-24 py-8 px-10",
    staticStyle: {
      "border-radius": "999px"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-error"
  })]), _c('v-col', [_c('p', {
    staticClass: "page-text grey--text text--lighten-1 pl-4"
  }, [_vm._v("해당 위치를 클릭하시면 상세정보를 보실 수 있습니다.")])])], 1)], 1), _c('v-row', {
    staticClass: "flex-lg-row-reverse"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, _vm._l(_vm.infos, function (info, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto",
        "lg": "12"
      }
    }, [_c('btn-idx', {
      attrs: {
        "disabled": "",
        "idx": info.idx,
        "label": info.label,
        "idxStlye": "primary lighten-5 primary--text"
      }
    })], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto",
        "lg": "12"
      }
    }, [_c('btn-idx', {
      attrs: {
        "idx": tab.idx,
        "label": tab.label,
        "idxStlye": "primary white--text"
      },
      on: {
        "click": function ($event) {
          _vm.activeTabIndex = index;
        }
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "lg": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('ul', {
    staticClass: "tab-contents"
  }, _vm._l(_vm.contents, function (content, index) {
    return _c('li', {
      key: index,
      class: {
        on: _vm.activeTabIndex === index
      }
    }, [Array.isArray(content.image) ? [_c('v-row', _vm._l(content.image, function (image) {
      return _c('v-col', {
        key: image,
        attrs: {
          "cols": "6"
        }
      }, [_c('v-img', {
        key: image,
        staticClass: "w-100 rounded-sm",
        attrs: {
          "src": image,
          "max-width": "420",
          "aspect-ratio": 1 / 1
        }
      })], 1);
    }), 1)] : [_c('v-img', {
      staticClass: "w-100 rounded-sm",
      attrs: {
        "src": content.image,
        "max-width": "820",
        "aspect-ratio": 820 / 480
      }
    })], _c('v-divider', {
      staticClass: "secondary border-2 w-40px my-20 my-md-40"
    }), _c('h4', {
      staticClass: "font-size-20 font-size-md-26 font-size-lg-36 font-weight-medium mb-12 mb-md-20"
    }, [_vm._v(_vm._s(content.title))]), _c('p', {
      staticClass: "page-text page-text--lg line-height-15 grey--text word-keep-all",
      domProps: {
        "innerHTML": _vm._s(content.info)
      }
    }), content.details ? _c('v-card', {
      staticClass: "grey lighten-5 mt-12 mt-md-20",
      attrs: {
        "flat": "",
        "rounded": "xs"
      }
    }, [_c('div', {
      staticClass: "pa-16 pa-md-24"
    }, [_c('p', {
      staticClass: "page-text page-text--lg font-weight-bold secondary--text line-height-1 mb-8 mb-md-16"
    }, [_vm._v(" 보유장비 ")]), _c('p', {
      staticClass: "page-text line-height-15 grey--text word-keep-all"
    }, [_vm._v(" " + _vm._s(content.details) + " ")])])]) : _vm._e()], 2);
  }), 0)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }