<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual visual="제조지원·교육"></sub-visual>
        <!-- 내비게이션 -->
        <service-navigation-row />

        <section class="section">
            <v-container>
                <service-support-category :category="category" />

                <service-support-title :category="category" />

                <div>
                    <div class="card card--outline text-center pa-7 pa-md-16">
                        <h3 class="text-28 text-md-22 mb-10">{{ title }} 신청이 완료 되었습니다.</h3>
                        <div class="page-text color-gray-2">
                            <p v-for="(row, i) in description" :key="`description-${i}`">{{ row }}</p>
                            <p class="color-primary">관련문의 : 한국전자정보통신산업진흥회(yes@gokea.org)</p>
                        </div>
                    </div>
                    <div class="board-buttons board-buttons--center">
                        <button class="button button--primary" @click="go">확인</button>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ServiceSupportTitle from "@/components/client/service/service-support-title.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";
import ServiceSupportCategory from "@/components/client/service/service-support-category.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        ServiceSupportTitle,
        ServiceNavigationRow,
        ServiceSupportCategory,
    },
    props: ["category", "_program"],
    computed: {
        title() {
            if (this.category == "edu") return "교육";
            if (this.category == "unit") return "단위지원";
            if (this.category == "total") return "토탈지원";
        },
        description() {
            if (this.category == "edu") return ["관리자 승인 후 교육 참여가 가능합니다.", "신청내역은 마이페이지에서 확인이 가능합니다.", "자세한 내용은 별도 안내를 드리도록 하겠습니다."];
            else return ["관리자 확인 후  자세한 내용은 별도 안내를 드리도록 하겠습니다.", "신청내역은 마이페이지에서 확인이 가능합니다."];
        },
    },
    methods: {
        go() {
            this.$router.push(`/application/${this.category}/${this._program}`);
        },
    },
};
</script>
