<template>
  <div class="rounded pa-20 pa-md-30 py-lg-80 mb-30 mb-lg-60" style="background-color: #f7f8fa">
      <v-img src="/images/sub/service/support/s1-img.png" max-width="1060" contain class="d-none d-md-block mx-auto"></v-img>
      <v-img src="/images/sub/service/support/s1-img-mo.png" max-width="565" contain class="d-block d-md-none mx-auto"></v-img>
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style></style>
