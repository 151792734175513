var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.spacing
  }, [_c('div', {
    class: 'icon-priamry d-flex align-center justify-center ' + _vm.size,
    style: 'background-color:' + _vm.bgColor + ';'
  }, [_vm.size == 'icon-priamry--lg' ? _c('v-img', {
    attrs: {
      "src": _vm.icon,
      "contain": "",
      "max-height": "72"
    }
  }) : _c('v-img', {
    attrs: {
      "src": _vm.icon,
      "contain": "",
      "max-height": "54"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }