var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "mw-md-210px",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "200",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-layout', {
    staticClass: "step",
    style: 'border-color: var(--v-' + _vm.color + '-base)',
    attrs: {
      "column": "",
      "align-center": "",
      "justify-center": ""
    }
  }, [_c('v-img', {
    staticClass: "mb-10",
    attrs: {
      "src": _vm.icon,
      "width": "60",
      "max-height": "60",
      "aspect-ratio": 1 / 1,
      "contain": ""
    }
  }), _c('h6', {
    staticClass: "page-text line-height-15"
  }, [_vm._v("STEP " + _vm._s(_vm.idx))]), _c('p', {
    staticClass: "page-text",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }