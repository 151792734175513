<template>
    <div class="form-input">
        <input class="w-100" @input="sync" v-bind="$attrs" />
    </div>
</template>

<script>
export default {
    methods: {
        sync(event) {
            this.$emit("input", event.target.value);
        },
    },
};
</script>

<style></style>
