<template>
    <div class="board-container">

        <v-row class="row--xx-small">
            <v-spacer />
            <v-col cols="12" md="auto">
                <v-select v-model="filter.searchKey" :items="filterItems" hide-details outlined class="v-input--small w-md-100px" />
            </v-col>
            <v-col md="auto">
                <v-text-field v-model="filter.searchValue" hide-details outlined placeholder="검색어를 입력하세요." class="v-input--small w-md-240px" />
            </v-col>
            <v-col cols="auto">
                <v-btn @click="search" dark color="grey" class="input-btn h-100">검색</v-btn>
            </v-col>
        </v-row>

        <table class="board-list mt-12 mt-md-20">
    		<thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:6%;">번호</th>
                    <th style="width:64%;">게시물 제목이 옵니다.</th>                    
                    <th style="width:15%;">작성자</th>
                    <th style="width:15%;">작성일</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr v-for="(notification, index) in fixed" :key="index">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        <i class="icon icon-info"></i>
                    </td>
                    <td align="center" class="board-list__tit" @click="$router.push(`${$route.path}/${notification._id}`)">
                        <div class="ellip">{{ notification.subject }}</div>
                    </td>
                    <td align="center" class="board-list__txt">
                        관리자
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.createdAt.toDate() }}
                    </td>
                </tr> -->
                <tr v-for="(proposal, index) in proposalList" :key="index">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ (page - 1) * limit + index + 1 }}
                    </td>
                    <td @click="view(proposal)"  align="center" class="board-list__tit">
                        <div class="ellip">{{ proposal.subject }}</div>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ proposal.user.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ proposal.createdAt.toDate() }}
                    </td>
                </tr>
    		</tbody>
    	</table>

        <v-layout justify-end class="mt-24">
            <v-btn color="secondary" dark class="h-40px">
                <span @click="$router.push('/community/proposal?mode=input')" class="font-size-14">문의하기</span>
            </v-btn>
        </v-layout>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" />
        </div>
        <!-- <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component> -->
    </div>
</template>

<script>
import api from "@/api";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
import { mdiAlertCircleOutline } from "@mdi/js";
export default {
    components: {
        PaginationComponent,
    },
    props: {
        value: { type: String, default: null },
    },
    data() {
        return {
            mdiAlertCircleOutline,

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,
            filter: { 
                searchKey: 'subject + content', 
                searchValue: null 
            }, 
            filterItems: [
                { text: "전체", value: "subject + content" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],

            proposalList: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            this.$router.push({
                query: Object.assign({}, this.query, { page: this.page }),
            });

            var { summary, questions } = await api.v1.center.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.proposalList = questions;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);

        },
        view(proposal){
            try{
                if(this.payload._user !== proposal._user) throw new Error("본인이 작성한 글만 열람가능합니다.");
                else this.$router.push(`${this.$route.path}/${proposal._id}`)
            } catch(err){
                alert(err.message);
            }
        }
    },
    computed:{
        payload(){
            return this.$store.state.payload || {};
        },
    }
};
</script>
