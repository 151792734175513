var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "btn-idx w-lg-100 justify-start",
    attrs: {
      "text": "",
      "color": "grey darken-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-btn', _vm.$attrs, false), [_c('span', {
    class: 'btn-idx__idx ' + _vm.idxStlye
  }, [_vm._v(_vm._s(_vm.idx))]), _c('span', {
    staticClass: "btn-idx__label"
  }, [_vm._v(_vm._s(_vm.label))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }