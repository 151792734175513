<template>
    <v-row class="row--xx-small">
        <v-spacer />
        <v-col cols="12" md="auto">
            <v-select v-model="query.searchKey" :items="searchKeys" hide-details outlined class="v-input--small w-md-100px" />
        </v-col>
        <v-col md="auto">
            <v-text-field v-model="query.searchValue" hide-details outlined placeholder="검색어를 입력하세요." class="v-input--small w-md-240px" @keydown.enter="emit" />
        </v-col>
        <v-col cols="auto">
            <v-btn @click="emit" dark color="grey" class="input-btn h-100">검색</v-btn>
        </v-col>
    </v-row>
</template>

<script>
const initQuery = (query = {}) => ({
    ...query,

    isNotice: query.isNotice ?? false,
    sites: query.sites ?? "blacksmith",
    searchKey: query.searchKey ?? "subject + content",
    searchValue: query.searchValue ?? null,
});

const searchKeys = [
    { text: "전체", value: "subject + content" },
    { text: "제목", value: "subject" },
    { text: "내용", value: "content" },
];

export default {
    props: {
        value: { type: Object, default: initQuery },
    },
    data: () => ({
        query: initQuery(),
        searchKeys,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.value);
        },
        emit() {
            this.$emit("input", this.query);
        },
    },
};
</script>

<style>
</style>