<template>
    <client-page>
        <sub-visual sh="장비 및 공간" :tabActive="type == 'equipments' ? '메이커스 장비예약' : '시설 및 공간예약'" />

        <page-section class="page-section--first page-section--last">
            <page-section small>
                <rental-subject-title v-bind="{ rental }" :type="type" />
            </page-section>
            <page-section small class="page-section--sm--last">
                <v-sheet class="mb-30 mb-md-60">
                    <rental-subject-places v-if="type == 'equipments'" color="secondary" />
                    <rental-subject-search :type="type" />
                </v-sheet>
                <template v-for="subject in subjects">
                    <rental-subject-card v-bind="{ subject }" isListItem whiteSpacePreLine class="mt-20 mt-md-40" :key="subject?._id" />
                </template>
                <div class="v-pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </div>
            </page-section>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import RentalSubjectCard from "@/components/client/rental/rental-subject-card.vue";
import RentalSubjectTitle from "@/components/client/rental/rental-subject-title.vue";
import RentalSubjectPlaces from "@/components/client/rental/list/rental-subject-places.vue";
import RentalSubjectSearch from "@/components/client/rental/list/rental-subject-search.vue";

const defaultType = RENTAL_SUBJECT_TYPES.EQUIPMENTS.value.toLowerCase();

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        RentalSubjectCard,
        RentalSubjectTitle,
        RentalSubjectPlaces,
        RentalSubjectSearch,
    },
    props: {
        type: { type: String, default: defaultType },
    },
    data: () => ({
        subjects: [],
        rental: {},

        limit: 10,
        summary: { totalCount: 0 },
    }),
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        headers() {
            let { limit } = this;
            return { skip: (this.page - 1) * limit, limit };
        },
        params() {
            let { query } = this.$route;
            let type = this.type?.toUpperCase?.();

            return { ...query, type, shows: true };
        },
    },
    mounted() {
        this.reroute().then(this.search);
        
        // var HTML = document.querySelector("html");
        // HTML.classList.add("scroll-smooth");
    },
    // destroyed(){
    //     var HTML = document.querySelector("html");
    //     HTML.classList.remove("scroll-smooth");
    // },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            if (!Object.keys(RENTAL_SUBJECT_TYPES).includes(this.type?.toUpperCase?.())) {
                const path = `/rental/${defaultType}`;
                if (this.$route.path !== path) this.$router.replace({ path });
            }
        },
        async search() {
            let { headers, params } = this;
            let { summary, subjects } = await api.v1.rental.subjects.gets({ headers, params });
            this.summary = summary;
            this.subjects = subjects;
            await this.getSetting()
            // document.getElementById("retnal-subject-list-section").scrollIntoView({ behavior: "smooth" });
        },
        async getSetting() {
            let { setting } = await api.v1.setting.get();
            this.rental = setting.rental;
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .theme--light.v-input.v-text-field.search-input {
        height: 48px;
        border-radius: 999px;
        border: 2px solid var(--v-secondary-base);
        .v-input__slot {
            padding: 0 16px;
            input {
                font-size: 1.4rem;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .theme--light.v-input.v-text-field.search-input {
            height: 56px;
            .v-input__slot {
                padding: 0 20px;
                input {
                    font-size: 1.6rem;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>