<template>
    <div v-if="question" class="board-container">
        <div role="table" class="board-table board-table--view">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        {{ question.subject }}
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <ul class="board-view-data">
                            <li>작성일 : {{ question.createdAt.toDate() }}</li>
                            <!-- <li>조회수 : {{ question.viewCount }}</li> -->
                        </ul>
                    </div>
                </div>
                <div v-if="question?.files.length" role="row">
                    <div role="cell">
                        <div class="board-view-file">
                            <span class="head">
                                <svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 409 513.6" style="enable-background: new 0 0 409 513.6" xml:space="preserve">
                                    <path
                                        d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82
                                    c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8
                                    M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7
                                    c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
                                    />
                                </svg>
                                첨부파일 :
                            </span>
                            <ul class="lists">
                                <li v-for="file in question?.files" :key="file.url" class="ml-20">
                                    <a :href="file.url" download>{{ file?.url?.split('/files/')[1] }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <div class="board-content">
                            <div v-html="question.content.replace(/\n/g, '<br>')"></div>
                        </div>
                    </div>
                </div>
                <div role="row" class="grey lighten-5">
                    <div role="cell">
                        <div class="py-16">
                            <v-row align="center" class="row--x-small">
                                <v-col cols="auto">
                                    <span class="mark">A</span>
                                </v-col>
                                <v-col>
                                    <p class="page-text grey--text">
                                        {{question?.reply}}
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-layout justify-end class="mt-24">
            <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                <span class="grey--text font-size-14">리스트</span>
            </v-btn>
        </v-layout>

        <!-- <div v-show="false" role="table" class="board-table board-table--preview">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">이전글</div>
                    <div role="cell">
                        <div class="title"><a href="">이전 게시글 제목입니다.</a></div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">이전글</div>
                    <div role="cell">
                        <div class="title"><a href="">다음 게시글 제목입니다.</a></div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="board-buttons d-flex justify-end mt-20 mt-lg-30">
            <v-btn @click="$router.go(-1)" small outlined color="grey" class="h-lg-40px"><span class="color-grey">List</span></v-btn>
            <a class="button button--border-primary" @click="$router.go(-1)"><span>목록</span></a>
            <div v-show="false" class="right">
                <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>답글</span></a>
                <a class="button button--primary" href=""><span>삭제</span></a>
                <a class="button button--border" href=""><span>취소</span></a>
                <a class="button button--primary" href=""><span>확인</span></a>
            </div>
        </div> -->
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

export default {
    components: {},
    data() {
        return {
            question: null,
        };
    },
    computed: {
        files() {
            return [{ href: this.question?.upload, name: this.question?.upload?.split("/")?.pop?.() }, ...(this.question?.files || [])].filter(({ href }) => href);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        download,
        async init() {
            try {
                var { question } = await api.v1.center.questions.get({ _id: this.$route.params._proposal });
                this.question = question;
                console.log(this.board);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mark{
    width: 24px;
    height: 24px;
    font-weight: 700;
    color: #fff;
    background-color: var(--v-secondary-base);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.4rem;
    border-radius: 50%;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .mark{
        width: 26px;
        height: 26px;
        font-size: 1.6rem;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>

