<template>
    <v-tabs color="secondary" class="v-tabs--primary">
        <template v-for="{ text, to } in items">
            <v-tab v-bind="{ to }" exact :key="text">{{ text }}</v-tab>
        </template>
    </v-tabs>
</template>

<script>
import { PROGRAM_STATES } from "@/assets/variables";

const path = "/program/list";
const items = [
    { to: { path }, text: "전체" },
    { to: { path: `${path}/${PROGRAM_STATES.ON_GOING.value}` }, text: "진행중" },
    { to: { path: `${path}/${PROGRAM_STATES.FINISHED.value}` }, text: "종료" },
];

export default {
    data: () => ({
        items,
    }),
};
</script>

<style>
</style>