<template>
    <div class="form-birth__inner">
        <select class="w-100" v-model="selected" @input="sync">
            <option v-for="option in options" :key="`${option}${mode}`" :value="option">{{ option + mode }}</option>
        </select>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: ["value", "mode", "year", "month"],
    data() {
        return {
            selected: null,
        };
    },
    computed: {
        options() {
            if (this.mode == "년") {
                const years = [];
                let year = dayjs().year();
                do {
                    years.push(year);
                    year -= 1;
                } while (year >= 1900);
                return years;
            }
            if (this.mode == "월") {
                const months = [];
                let month = 1;
                do {
                    months.push(month);
                    month += 1;
                } while (month <= 12);
                return months;
            }
            if (this.mode == "일") {
                const days = [];
                if (this.year && this.month) {
                    let day = 1;
                    const endDay = dayjs(`${this.year}-${String(this.month).padStart(2, 0)}-01`)
                        .endOf("month")
                        .date();
                    do {
                        days.push(day);
                        day += 1;
                    } while (day <= endDay);
                }
                return days;
            }
        },
    },
    methods: {
        init() {
            this.selected = this.value;
        },
        sync() {
            this.$emit("input", this.selected);
        },
    },
    watch: {
        value() {
            if (this.value !== this.selected) this.selected = this.value;
        },
        selected() {
            if (this.selected !== this.value) this.sync();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style></style>
