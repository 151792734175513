<template>
    <v-col cols="12" md="" class="mw-md-210px">
        <v-responsive max-width="200" :aspect-ratio="1 / 1" class="mx-auto">
            <v-layout column align-center justify-center class="step" :style="'border-color: var(--v-'+color+'-base)'">
                <v-img :src="icon" width="60" max-height="60" :aspect-ratio="1 / 1" contain class="mb-10"/>
                <h6 class="page-text line-height-15">STEP {{idx}}</h6>
                <p v-html="title" class="page-text"></p>
            </v-layout>
        </v-responsive>
    </v-col>
</template>

<script>
export default{
    props : {
        idx: { type: String, default: "" },
        title: { type: String, default: "" },
        icon: { type: String, default: "" },
        color: { type: String, default: "" },
    },
	components: {
	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style lang="scss" scoped>
.step{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    padding: 16px;
    text-align: center;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>