<template>
    <v-sheet>
        <page-section small>
            <div class="floor-image">
                <v-img src="/images/sub/about/facilities/facilities-img2.svg" max-width="1360" :aspect-ratio="1360 / 350" class="w-100"/>
                <div class="floor-image__btns">
                    <button v-for="(imageTab, index) in imageTabs" :key="index"
                    :style="'width: '+imageTab.width+'%; height: '+imageTab.height+'%; top: '+imageTab.top+'%; left: '+imageTab.left+'%;'"
                    @click="activeTabIndex = index"></button>      
                    <button style="width: 3.529412%; height: 17%; top: 45.71429%; left: 23.16176%;" @click="activeTabIndex = 8"></button>
                </div>                              
            </div>
        </page-section>
        <page-section small class="page-section--sm--last">
            <v-row class="row--x-large">
                <v-col cols="12" lg="auto">
                    <v-sheet class="w-lg-420px">
                        <div class="w-100 grey lighten-5 mb-16 mb-lg-24 py-8 px-10" style="border-radius: 999px">
                            <v-row no-gutters align="center">
                                <v-col cols="auto">
                                    <i class="icon icon-error" />
                                </v-col>
                                <v-col>
                                    <p class="page-text grey--text text--lighten-1 pl-4">해당 위치를 클릭하시면 상세정보를 보실 수 있습니다.</p>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row class="flex-lg-row-reverse">
                            <v-col cols="12" lg="6">
                                <v-row class="row--small">
                                    <v-col v-for="(info, index) in infos" :key="index" cols="auto" lg="12">
                                        <btn-idx disabled :idx="info.idx" :label="info.label" idxStlye="secondary lighten-5 secondary--text" />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-row class="row--small">
                                    <v-col v-for="(tab, index) in tabs" :key="index" cols="auto" lg="12">
                                        <btn-idx :idx="tab.idx" :label="tab.label" idxStlye="secondary white--text" @click="activeTabIndex = index" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col lg="auto" class="d-none d-lg-block">
                    <v-divider vertical />
                </v-col>
                <v-col cols="12" lg="">
                    <ul class="tab-contents">
                        <li v-for="(content, index) in contents" :key="index" :class="{ on: activeTabIndex === index }">
                            <template v-if="Array.isArray(content.image)">
                                <v-row>
                                    <v-col cols="6" v-for="image in content.image" :key="image">
                                        <v-img :key="image" :src="image" max-width="420" :aspect-ratio="1 / 1" class="w-100 rounded-sm" />
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-img :src="content.image" max-width="820" :aspect-ratio="820 / 480" class="w-100 rounded-sm" />
                            </template>
                            <v-divider class="secondary border-2 w-40px my-20 my-md-40" />
                            <h4 class="font-size-20 font-size-md-26 font-size-lg-36 font-weight-medium mb-12 mb-md-20">{{content.title}}</h4>
                            <div class="mb-2">
                                <v-row class="row--x-small">
                                    <v-col cols="auto">
                                        <p class="page-text page-text--lg font-weight-medium grey--text text--darken-3">목적</p>
                                    </v-col>
                                    <v-col>
                                        <p class="page-text page-text--lg grey--text">{{content.purpose}}</p>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mb-2">
                                <v-row class="row--x-small">
                                    <v-col cols="auto">
                                        <p class="page-text page-text--lg font-weight-medium grey--text text--darken-3">기능</p>
                                    </v-col>
                                    <v-col>
                                        <p class="page-text page-text--lg grey--text">{{content.function}}</p>
                                    </v-col>
                                </v-row>
                            </div>
                            <div>
                                <v-row class="row--x-small">
                                    <v-col cols="auto">
                                        <p class="page-text page-text--lg font-weight-medium grey--text text--darken-3">구성</p>
                                    </v-col>
                                    <v-col>
                                        <p class="page-text page-text--lg grey--text">{{content.composition}}</p>
                                        <p v-if="content.caption" class="page-text page-text--lg grey--text text--lighten-1 mt-4">{{content.caption}}</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </page-section>
    </v-sheet>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";
import BtnIdx from "@/components/client/sub/btn-idx.vue";
export default {
    components: {
        PageSection,
        BtnIdx,
    },
    data() {
        return {
            activeTabIndex: 0,
            imageTabs: [
                { width: '22.64705882', height: '25.71428571', top: '28.57142857', left: '65.58823529' },
                { width: '11.47058824', height: '20', top: '65.42857143', left: '76.76470588' },
                { width: '13.97058824', height: '20', top: '65.42857143', left: '43.38235294' },
                { width: '3.529411765', height: '25.71428571', top: '28.57142857', left: '57.35294118' },
                { width: '5.294117647', height: '25.71428571', top: '28.57142857', left: '38.23529412' },
                { width: '13.82352941', height: '25.71428571', top: '28.57142857', left: '43.52941176' },
                { width: '4.411764706', height: '13.71428571', top: '1.714285714', left: '51.83823529' },
                { width: '5.588235294', height: '40.57142857', top: '45.71428571', left: '12.20588235' },
                { width: '4.411764706', height: '13.71428571', top: '1.714285714', left: '58.82352941' },
            ],
            tabs: [
                { idx: 'A', label: '전자제조 장비룸1' },
                { idx: 'B', label: '전자제조 장비룸2' },
                { idx: 'C', label: '회의룸' },
                { idx: 'D', label: '오픈 사무공간' },
                { idx: 'E', label: '오픈 조립장' },
                { idx: 'F', label: '코워킹 라운지' },
                { idx: 'G', label: '오픈 실험실' },
                { idx: 'H', label: '교육장' },
                { idx: 'I', label: '스튜디오' },
            ],
            infos: [                
                { idx: '1', label: '서버실' },
                { idx: '2', label: '용산구 창업지원센터' },
                { idx: '3', label: '기자재실' },
                { idx: '4', label: '자재룸' },
                { idx: '5', label: '유틸룸' },
                { idx: '6', label: '측정평가실' },
                { idx: '7', label: '313호' },
                { idx: '8', label: '오픈카페' },
                { idx: '9', label: '세미나실 322호' },
                { idx: '10', label: '입주공간 324호' },
                { idx: '11', label: '입주공간 325호' },
                { idx: '12', label: '입주공간 304호' },
                { idx: '13', label: '입주공간 305호' },
                { idx: '14', label: '한국전자정보통신 산업진흥회' },                
                { idx: '15', label: '상담실' },
            ],
            contents: [
                {
                    title: '전자제조 장비룸1',
                    image: '/images/sub/about/facilities/facilities-3f-1.jpg',
                    purpose: '스타트업, 중소기업 제조지원',
                    function: '전자보드 초도양산, 제조장비구축',
                    composition: '전자제품 제조장비(40종)',
                    caption: '연 150개 전자보드 제조, 생산지원',
                },
                {
                    title: '전자제조 장비룸2',
                    image: '/images/sub/about/facilities/facilities-3f-2.jpg',
                    purpose: '스타트업, 중소기업 제조지원',
                    function: '측정평가장비, 3D모델링장비 등',
                    composition: '3D모델링장비(10종), 측정평가 맟 검사장비(10종)',
                },
                {
                    title: '회의룸',
                    image: '/images/sub/about/facilities/facilities-3f-3.jpg',
                    purpose: '입주기업 회의/편의 공간제공',
                    function: '비즈니스, 코워킹 가능 공간',
                    composition: '3실(6인실 2개, 8인실 1개)',
                },
                {
                    title: '오픈 사무공간',
                    image: '/images/sub/about/facilities/facilities-3f-4.jpg',
                    purpose: '창업, 중소기업 업무공간 제공',
                    function: '방문기업 증가, 지원 활성화',
                    composition: '1인 사무공간(17대)',
                },
                {
                    title: '오픈 조립장',
                    image: '/images/sub/about/facilities/facilities-3f-5.jpg',
                    purpose: '주변 소공인, 방문객 공간제공',
                    function: 'PC조립 관련 편의, 노하우 제공',
                    composition: '조립 작업대(12대)',
                },
                {
                    title: '코워킹 라운지',
                    image: '/images/sub/about/facilities/facilities-3f-6.jpg',
                    purpose: '행사 운영 및 지원공간 제공',
                    function: '피칭대회, 네트워킹 개최',
                    composition: '단상, 참석자 좌석, 휴게공간 등',
                },
                {
                    title: '오픈 실험실',
                    image: '/images/sub/about/facilities/facilities-3f-7.jpg',
                    purpose: '상주 엔지니어와 공동연구/개발',
                    function: '측정장비, 조립장비 활용, AS 등',
                    composition: '설계실, 조립룸, 측정평가실 등',
                },
                {
                    title: '교육장',
                    image: '/images/sub/about/facilities/facilities-3f-8.jpg',
                    purpose: '전자제조 인력양성, 기술지원 공간',
                    function: '교육 프로그램 운영',
                    composition: '3실 (총 2실)',
                    caption: '데이터, 전자제조, 제품분석, 장비 관련 교육 운영',
                },
                {
                    title: '스튜디오',
                    image: '/images/sub/about/facilities/facilities-3f-9.jpg',
                    purpose: '창업, 중소기업 비대면 회의/편의 공간제공',
                    function: '비즈니스, 코워킹, 홍보가능 공간',
                    composition: '4실 (2층 1실, 3층 3실)',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.floor-image{
    position: relative;
    &__btns{
        button{
            display: block;
            position: absolute;
            // opacity: 0.5;
            // &:nth-child(1){
            //     background: red;
            // }
            // &:nth-child(2){
            //     background: orange;
            // }
            // &:nth-child(3){
            //     background: yellow;
            // }
            // &:nth-child(4){
            //     background: green;
            // }
            // &:nth-child(5){
            //     background: blue;
            // }
            // &:nth-child(6){
            //     background: navy;
            // }
            // &:nth-child(7){
            //     background: violet;
            // }
            // &:nth-child(8){
            //     background: mediumspringgreen;
            // }
            // &:nth-child(9){
            //     background: hotpink;
            // }
            // &:nth-child(10){
            //     background: greenyellow;
            // }
        }
    }
}
</style>