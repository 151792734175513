<template>
    <v-row>
        <!-- S::입주기업 -->
        <v-col v-for="company in companyList" :key="company._id" cols="12" md="6">
            <v-card flat outlined rounded>
                <v-row no-gutters>
                    <v-col cols="12" md="4" class="border-md-end border-bottom border-md-bottom-0" style="border-color: var(--v-grey-lighten4) !important;">
                        <v-layout align-center justify-center fill-height class="pa-16">
                            <!-- 로고 -->
                            <v-img :src="company?.thumb || '/images/ci.svg'" max-width="166" :aspect-ratio="166 / 80" contain class="w-100" />
                        </v-layout>
                    </v-col>
                    <v-col cols="12" md="8">
                        <div class="pa-16 pa-md-20">
                            <!-- 입주위치 -->
                            <h4 class="tit">{{ company.subject }}</h4>
                            <v-divider class="my-10" />
                            <v-sheet class="mb-n4">
                                <!-- 입주시기 -->
                                <info-row-inline v-if="company?.state?.period" title="입주시기" :info="company?.state?.period" />
                                <!-- 기업명 -->
                                <info-row-inline v-if="company?.state?.company" title="기업명" :info="company?.state?.company" />
                                <!--  -->
                                <info-row-inline v-if="company?.state?.name" title="대표자명" :info="company?.state?.name" />
                                <!-- 대표자명 -->
                                <info-row-inline v-if="company?.state?.item" title="사업아이템" :info="company?.state?.item" />
                                <!-- 연락처 -->
                                <info-row-inline v-if="company?.state?.phone" title="연락처" :info="company?.state?.phone" />
                            </v-sheet>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <!-- E::입주기업 -->
    </v-row>
</template>

<script>
import api from "@/api";
import InfoRowInline from "@/components/client/sub/info-row-inline.vue";

export default {
    components: {
        InfoRowInline,
    },
    data() {
        return {
            companyList: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { manages } = await api.v1.manage.gets({ params: { code: "current" } });
            this.companyList = manages;
        },
    },
};
</script>

<style></style>
