var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "제조지원·교육"
    }
  }), _c('service-navigation-row'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('section', {
    staticClass: "page-section"
  }, [_c('tit-primary', {
    attrs: {
      "sc": "SERVICE",
      "tit": "지원서비스 안내",
      "titTxt": "스마트소형가전의 설계~개발~제조를 직접 진행합니다. 설계 엔지니어, 소프트웨어, 장비, 인프라를 구축하였고 개발, 제조를 위해 부품선정, 회로설계, 아트웍, 보드제작, 펌웨어개발, 디버깅, 측정평가 지원이 가능합니다."
    }
  }), _c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 rounded border pa-20 pa-md-30 py-lg-64 d-flex align-center flex-column"
  }, [_c('sub-icon-primary', {
    attrs: {
      "spacing": "mb-14 mb-lg-26",
      "bgColor": "#f7f8fa",
      "icon": "/images/sub/service/about/s1-icon1-1.svg"
    }
  }), _c('p', {
    staticClass: "page-text color-grey text-center"
  }, [_vm._v(" 가성비 높은 대체부품 적용을 위한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 설계, 보드 제작이 가능합니다. ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 rounded border pa-20 pa-md-30 py-lg-64 d-flex align-center flex-column"
  }, [_c('sub-icon-primary', {
    attrs: {
      "spacing": "mb-14 mb-lg-26",
      "bgColor": "#f7f8fa",
      "icon": "/images/sub/service/about/s1-icon1-2.svg"
    }
  }), _c('p', {
    staticClass: "page-text color-grey text-center"
  }, [_vm._v(" 성능, 기능, 가격향상을 위한 재설계, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 보드 제작이 가능합니다. ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 rounded border pa-20 pa-md-30 py-lg-64 d-flex align-center flex-column"
  }, [_c('sub-icon-primary', {
    attrs: {
      "spacing": "mb-14 mb-lg-26",
      "bgColor": "#f7f8fa",
      "icon": "/images/sub/service/about/s1-icon1-3.svg"
    }
  }), _c('p', {
    staticClass: "page-text color-grey text-center"
  }, [_vm._v(" 회로설계, 보드제작은 담당자 문의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 후 상시지원으로 가능합니다. ")])], 1)])], 1)], 1), _c('section', {
    staticClass: "page-section page-section--last"
  }, [_c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "rounded h-100 pa-20 pa-md-30 py-lg-52 px-lg-60 d-flex flex-column flex-md-row align-center",
    staticStyle: {
      "background-color": "#f7f8fa"
    }
  }, [_c('sub-icon-primary', {
    attrs: {
      "spacing": "mb-14 mb-md-0 mr-md-14 mr-lg-30",
      "bgColor": "#2e60ef",
      "icon": "/images/sub/service/about/s1-icon2-1.svg"
    }
  }), _c('div', {
    staticClass: "text-center text-md-left"
  }, [_c('h4', {
    staticClass: "tit--sm font-weight-bold mb-4 mb-lg-10"
  }, [_vm._v(" 지원제품 ")]), _c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v(" 스마트소형가전으로 가볍고 작고 저가이며 주기가 빠르고 사용자반응이 적용된 중간기술기반의 아이디어 소형가전입니다. ")])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "rounded h-100 pa-20 pa-md-30 py-lg-52 px-lg-60 d-flex flex-column flex-md-row align-center",
    staticStyle: {
      "background-color": "#f7f8fa"
    }
  }, [_c('sub-icon-primary', {
    attrs: {
      "spacing": "mb-14 mb-md-0 mr-md-14 mr-lg-30",
      "bgColor": "#2e60ef",
      "icon": "/images/sub/service/about/s1-icon2-2.svg"
    }
  }), _c('div', {
    staticClass: "text-center text-md-left"
  }, [_c('h4', {
    staticClass: "tit--sm font-weight-bold mb-4 mb-lg-10"
  }, [_vm._v(" 지원대상 ")]), _c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v(" 중소기업, 스타트업, 엔지니어 등 스마트 소형가전의 개발,제조를 진행 중 이거나 계획중인 곳으로 구체적 아이템이 있어야 합니다. ")])])], 1)])], 1)], 1)])], 1), _c('section', {
    staticClass: "section py-0 py-lg-60 section--support"
  }, [_c('v-container', [_c('div', {
    staticClass: "rounded rounded-lg-0"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "color-white",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-0 section--supprot__item",
    staticStyle: {
      "background-color": "#2e60ef"
    }
  }, [_c('h3', {
    staticClass: "tit font-weight-bold line-height-1 mb-8 mb-lg-14"
  }, [_vm._v(" 지원 방법 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 지원은 토탈지원과 상시지원이 있습니다. ")])])]), _c('v-col', {
    staticClass: "page-text mt-n10 mt-lg-0",
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-0 pl-lg-30 pl-xl-60 section--supprot__item",
    staticStyle: {
      "background-color": "#ebf5ff"
    }
  }, [_c('div', {
    staticClass: "mb-10 mb-lg-22"
  }, [_c('div', {
    staticClass: "rounded-tit d-flex align-center justify-center text-center line-height-1 mb-6 mb-lg-10"
  }, [_c('p', {
    staticClass: "color-white"
  }, [_vm._v("토탈지원")])]), _c('p', {
    staticClass: "color-grey"
  }, [_vm._v(" 개발~전조~전시 전 과정을 공모를 통해 선정후 지원합니다. ")])]), _c('div', [_c('div', {
    staticClass: "rounded-tit d-flex align-center justify-center text-center line-height-1 mb-6 mb-lg-10"
  }, [_c('p', {
    staticClass: "color-white"
  }, [_vm._v("상시지원")])]), _c('p', {
    staticClass: "color-grey"
  }, [_vm._v(" 설계 SMT, 제조, 조립, 디버깅, 펌웨어, 부품선정, 컨설팅, 전시 등 기업이 필요한 부분만 담당자 배정에 의해 상시 지원합니다. ")])])])])], 1)], 1)])], 1), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tit-primary', {
    attrs: {
      "sc": "process",
      "tit": "초도양산 및 상품화 지원 절차"
    }
  }), _c('div', {
    staticClass: "rounded border pa-20 pa-md-30 py-lg-92"
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100 mx-auto",
    attrs: {
      "src": "/images/sub/service/about/s3-img.jpg",
      "max-width": "1156"
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto",
    attrs: {
      "src": "/images/sub/service/about/s3-img-mo.jpg",
      "max-width": "596"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }