var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소개",
      "tabActive": "공간소개"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "공간소개"
    }
  }, [_c('v-tabs', {
    staticClass: "v-tabs--primary",
    attrs: {
      "color": "secondary"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("2F")]), _c('v-tab', [_vm._v("3F")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('facilities2f')], 1), _c('v-tab-item', [_c('facilities3f')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }