var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    staticClass: "search-input",
    attrs: {
      "placeholder": "검색어를 입력해주세요.",
      "hide-details": "",
      "outlined": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.emit.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "color": "secondary"
          },
          on: {
            "click": _vm.emit
          }
        }, [_c('i', {
          staticClass: "icon icon-search"
        })])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.query.name,
      callback: function ($$v) {
        _vm.$set(_vm.query, "name", $$v);
      },
      expression: "query.name"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }