<template>
    <client-page>

        <sub-visual sh="게시판" tabActive="건의합니다" />

        <page-section title="건의합니다" class="page-section--first page-section--last">
            <proposal-view v-if="$route.params._proposal"></proposal-view>
            <proposal-input v-else-if="$route.query.mode === 'input'"></proposal-input>
            <proposal-list v-else></proposal-list>
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import ProposalList from "@/components/client/center/proposal/proposal-list.vue";
import ProposalView from "@/components/client/center/proposal/proposal-view.vue";
import ProposalInput from "@/components/client/center/proposal/proposal-input.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
		ProposalList,
		ProposalView,
		ProposalInput,
    },
    mounted(){
        console.log(this.$route);
    }
};
</script>