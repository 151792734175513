<template>
    <v-btn v-bind="{ ...$attrs, disabled, loading }" @click="apply">{{ text }}</v-btn>
</template>

<script>
import api from "@/api";
import { initProgram, PROGRAM_STATES__APPLICATION } from "@/assets/variables";

export default {
    props: {
        program: { type: Object, default: initProgram },
    },
    data: () => ({
        application: null,

        loading: false,
    }),
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        userTypeMet() {
            return this.program.userTypes.includes(this.payload?.userType);
        },
        disabled() {
            const hasUserApplied = !!this.application;

            const doesStateNotMatches = [PROGRAM_STATES__APPLICATION.COMPLETE.value, PROGRAM_STATES__APPLICATION.FINISHED.value, PROGRAM_STATES__APPLICATION.UPCOMING.value].includes(this.program?.application?.state);

            const doesCapacityExceeded = (this.program?.capacity || 0) <= (this.program?.meta?.summary?.applications__active || 0);

            return hasUserApplied || doesStateNotMatches || doesCapacityExceeded;
        },
        text() {
            if (this.application) return "신청완료";
            else if (this.program?.application?.state == PROGRAM_STATES__APPLICATION.ON_GOING.value) return "신청하기";
            else return this.program?.application?.stateText;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        program() {
            this.init();
        },
        payload() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (!this.payload._user || !this.program._id) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                this.application = (await api.v1.me.programs.applications.getCurrent({ _program: this.program._id }))?.application;
            } finally {
                this.loading = false;
            }
        },

        async apply() {
            if (!this.payload._user) {
                alert("로그인 후 신청하실 수 있습니다");
                this.$router.push("/login");
                return;
            }

            if (!this.userTypeMet) {
                alert("교육대상이 아닙니다");
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                const { application } = await api.v1.me.programs.applications.post({ _program: this.program._id });
                this.application = application;

                alert("신청이 완료되었습니다");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
