<template></template>

<script>
import api from "@/api";
import { initRentalSubject, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        value: { type: Boolean, default: false },

        date: { type: String },
        subject: { type: Object, default: initRentalSubject },
    },
    mounted() {
        this.search();
    },
    watch: {
        date() {
            this.search();
        },
        subject() {
            this.search();
        },
    },
    methods: {
        async search() {
            const { date } = this;
            if (!date) return;

            this.$emit("setLoading", true);
            try {
                const { APPLIED, APPROVED } = RENTAL_SCHEDULE_STATES;
                let { summary } = await api.v1.me.rental.schedules.gets({
                    params: {
                        date,
                        rentalType: this.subject?.rental?.type,
                        state: [APPLIED.value, APPROVED.value],
                        onlyCount: true,
                    },
                });
                this.$emit("input", 0 < summary.totalCount);
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
