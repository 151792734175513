var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "제조지원·교육"
    }
  }), _c('service-navigation-row'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('service-support-category', {
    attrs: {
      "category": _vm.category
    }
  }), _c('service-support-title', {
    attrs: {
      "category": _vm.category
    }
  }), _vm.category == 'unit' ? _c('service-support-description-unit') : _vm._e(), _vm.category == 'total' ? _c('service-support-description-total') : _vm._e(), _c('div', {
    staticClass: "board-container"
  }, [_c('service-support-table', {
    attrs: {
      "programs": _vm.programs,
      "baseIndex": _vm.baseIndex,
      "category": _vm.category
    }
  }), _c('div', {
    staticClass: "scroll-responsive d-sm-none"
  }, [_c('span', [_vm._v("좌·우로 스크롤하여 내용을 확인해주세요")]), _c('i', {
    staticClass: "icon icon-scroll"
  })]), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }