<template>
    <div>
        <v-card v-for="(item, index) in schedules" :key="index" outlined rounded class="mt-10">
            <div class="px-20 px-md-36 py-16">
                <v-layout justify-space-between align-center>
                    <i class="icon icon-check mr-8" />
                    <p class="page-text page-text--lg line-height-15">
                        <template v-if="subject.rental.type == RENTAL_TYPES.BY_DATE.value">
                            {{ $dayjs(item.date).format("YYYY년 M월 D일") }}
                        </template>
                        <template v-if="subject.rental.type == RENTAL_TYPES.BY_HOUR.value">
                            {{ $dayjs(item.date).hour(item.hour).format("YYYY년 M월 D일 HH:mm (dd)") }}
                        </template>
                        <template v-if="subject.rental.type == RENTAL_TYPES.BY_TIME.value">
                            {{ $dayjs(item.date).format("YYYY년 M월 D일") }}
                            <b> {{ item.timeStart }} ~ {{ item.timeEnd }} </b>
                        </template>
                    </p>
                </v-layout>
            </div>
        </v-card>
    </div>
</template>

<script>
import { initRentalSubject, RENTAL_TYPES } from "@/assets/variables";

export default {
    props: {
        subject: { type: Object, default: initRentalSubject },
        schedules: { type: Array, default: () => [] },
    },
    data: () => ({
        RENTAL_TYPES,
    }),
};
</script>
