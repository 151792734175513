<template>
    <client-page>

        <sub-visual sh="소개" tabActive="공간소개" />

        <page-section title="공간소개" class="page-section--first page-section--last">
            <v-tabs v-model="tab" color="secondary" class="v-tabs--primary">
                <v-tab>2F</v-tab>
                <v-tab>3F</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <facilities2f />
                </v-tab-item>
                <v-tab-item>
                    <facilities3f />
                </v-tab-item>
            </v-tabs-items>
        </page-section>

    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import Facilities2f from "@/components/client/sub/about/facilities-2f.vue";
import Facilities3f from "@/components/client/sub/about/facilities-3f.vue";
export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        Facilities2f,        
        Facilities3f,
    },
    data() {
        return {
            tab: null,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
