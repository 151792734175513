<template>
    <router-link :to="`/program/view/${program._id}`">
        <!-- 썸네일 -->
        <v-img :src="program?.thumb?.src || '/images/sub/program/list/program-item-default.jpg'" max-width="440" :aspect-ratio="1 / 1" class="w-100 rounded mb-16 mb-md-24" />

        <v-row class="row--x-small">
            <v-col cols="auto">
                <!-- 카테고리 -->
                <v-chip small color="grey lighten-4" class="v-chip--noactive grey--text">{{ program?.category?.name }}</v-chip>
            </v-col>

            <!-- 회원등급 -->
            <template v-if="isForAllUserTypes">
                <v-col cols="auto">
                    <v-chip small color="secondary" class="v-chip--noactive">전체회원</v-chip>
                </v-col>
            </template>
            <template v-else v-for="text in userTypeTexts">
                <v-col cols="auto" :key="text">
                    <v-chip small color="primary" class="v-chip--noactive">{{ text }}</v-chip>
                </v-col>
            </template>

            <!-- 교육일정 -->
            <v-col cols="auto">
                <!-- 교육종료 -->
                <template v-if="program.state == PROGRAM_STATES.FINISHED.value">
                    <v-chip small dark color="grey" class="v-chip--noactive">
                        {{ program?.stateText }}
                    </v-chip>
                </template>
                <!-- 진행중 -->
                <template v-else>
                    <v-chip small outlined color="grey" class="v-chip--noactive">
                        {{ program?.stateText }}
                    </v-chip>
                </template>
            </v-col>
        </v-row>

        <!-- 교육명 -->
        <h4 class="tit ellip mt-10">{{ program?.name }}</h4>
        <!-- 교육일정 -->
        <v-row no-gutters class="page-text page-text--lg grey--text mt-12 mt-md-16" :key="unit._id">
            <v-col cols="auto">
                <p class="pr-8">{{ date }} ({{ unit?.day }})</p>
            </v-col>
            <v-col cols="auto">
                <p>{{ unit?.timeStart }} ~ {{ unit?.timeEnd }}</p>
            </v-col>
        </v-row>
        <v-divider class="mt-12 mt-md-16" />
        <!-- 모집현황 -->
        <p class="page-text page-text--lg grey--text mt-12 mt-md-16">{{ program?.application?.stateText }} (총 교육인원 {{ program?.capacity?.format?.() }}명 / 현재 참여 {{ program?.meta?.summary?.applications__active || 0 }}명)</p>
    </router-link>
</template>

<script>
import { initProgram, PROGRAM_STATES, USER_TYPES } from "@/assets/variables";

export default {
    props: {
        program: { type: Object, default: initProgram },
    },
    data: () => ({
        PROGRAM_STATES,
    }),
    computed: {
        userTypeTexts() {
            return [...new Set(this.program.userTypeTexts.split(", "))];
        },
        isForAllUserTypes() {
            return !Object.values(USER_TYPES).some((type) => !this.program.userTypes.includes(type));
        },
        unit() {
            return this.program.units[0];
        },
        date() {
            return this.unit.date?.toDate?.()?.replaceAll?.("-", ".");
        },
    },
};
</script>

<style>
</style>