var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('table-primary', {
    attrs: {
      "caption": ""
    }
  }, [_c('table-row', {
    attrs: {
      "title": "제목"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "제목을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.subject,
      callback: function ($$v) {
        _vm.$set(_vm.question, "subject", $$v);
      },
      expression: "question.subject"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "첨부파일"
    }
  }, [_c('v-file-input', {
    attrs: {
      "placeholder": "파일을 첨부하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "문의내용"
    }
  }, [_c('naver-smarteditor', {
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.question);
      }
    },
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "outlined": "",
      "rounded": "",
      "color": "grey lighten-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "rounded": "",
      "dark": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('span', [_vm._v("작성하기")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }