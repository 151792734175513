<template>
    <client-page>
        <sub-visual sh="프로그램" tabActive="프로그램 신청" />

        <page-section class="page-section--first page-section--last">
            <program-view-card v-bind="{ program }">
                <template #action-btn>
                    <program-application-apply-btn x-large rounded color="secondary" v-bind="{ program }" />
                </template>
            </program-view-card>

            <v-divider class="grey darken-4 mt-30 mt-md-60 mb-20 mb-md-40" style="border-top-width: 2px" />

            <template v-for="subject in program.subjects">
                <rental-subject-card v-bind="{ subject }" isListItem whiteSpacePreLine readonly class="mb-20 mb-md-40" :key="subject._id" />
            </template>
            <v-sheet>
                <div v-html="program.detail" />
            </v-sheet>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initProgram } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import ProgramViewCard from "@/components/client/programs/view/program-view-card.vue";
import RentalSubjectCard from "@/components/client/rental/rental-subject-card.vue";
import ProgramApplicationApplyBtn from "@/components/client/programs/applications/program-application-apply-btn.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        ProgramViewCard,
        RentalSubjectCard,
        ProgramApplicationApplyBtn,
    },
    props: {
        _program: { type: String, default: null },
    },
    data: () => ({
        program: initProgram(),

        loading: false,
    }),
    mounted() {
        this.init();
    },
    watch: {
        _program() {
            this.init();
        },
    },
    methods: {
        init() {
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { program } = await api.v1.programs.get({ _id: this._program });
                this.program = initProgram(program);
            } finally {
                this.loading = false;
            }
        },
        async apply() {},
    },
};
</script>

<style lang="scss" scoped>
</style>
