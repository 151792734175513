<template>
    <client-page>

        <sub-visual sh="소개" tabActive="이용안내" />

        <page-section title="이용안내" class="page-section--first page-section--last">
            <!-- title="운영시간" -->
            <page-section small>
                <!-- <v-divider class="grey darken-4 mb-12 mb-md-20" style="border-top-width: 2px;" /> -->
                <!-- <p v-for="detail in info" :key="detail" class="page-text page-text--lg mt-4 mt-lg-10">{{detail}}</p> -->
                <p style="white-space: pre;">{{ info }}</p>
            </page-section>
            <page-section small title="시설이용">
                <v-sheet class="mb-30 mb-md-50 mb-lg-80">
                    <div class="chip secondary mb-12 mb-md-24">
                        <p>일반(개인, 기업) 회원</p>
                    </div>
                    <v-card flat rounded color="grey lighten-5">
                        <div class="pa-16 pa-md-24">
                            <v-row align="center" justify="center" justify-md="space-between" class="row--x-small">
                                <step idx="1" title="회원가입" icon="/images/sub/about/information/information-icon1-1.svg" color="secondary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-secondary" />
                                </v-col>
                                <step idx="2" title="기본장비 교육" icon="/images/sub/about/information/information-icon1-2.svg" color="secondary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-secondary" />
                                </v-col>
                                <step idx="3" title="멤버십 발급" icon="/images/sub/about/information/information-icon1-3.svg" color="secondary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-secondary" />
                                </v-col>
                                <step idx="4" title="보험증서 등록" icon="/images/sub/about/information/information-icon1-4.svg" color="secondary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-secondary" />
                                </v-col>
                                <step idx="5" title="장비·프로그램 예약" icon="/images/sub/about/information/information-icon1-5.svg" color="secondary" />
                            </v-row>
                        </div>
                    </v-card>
                </v-sheet>
                <v-sheet>
                    <div class="chip primary mb-12 mb-md-24">
                        <p>입주사 및 유관기업</p>
                    </div>
                    <v-card flat rounded color="grey lighten-5">
                        <div class="pa-16 pa-md-24">
                            <v-row align="center" justify="center" justify-md="space-between" class="row--x-small">
                                <step idx="1" title="입주사 선정" icon="/images/sub/about/information/information-icon2-1.svg" color="primary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-primary" />
                                </v-col>
                                <step idx="2" title="회원가입" icon="/images/sub/about/information/information-icon2-2.svg" color="primary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-primary" />
                                </v-col>
                                <step idx="3" title="기본장비 교육" icon="/images/sub/about/information/information-icon2-3.svg" color="primary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-primary" />
                                </v-col>
                                <step idx="4" title="멤버십 발급" icon="/images/sub/about/information/information-icon2-4.svg" color="primary" />
                                <v-col cols="auto">
                                    <i class="icon icon-right-arrow-primary" />
                                </v-col>
                                <step idx="5" title="장비·시설·프로그램<br class='d-none d-xl-block' /> 예약" icon="/images/sub/about/information/information-icon2-5.svg" color="primary" />
                            </v-row>
                        </div>
                    </v-card>
                </v-sheet>
            </page-section>
            <page-section small title="멤버십 안내" class="page-section--sm--last">
                <v-sheet class="mb-n20 mb-md-n40">
                    <v-card v-for="item in membership" :key="item.title" rounded flat :color="item.bgColor" class="mb-20 mb-md-40">
                        <div class="pa-20 pa-md-30 pa-lg-40">
                            <v-row align="center" justify="center">
                                <v-col cols="auto">
                                    <div :class="'icon-rounded ' + item.color">
                                        <v-img :src="item.icon" max-width="100" :aspect-ratio="1 / 1" contain></v-img>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="">
                                    <h5 class="tit tit--sm mb-6 mb-md-10">{{item.title}}</h5>
                                    <div class="chip chip--sm white mb-12 mb-md-20">
                                        <p class="line-height-15">
                                            {{item.sc}}
                                        </p>
                                    </div>
                                    <div class="pl-16 pl-md-20">
                                        <p v-for="detail in item.info" :key="detail" class="bar-txt page-text page-text--lg grey--text line-height-15">
                                            - {{detail}}
                                        </p>
                                        <v-sheet v-if="item.infoDetail" color="transparent" max-width="700" class="mt-12 mt-lg-20">
                                            <v-row no-gutters class="mx-n8">
                                                <v-col v-for="detail in item.infoDetail" :key="detail" cols="auto" class="px-8 ">
                                                    <p class="bar-txt page-text page-text--lg grey--text line-height-15">
                                                        - {{detail}}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-sheet>
            </page-section>
        </page-section>

    </client-page>
</template>

<script>
import api from '@/api';
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import Step from "@/components/client/sub/step.vue";
export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        Step,
    },
    data() {
        return {            
            info : [
                "운영시간 : 월·수·금 11시~20시 (운영마감 19:30), 화·목 09시~18:00 (운영마감 17:30), 토 09시~15시 (장비점검/수선) 15시-18시",
                "점심시간 : 12:00 ~ 13:00",
                "휴무일 : 법정휴일, 공휴일, 안전점검의 날(매월 마지막주 금요일), 기타 운영에 필요한 경우",
                "메이커스페이스 장비사용을 희망하는 회원은 상해보험가입증서 사본제출 필수",
                "장비사용(예약)날짜 기준, 상해보험가입증서가 유효한 회원에 한해 장비사용가능",
                "상해보험증서 미제출·미갱신 회원은 장비 예약 및 장비사용 불가",
                "아래 내용이 확인된 회원의 경우 장비 예약·장비사용 가능",
                "① 회원 당사자의 피보험자 유무",
                "② 계약기간(장비사용(예약)날짜가 보험 계약기간에 포함되었는지 유무)",
                "③ 상해보장내용",
            ],
            membership : [
                {
                    title : "메이커스 멤버십(예비 창업자)",
                    icon : "/images/sub/about/information/information-icon3.svg",
                    sc : "회원가입, 장비프로그램 이수자, 보험증서(각서) 등록 완료자",
                    color : "secondary",
                    bgColor : "#F2F5F9",
                    info : ["장비운영교육(기본)", "장비사용 예약(메이커스 장비)"],
                },
                {
                    title : "입주사 및 유관기업 멤버십",
                    icon : "/images/sub/about/information/information-icon4.svg",
                    sc : "회원가입, 사업계획서 제출, 승인, 사업자등록증 제출 완료자",
                    color : "primary",
                    bgColor : "#F9F6F9",
                    info : ["장비운영교육(기본)", "장비사용 예약(메이커스 · 전문 제조장비)"],
                    infoDetail: ["공용공간 사용 예약", "입주지원(공간)", "기술제조기반 교육지원", "전자제조 초도양산 지원", "3D 모델링 지원", "투자 마케팅 지원 프로그램", "기술자문/컨설팅(상시지원)"],
                },
            ],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { manages } = await api.v1.manage.gets({ params: { code: 'info' }});
            if(this.info.length>0) this.info = manages[0].content;

        }
    }
};
</script>

<style lang="scss" scoped>
.chip{
    padding: 4px 16px;
    border-radius: 999px;
    width: fit-content;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align-last: center;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    &--sm{
        text-align: left;
        text-align-last: left;
        height: auto;
        font-weight: 400;
        font-size: 1.6rem;
        color: var(--v-grey-darken4);
    }
}
i[class*=icon-right-arrow]{
    transform: rotate(90deg);
}
.icon-rounded{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bar-txt{
    text-indent: -9px;
    padding-left: 9px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .chip{
        padding: 4px 20px;
        height: 48px;
        font-size: 2rem;
        &--sm{
            height: auto;
            font-size: 1.8rem;
        }
    }
    i[class*=icon-right-arrow]{
        transform: rotate(0);
    }
    .bar-txt{
        text-indent: -11px;
        padding-left: 11px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
