<template>
    <td v-if="hasDates">{{ startsAt }} - {{ endsAt }}</td>
    <td v-else>-</td>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: ["value"],
    computed: {
        startsAt() {
            const target = this.value.applicationStartsAt;
            if (target) return dayjs(target).format("YYYY.MM.DD");
            else return;
        },
        endsAt() {
            const target = this.value.applicationEndsAt;
            if (target) return dayjs(target).format("YYYY.MM.DD");
            else return;
        },
        hasDates() {
            return this.startsAt && this.endsAt;
        },
    },
};
</script>

<style></style>
