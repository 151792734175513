var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "장비 및 공간",
      "tabActive": _vm.type == 'equipments' ? '메이커스 장비예약' : '시설 및 공간예약'
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('page-section', {
    attrs: {
      "small": ""
    }
  }, [_c('rental-subject-title', _vm._b({
    attrs: {
      "type": _vm.type
    }
  }, 'rental-subject-title', {
    rental: _vm.rental
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--sm--last",
    attrs: {
      "small": ""
    }
  }, [_c('v-sheet', {
    staticClass: "mb-30 mb-md-60"
  }, [_vm.type == 'equipments' ? _c('rental-subject-places', {
    attrs: {
      "color": "secondary"
    }
  }) : _vm._e(), _c('rental-subject-search', {
    attrs: {
      "type": _vm.type
    }
  })], 1), _vm._l(_vm.subjects, function (subject) {
    return [_c('rental-subject-card', _vm._b({
      key: subject === null || subject === void 0 ? void 0 : subject._id,
      staticClass: "mt-20 mt-md-40",
      attrs: {
        "isListItem": "",
        "whiteSpacePreLine": ""
      }
    }, 'rental-subject-card', {
      subject
    }, false))];
  }), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }