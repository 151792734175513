<template>
    <client-page>

        <sub-visual sh="장비 및 공간" tabActive="전문제조장비" />

        <page-section title="전문제조장비" class="page-section--first pb-40 pb-md-80 pb-lg-100">
            <div class="mb-12 mb-md-20">
                <h4 class="tit">장비 구성</h4>
            </div>  
            <v-divider class="grey darken-4 mb-12 mb-md-20" style="border-top-width: 2px;" />
            <rental-subject-title v-bind="{ rental }" type="intro" />
        </page-section>
        <page-section class="page-section--last pt-40 pt-md-80 pt-lg-100 grey lighten-5">
            <v-tabs v-model="tab" color="secondary" class="v-tabs--primary">
                <v-tab v-for="item in equipment" :key="item.title">{{item.title}}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in equipment" :key="item.title">
                    <v-row class="my-n20 my-md-n30">
                        <v-col v-for="child in item.children" :key="child.title" cols="6" md="4" class="py-20 py-md-30">
                            <v-img :src="child.image" max-width="440" :aspect-ratio="1 / 1" class="w-100 rounded" />
                            <h4 class="tit mt-12 mt-md-20 text-center">{{child.title}}</h4>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
            <v-card color="white" flat rounded="xs" class="mt-30 mt-md-60">
                <div class="pa-20 px-md-24 py-md-30 py-lg-40">
                    <p class="page-text page-text--lg font-weight-bold secondary--text line-height-1 mb-4 mb-md-8">장비사용문의 : 02 - 6388-6192</p>
                    <p class="page-text grey--text line-height-15">전문장비는 관리자 문의 후 지원서비스를 받으실 수 있습니다.</p>
                </div>
            </v-card>
            <v-layout justify-center class="mt-30 mt-md-60">
                <v-btn href="https://edaom.kr/rental/equipments" target="_blank" x-large rounded color="secondary">전자제조 지원신청</v-btn>
            </v-layout>
        </page-section>

    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import RentalSubjectTitle from "@/components/client/rental/rental-subject-title.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        RentalSubjectTitle,
    },
    data() {
        return {
            rental: {},
            tab: null,
            info : [
                { title : "전자제조장비", info : "전자부품실장(SMT) 및 수삽부품 조립라인 (총 3라인) 40여종", },
                { title : "목업 장비", info : "기구설계, 기구제작, 시제품 외형(목업)제작 지원장비 10여종", },
                { title : "측정평가/디버깅 장비", info : "전자 실험 및 측정평가, 신뢰성검사, 부품 리페어 장비 10여종", },
            ],
            equipment : [
                {
                    title : "전자제조장비",
                    children : [
                        { title : "전자부품실장라인(2라인)", image : "/images/sub/rental/intro/intro-img1-1.jpg", },
                        { title : "전자보드조립라인(1라인)", image : "/images/sub/rental/intro/intro-img1-2.jpg", },
                        { title : "수삽부품 조립라인", image : "/images/sub/rental/intro/intro-img1-3.jpg", },
                        { title : "레이저 마커", image : "/images/sub/rental/intro/intro-img1-4.jpg", },
                        { title : "부품검사기(X-ray)", image : "/images/sub/rental/intro/intro-img1-5.jpg", },
                        { title : "디버깅", image : "/images/sub/rental/intro/intro-img1-6.jpg", },
                        { title : "계측기기", image : "/images/sub/rental/intro/intro-img1-7.jpg", },
                        { title : "수삽", image : "/images/sub/rental/intro/intro-img1-8.jpg", },
                    ]

                },
                {
                    title : "목업 장비",
                    children : [
                        { title : "산업용 3D 프린터(SLA)", image : "/images/sub/rental/intro/intro-img2-1.jpg", },
                        { title : "산업용 3D 프린터(FDM)", image : "/images/sub/rental/intro/intro-img2-2.jpg", },
                        { title : "산업용 3D 스캐너", image : "/images/sub/rental/intro/intro-img2-3.jpg", },
                    ]

                },
                {
                    title : "측정평가/디버깅 장비",
                    children : [
                        { title : "회로설계 툴", image : "/images/sub/rental/intro/intro-img3-1.jpg", },
                        { title : "항온항습기", image : "/images/sub/rental/intro/intro-img3-2.jpg", },
                        { title : "부품 리웍스테이션", image : "/images/sub/rental/intro/intro-img3-3.jpg", },
                    ]

                },
            ],
        };
    },
    methods: {
        async getSetting() {
            let { setting } = await api.v1.setting.get();
            this.rental = setting.rental;
        }
    },
    async mounted() {
        await this.getSetting()
    }
};
</script>
