<template>
    <client-page>

        <sub-visual sh="게시판" tabActive="1:1문의" />

        <page-section title="1:1문의" class="page-section--first page-section--last">
            <inquiry-input />
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import InquiryInput from "@/components/client/center/inquiry/inquiry-input.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
		InquiryInput,
    },
};
</script>