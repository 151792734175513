var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input"
  }, [_c('input', _vm._b({
    staticClass: "w-100",
    on: {
      "input": _vm.sync
    }
  }, 'input', _vm.$attrs, false))]);

}
var staticRenderFns = []

export { render, staticRenderFns }