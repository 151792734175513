<template>
    <client-page class="wrap">
        <sub-visual sh="장비 및 공간"></sub-visual>

        <page-section class="page-section--first page-section--last page-section--rental">
            <page-section small>
                <rental-subject-card v-bind="{ subject }" readonly whiteSpacePreLine />
            </page-section>
            <page-section small>
                <v-divider />
            </page-section>
            <page-section small title="예약일시" smallTitleSpacing="mb-20 mb-md-40">
                <v-row>
                    <v-col cols="12" md="6" lg="8" class="mb-16 mb-md-0">
                        <div class="pr-lg-20">
                            <v-card rounded outlined elevation="4" v-bind="{ disabled, loading }">
                                <div class="pa-16 pa-md-30 py-xl-80">
                                    <v-sheet max-width="772" class="mx-auto">
                                        <rental-form-calendar v-model="date" v-bind="{ subject }" />
                                    </v-sheet>
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <v-sheet>
                            <v-row align="end" class="row--x-small">
                                <v-col cols="12" md="">
                                    <p class="page-text page-text--lg font-weight-bold secondary--text">이용시간을 선택해주세요</p>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <v-layout justify-end>
                                        <v-layout justify-end align-center class="mr-12 mr-md-20" style="flex: unset">
                                            <p class="page-text grey--text line-height-15">예약가능</p>
                                            <span class="state-bar"></span>
                                        </v-layout>
                                        <v-layout justify-end align-center style="flex: unset">
                                            <p class="page-text grey--text line-height-15">예약불가</p>
                                            <span class="state-bar grey lighten-4"></span>
                                        </v-layout>
                                    </v-layout>
                                </v-col>
                            </v-row>
                            <v-card flat class="mt-16 mt-md-20" v-bind="{ disabled, loading }">
                                <rental-form-schedules v-model="schedules" v-bind="{ date, subject, blockages, schedulesOnCloud, limitationMet }" />
                            </v-card>
                        </v-sheet>
                        <v-sheet class="mt-20 mt-md-40">
                            <p class="page-text page-text--lg font-weight-bold secondary--text">선택내용을 확인해주세요</p>
                            <rental-form-preview v-bind="{ subject, schedules }" class="mt-16 mt-md-20" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </page-section>

            <page-section small title="사용목적" smallTitleSpacing="mb-20 mb-md-40">
                <v-textarea v-model="desc.objective" outlined hide-details height="180" :placeholder="`사용목적을 입력해주세요`" class="v-textarea--lg rounded" v-bind="{ disabled }" />
            </page-section>

            <template v-if="subject.type == RENTAL_SUBJECT_TYPES.FACILITIES.value">
                <page-section small title="사용인원" smallTitleSpacing="mb-20 mb-md-40">
                    <v-textarea v-model="desc.headcount" outlined hide-details height="180" :placeholder="`사용인원을 입력해주세요`" class="v-textarea--lg rounded" />
                </page-section>
            </template>

            <template v-if="subject.type == RENTAL_SUBJECT_TYPES.EQUIPMENTS.value">
                <page-section small title="작업계획" smallTitleSpacing="mb-20 mb-md-40">
                    <v-textarea v-model="desc.procedure" outlined hide-details height="180" :placeholder="`작업계획을 입력해주세요`" class="v-textarea--lg rounded" />
                </page-section>

                <page-section small title="사용재료" smallTitleSpacing="mb-20 mb-md-40">
                    <v-textarea v-model="desc.substance" outlined hide-details height="180" :placeholder="`사용재료를 입력해주세요`" class="v-textarea--lg rounded" />
                </page-section>
            </template>

            <page-section small class="page-section--sm--last">
                <v-row justify="center" class="row--x-small">
                    <v-col cols="auto">
                        <v-btn @click="$router.go(-1)" large outlined rounded color="grey lighten-4">
                            <span class="grey--text" v-bind="{ loading }">취소</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn large rounded color="secondary" v-bind="{ loading, disabled }" @click="save">
                            <span>예약하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </page-section>
        </page-section>

        <template v-if="subject._id">
            <rental-subject-blockages v-model="blockages" v-bind="{ date, subject }" />

            <rental-schedules-on-cloud v-model="hasAnScheduleOnDate" v-bind="{ date, subject, loading }" @setLoading="(bool) => (loading = bool)" />

            <rental-subject-schedules-on-cloud v-model="schedulesOnCloud" v-bind="{ date, subject, loading }" @setLoading="(bool) => (loading = bool)" />

            <rental-subject-limitation-per-date v-model="limitationPerDateMet" v-bind="{ date, subject, schedules }" />

            <rental-subject-limitation-per-week v-model="limitationPerWeekMet__self" mode="self" v-bind="{ date, subject, schedules }" />
            <rental-subject-limitation-per-week v-model="limitationPerWeekMet__type" mode="type" v-bind="{ date, subject, schedules }" />
            <rental-subject-limitation-per-week v-model="limitationPerWeekMet__site" mode="site" v-bind="{ date, subject, schedules }" />
        </template>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { initRentalSubject, RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

import RentalSubjectCard from "@/components/client/rental/rental-subject-card.vue";
import RentalSubjectBlockages from "@/components/client/rental/rental-subject-blockages.vue";
import RentalSchedulesOnCloud from "@/components/client/rental/rental-schedules-on-cloud.vue";
import RentalSubjectSchedulesOnCloud from "@/components/client/rental/rental-subject-schedules-on-cloud.vue";
import RentalSubjectLimitationPerDate from "@/components/client/rental/rental-subject-limitation-per-date.vue";
import RentalSubjectLimitationPerWeek from "@/components/client/rental/rental-subject-limitation-per-week.vue";

import RentalFormPreview from "@/components/client/rental/form/rental-form-preview.vue";
import RentalFormCalendar from "@/components/client/rental/form/rental-form-calendar.vue";
import RentalFormSchedules from "@/components/client/rental/form/rental-form-schedules.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,

        RentalSubjectCard,
        RentalSubjectBlockages,
        RentalSchedulesOnCloud,
        RentalSubjectSchedulesOnCloud,
        RentalSubjectLimitationPerDate,
        RentalSubjectLimitationPerWeek,

        RentalFormPreview,
        RentalFormCalendar,
        RentalFormSchedules,
    },
    props: {
        type: { type: String, default: RENTAL_SUBJECT_TYPES.EQUIPMENTS.value },
        _subject: { type: String, default: null },
    },
    data: () => ({
        // form
        date: dayjs()
            .add(1, "day")
            .format("YYYY-MM-DD"),
        desc: {
            objective: null,
            procedure: null,
            substance: null,
            headcount: null,
        },
        schedules: [],

        // else
        subject: initRentalSubject(),
        blockages: [],
        schedulesOnCloud: [],
        hasAnScheduleOnDate: false,
        limitationPerDateMet: false,
        limitationPerWeekMet__self: false,
        limitationPerWeekMet__type: false,
        limitationPerWeekMet__site: false,

        RENTAL_SUBJECT_TYPES,

        loading: false,
    }),
    computed: {
        ...mapGetters(["isBanned"]),

        disabled() {
            return !this.subject.rental.isActive;
        },
        limitationPerWeekMet() {
            return this.limitationPerWeekMet__self || this.limitationPerWeekMet__type || this.limitationPerWeekMet__site;
        },
        limitationMet() {
            return this.limitationPerDateMet || this.limitationPerWeekMet || this.hasAnScheduleOnDate;
        },
        _equipments__rentable() {
            return this.$store.state.user?.blacksmith?.["_equipments__rentable"] || [];
        },

        isUserFacilityActive() {
            return this.$store.state.user?.blacksmith?.facility?.isActive ?? false;
        },

        isRentableForUser() {
            switch (this.subject?.type) {
                case RENTAL_SUBJECT_TYPES.EQUIPMENTS.value: {
                    if (this.isBanned) return false;
                    return this._equipments__rentable.includes(this.subject._id);
                }
                case RENTAL_SUBJECT_TYPES.FACILITIES.value: {
                    return this.isUserFacilityActive;
                }
                default: {
                    return true;
                }
            }
        },

        notRentableMessage() {
            switch (this.subject?.type) {
                case RENTAL_SUBJECT_TYPES.EQUIPMENTS.value: {
                    if (this.isBanned) return "사용금지 기간입니다";
                    return "장비운영교육 이수가 필요합니다.";
                }

                case RENTAL_SUBJECT_TYPES.FACILITIES.value: {
                    return "예약 권한이 필요합니다.";
                }

                default: {
                    return null;
                }
            }
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { subject } = await api.v1.rental.subjects.get({ _id: this._subject });
                this.subject = initRentalSubject(subject);

                if (!this.isRentableForUser) {
                    alert(this.notRentableMessage);
                    if (this.isBanned) this.$router.replace("/mypage/bans");
                    else this.$router.replace("/rental/equipments");
                    return;
                }

                this.reroute();
            } finally {
                if (this.loading) this.loading = false;
            }
        },
        async reroute() {
            const path = `/rental/${this.subject?.type?.toLowerCase?.()}/${this._subject}`;
            if (this.$route.path !== path) this.$router.replace({ path });
        },
        validates() {
            try {
                if (!this.$store.state.payload?._user) {
                    throw new Error("회원만 사용할 수 있습니다");
                }

                if (this.schedules.length < 1) {
                    throw new Error("스케줄을 선택해주세요");
                }

                if (this.subject.type == RENTAL_SUBJECT_TYPES.FACILITIES.value) {
                    if (!this.desc.objective) {
                        throw new Error("사용목적을 작성해주세요");
                    }

                    if (!this.desc.headcount) {
                        throw new Error("사용인원을 작성해주세요");
                    }
                }

                if (this.subject.type == RENTAL_SUBJECT_TYPES.EQUIPMENTS.value) {
                    if (!this.desc.objective) {
                        throw new Error("사용목적을 작성해주세요");
                    }

                    if (!this.desc.procedure) {
                        throw new Error("작업계획을 작성해주세요");
                    }

                    if (!this.desc.substance) {
                        throw new Error("사용재료를 작성해주세요");
                    }
                }

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;
            this.loading = true;
            let { desc } = this;

            this.schedules = await Promise.all(
                this.schedules.map(async (schedule) => {
                    try {
                        return { ...((await api.v1.me.rental.schedules.post({ ...schedule, desc }))?.schedule || {}), success: true };
                    } catch (error) {
                        console.error(error);
                        return { ...schedule, success: false };
                    }
                })
            );
            let hasFailedItem = this.schedules.some(({ success }) => !success);
            if (hasFailedItem) {
                alert("예약 신청이 실패하였습니다.\n성공한 예약을 확인후 다시 신청해주세요.");
            } else {
                alert("예약 신청에 성공하였습니다");
            }
            (async () => {
                let requests = this.schedules
                    .reduce((requests, { _id, date }) => {
                        if (_id) {
                            let group = requests.find((group) => group.date == date);
                            if (!group) {
                                requests.push({ date, _schedules: [] });
                                group = requests[requests.length - 1];
                            }
                            group._schedules.push(_id);
                        }

                        return requests;
                    }, [])
                    .sort((a, b) => this.$dayjs(a.date).isAfter(b.date));

                let { _subject } = this;

                await Promise.all(
                    requests.map(async (request) => {
                        let { _schedules, date } = request;
                        let item = {
                            _subject,
                            _schedules,

                            rentalType: this.subject?.rental?.type,
                            subjectType: this.subject?.type,

                            date,
                            desc,
                        };
                        try {
                            return { ...((await api.v1.me.rental.requests.post(item)?.request) || {}), success: true };
                        } catch (error) {
                            console.error(error);
                            return { ...item, success: false };
                        }
                    })
                );
            })();

            this.loading = false;

            this.$router.push(`/mypage/rental-requests/${this.subject.type}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.state-bar {
    display: block;
    width: 30px;
    height: 8px;
    border-radius: 999px;
    border: 1px solid var(--v-grey-lighten4);
    margin-left: 8px;
}
</style>
