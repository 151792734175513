var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', _vm._l(_vm.companyList, function (company) {
    var _company$state, _company$state2, _company$state3, _company$state4, _company$state5, _company$state6, _company$state7, _company$state8, _company$state9, _company$state10;
    return _c('v-col', {
      key: company._id,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "outlined": "",
        "rounded": ""
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "border-md-end border-bottom border-md-bottom-0",
      staticStyle: {
        "border-color": "var(--v-grey-lighten4) !important"
      },
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-layout', {
      staticClass: "pa-16",
      attrs: {
        "align-center": "",
        "justify-center": "",
        "fill-height": ""
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": (company === null || company === void 0 ? void 0 : company.thumb) || '/images/ci.svg',
        "max-width": "166",
        "aspect-ratio": 166 / 80,
        "contain": ""
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "8"
      }
    }, [_c('div', {
      staticClass: "pa-16 pa-md-20"
    }, [_c('h4', {
      staticClass: "tit"
    }, [_vm._v(_vm._s(company.subject))]), _c('v-divider', {
      staticClass: "my-10"
    }), _c('v-sheet', {
      staticClass: "mb-n4"
    }, [company !== null && company !== void 0 && (_company$state = company.state) !== null && _company$state !== void 0 && _company$state.period ? _c('info-row-inline', {
      attrs: {
        "title": "입주시기",
        "info": company === null || company === void 0 ? void 0 : (_company$state2 = company.state) === null || _company$state2 === void 0 ? void 0 : _company$state2.period
      }
    }) : _vm._e(), company !== null && company !== void 0 && (_company$state3 = company.state) !== null && _company$state3 !== void 0 && _company$state3.company ? _c('info-row-inline', {
      attrs: {
        "title": "기업명",
        "info": company === null || company === void 0 ? void 0 : (_company$state4 = company.state) === null || _company$state4 === void 0 ? void 0 : _company$state4.company
      }
    }) : _vm._e(), company !== null && company !== void 0 && (_company$state5 = company.state) !== null && _company$state5 !== void 0 && _company$state5.name ? _c('info-row-inline', {
      attrs: {
        "title": "대표자명",
        "info": company === null || company === void 0 ? void 0 : (_company$state6 = company.state) === null || _company$state6 === void 0 ? void 0 : _company$state6.name
      }
    }) : _vm._e(), company !== null && company !== void 0 && (_company$state7 = company.state) !== null && _company$state7 !== void 0 && _company$state7.item ? _c('info-row-inline', {
      attrs: {
        "title": "사업아이템",
        "info": company === null || company === void 0 ? void 0 : (_company$state8 = company.state) === null || _company$state8 === void 0 ? void 0 : _company$state8.item
      }
    }) : _vm._e(), company !== null && company !== void 0 && (_company$state9 = company.state) !== null && _company$state9 !== void 0 && _company$state9.phone ? _c('info-row-inline', {
      attrs: {
        "title": "연락처",
        "info": company === null || company === void 0 ? void 0 : (_company$state10 = company.state) === null || _company$state10 === void 0 ? void 0 : _company$state10.phone
      }
    }) : _vm._e()], 1)], 1)])], 1)], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }