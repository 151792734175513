<template>
    <v-sheet>
        <table-primary caption>
            <table-row title="문의제목">
                <v-text-field v-model="form.subject" placeholder="문의내용" outlined hide-details />
            </table-row>
            <table-row title="이름">
                <v-text-field v-model="form.writer.name" placeholder="이름을 입력하세요." outlined hide-details />
            </table-row>
            <table-row title="이메일" pointer>
                <v-row class="row--xx-small">
                    <v-col cols="12" md="6">
                        <v-text-field v-model="email1" placeholder="이메일을 입력하세요." outlined hide-details />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-combobox prefix="@" v-model="email2" :items="emailItems" outlined hide-details />
                    </v-col>
                </v-row>
            </table-row>
            <table-row title="문의내용">
                <v-textarea v-model="form.content" placeholder="문의 내용을 입력하세요." outlined hide-details />
            </table-row>
        </table-primary>

        <v-card outlined rounded class="agreement-card mt-30 mt-lg-60">
            <div class="pa-16 pa-md-20">
                <v-row align="center" class="row--small">
                    <v-col>
                        <v-row no-gutters align="center">
                            <v-col cols="auto">
                                <v-checkbox v-model="term" label="개인정보 수집 및 이용에 동의합니다." hide-details />
                            </v-col>
                            <v-col>
                                <p class="font-size-14 pl-4" style="color: #E91E63">(필수)</p>
                            </v-col>
                        </v-row>                                    
                    </v-col>
                    <v-col cols="12" sm="auto" class="d-flex justify-end d-sm-block">
                        <v-btn text small color="grey lighten-1" @click="$refs.privacy.open()"><span class="font-size-14">내용보기</span></v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <v-layout justify-center class="v-btn--group">
            <v-btn  @click="send" large rounded dark color="secondary">
                <span>문의하기</span>
            </v-btn>
        </v-layout>

        <dialog-privacy ref="privacy" />

    </v-sheet>
</template>

<script>
import api from '@/api';
import TablePrimary from "@/components/dumb/table-primary.vue";
import TableRow from "@/components/dumb/table-row.vue";
import DialogPrivacy from '@/components/client/join/dialog-privacy.vue';

export default {
    components: {
        TablePrimary,
        TableRow,
        DialogPrivacy,
    },
    data() {
        return {
            form:{
                subject: null,
                content: null,
                writer: {
                    name: null,
                    email: null,
                },
            },
            email1: null,
            email2: null,
            term: false,

            emailItems: ["naver.com", "hanmail.net", "gogle.com",],
        };
    },
    methods:{
        validate(){
            try{
                if(!this.form.subject) throw new Error("문의제목을 작성해 주세요");
                if(!this.form.writer.name) throw new Error("이름을 작성해 주세요");
                if(!this.email1) throw new Error("이메일을 작성해 주세요");
                if(!this.email2) throw new Error("이메일을 작성해 주세요");
                if(!this.form.content) throw new Error("문의내용을 작성해 주세요");
                if(!this.term) throw new Error("약관에 동의해 주세요");
                return true;
            } catch(err) {
                alert(err.message);
            }
        },
        async send() {
            if(this.validate()){
                let { form } = await api.v1.forms.post(this.form);
                if(form) alert("문의신청이 접수됐습니다");
                this.$router.go('/');
            }
        }
    },
    watch:{
        email1(){
            this.form.writer.email = `${this.email1}@${this.email2}`;
        },
        email2(){
            this.form.writer.email = `${this.email1}@${this.email2}`;
        }
    }
};
</script>


<style lang="scss" scoped>
::v-deep{
    .v-input--checkbox{
        .v-icon.v-icon{
            font-size: 2rem;
        }
        .theme--light.v-label{
            font-size: 1.4rem;
        }
        .v-input--selection-controls__input{
            width: 20px;
            height: 20px;
        }
        .v-input--selection-controls__ripple{
            left: -14px;
        }
    }
}
</style>