<template>
    <v-row align="center" class="row--small">
        <v-col>
            <input v-model="applicant.email1" type="text" name="email1" class="w-100" />
        </v-col>
        <v-col cols="auto" class="pa-0">@</v-col>
        <v-col>
            <input v-model="applicant.email2" type="text" name="email2" class="w-100" :disabled="disabled" />
        </v-col>
        <v-col>
            <select v-model="selected" class="w-100" @input="syncEmail2">
                <option value="">직접입력</option>
                <option value="naver.com">naver.com</option>
                <option value="daum.net">daum.net</option>
                <option value="google.com">google.com</option>
            </select>
        </v-col>
    </v-row>
    <!-- <div class="form-email">
        <input v-model="applicant.email1" type="text" name="email1" class="input" />
        <span class="text">@</span>
        <input v-model="applicant.email2" type="text" name="email2" class="input" :disabled="disabled" />
        <select v-model="selected" class="select" @input="syncEmail2">
            <option value="">직접입력</option>
            <option value="naver.com">naver.com</option>
            <option value="daum.net">daum.net</option>
            <option value="google.com">google.com</option>
        </select>
    </div> -->
</template>

<script>
export default {
    props: {
        value: Object,
    },
    computed: {
        disabled() {
            return this.selected == "" ? false : true;
        },
    },
    data() {
        return {
            applicant: {
                email1: null,
                email2: null,
            },
            selected: "",
        };
    },
    methods: {
        syncEmail2(event) {
            this.applicant.email2 = event.target.value;
        },
        sync() {
            const newValue = JSON.parse(JSON.stringify(this.value));
            Object.assignDefined(newValue, this.applicant);
            this.$emit("input", newValue);
        },
    },
    watch: {
        "value.email1"() {
            if (this.value.email1 !== this.applicant.email1) this.applicant.email1 = this.value.email1;
        },
        "value.email2"() {
            if (this.value.email2 !== this.applicant.email2) this.applicant.email2 = this.value.email2;
        },
        "applicant.email1"() {
            if (this.value.email1 !== this.applicant.email1) this.sync();
        },
        "applicant.email2"() {
            if (this.value.email2 !== this.applicant.email2) this.sync();
        },
    },
};
</script>

<style></style>
