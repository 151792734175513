<template>
    <div v-if="type == 'equipments'">
        <h4 class="tit tit--md">메이커스 장비예약</h4>
        <div v-html="rental.equipment.title.replace(/\n/g, '<br>')" class="page-text page-text--lg line-height-15 mt-20 mt-md-40" />
    </div>
    <div v-else-if="type == 'facilities'">
        <h4 class="tit tit--md">시설 및 공간예약</h4>
        <div v-html="rental.facility.title.replace(/\n/g, '<br>')" class="page-text page-text--lg line-height-15 mt-20 mt-md-40" />
    </div>
    <div v-else-if="type == 'intro'">
        <div v-html="rental.intro.title.replace(/\n/g, '<br>')" class="page-text page-text--lg line-height-15 mt-20 mt-md-40" />
    </div>
</template>

<script>
export default {
    props: {
        type: { type: String, default: "" },
        rental: { type: Object, default: () => {} }
    },
};
</script>

<style>
</style>