var render = function render(){
  var _vm$question, _vm$question2, _vm$question3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" " + _vm._s(_vm.question.subject) + " ")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v("작성일 : " + _vm._s(_vm.question.createdAt.toDate()))])])])]), (_vm$question = _vm.question) !== null && _vm$question !== void 0 && _vm$question.files.length ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-view-file"
  }, [_c('span', {
    staticClass: "head"
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 409 513.6"
    },
    attrs: {
      "version": "1.1",
      "id": "레이어_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 409 513.6",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    attrs: {
      "d": "M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82\n                                c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8\n                                M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7\n                                c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
    }
  })]), _vm._v(" 첨부파일 : ")]), _c('ul', {
    staticClass: "lists"
  }, _vm._l((_vm$question2 = _vm.question) === null || _vm$question2 === void 0 ? void 0 : _vm$question2.files, function (file) {
    var _file$url;
    return _c('li', {
      key: file.url,
      staticClass: "ml-20"
    }, [_c('a', {
      attrs: {
        "href": file.url,
        "download": ""
      }
    }, [_vm._v(_vm._s(file === null || file === void 0 ? void 0 : (_file$url = file.url) === null || _file$url === void 0 ? void 0 : _file$url.split('/files/')[1]))])]);
  }), 0)])])]) : _vm._e(), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.content.replace(/\n/g, '<br>'))
    }
  })])])]), _c('div', {
    staticClass: "grey lighten-5",
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "py-16"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mark"
  }, [_vm._v("A")])]), _c('v-col', [_c('p', {
    staticClass: "page-text grey--text"
  }, [_vm._v(" " + _vm._s((_vm$question3 = _vm.question) === null || _vm$question3 === void 0 ? void 0 : _vm$question3.reply) + " ")])])], 1)], 1)])])])]), _c('v-layout', {
    staticClass: "mt-24",
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-size-14"
  }, [_vm._v("리스트")])])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }