<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual visual="제조지원·교육"></sub-visual>
        <!-- 내비게이션 -->
        <service-navigation-row />

        <section class="section">
            <v-container>
                <service-support-category :category="category" />

                <service-support-title :category="category" />

                <div class="board-container">
                    <service-support-input v-model="applicant" :user="user" :program="program" @files="syncFiles" />
                    <div class="button-group">
                        <v-btn v-bind="{ loading }" large outlined rounded color="grey" elevation="0" @click="$router.go(-1)" class="min-w-120px min-w-lg-200px"><span class="color-grey">취소</span></v-btn>
                        <v-btn v-bind="{ loading }" large rounded color="primary" elevation="0" @click="save" class="min-w-120px min-w-lg-200px"><span>신청하기</span></v-btn>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import { SERVICE_PROGRAM_CATEGORIES } from "@/assets/variables";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ServiceSupportTitle from "@/components/client/service/service-support-title.vue";
import ServiceSupportInput from "@/components/client/service/service-support-input.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";
import ServiceSupportCategory from "@/components/client/service/service-support-category.vue";

export default {
    props: ["category", "_program"],
    components: {
        SubVisual,
        ClientPage,
        ServiceSupportInput,
        ServiceSupportTitle,
        ServiceNavigationRow,
        ServiceSupportCategory,
    },
    computed: {
        type() {
            return SERVICE_PROGRAM_CATEGORIES[this.category?.toUpperCase?.()]?.value;
        },
    },
    data() {
        return {
            user: null,
            program: {},
            applicant: {},
            files: [],

            loading: false,
        };
    },
    methods: {
        async init() {
            try {
                const { program } = await api.v1.services.get({ _id: this._program });
                this.program = program;

                const { user } = await api.v1.me.get();
                this.user = user;
            } catch (error) {
                this.$handleError(error);
            }
        },
        validates() {
            try {
                const { type, _program, applicant, files, category } = this;
                if (!applicant?.establishedAt) throw Error("기업설립일을 입력해주세요");

                return true;
            } catch (error) {
                this.$handleError(error);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            this.loading = true;

            try {
                const { type, _program, applicant, files, category } = this;
                const { application } = await api.v1.services.applications.post({
                    type,
                    _program,
                    applicant,
                });

                const _files = await Promise.all(files.map(async (file) => await api.v1.services.files.post({ _application: application._id }, file)));

                alert("신청이 완료되었습니다.");
                this.$router.push(this.$route.path.replace("/apply", "/complete"));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        syncFiles(files) {
            this.files = files;
        },
    },
    mounted() {
        if (this.$store.state.payload._user) this.init();
        else {
            alert("권한이 없습니다.");
            this.$router.go(-1);
        }
    },
};
</script>
