var render = function render(){
  var _vm$program, _vm$program$thumb, _vm$program2, _vm$program2$category, _vm$program3, _vm$program4, _vm$program5, _vm$unit, _vm$unit2, _vm$unit3, _vm$program6, _vm$program6$applicat, _vm$program7, _vm$program7$capacity, _vm$program7$capacity2, _vm$program8, _vm$program8$meta, _vm$program8$meta$sum;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    attrs: {
      "to": `/program/view/${_vm.program._id}`
    }
  }, [_c('v-img', {
    staticClass: "w-100 rounded mb-16 mb-md-24",
    attrs: {
      "src": ((_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$thumb = _vm$program.thumb) === null || _vm$program$thumb === void 0 ? void 0 : _vm$program$thumb.src) || '/images/sub/program/list/program-item-default.jpg',
      "max-width": "440",
      "aspect-ratio": 1 / 1
    }
  }), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "v-chip--noactive grey--text",
    attrs: {
      "small": "",
      "color": "grey lighten-4"
    }
  }, [_vm._v(_vm._s((_vm$program2 = _vm.program) === null || _vm$program2 === void 0 ? void 0 : (_vm$program2$category = _vm$program2.category) === null || _vm$program2$category === void 0 ? void 0 : _vm$program2$category.name))])], 1), _vm.isForAllUserTypes ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "v-chip--noactive",
    attrs: {
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("전체회원")])], 1)] : _vm._l(_vm.userTypeTexts, function (text) {
    return [_c('v-col', {
      key: text,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      staticClass: "v-chip--noactive",
      attrs: {
        "small": "",
        "color": "primary"
      }
    }, [_vm._v(_vm._s(text))])], 1)];
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.program.state == _vm.PROGRAM_STATES.FINISHED.value ? [_c('v-chip', {
    staticClass: "v-chip--noactive",
    attrs: {
      "small": "",
      "dark": "",
      "color": "grey"
    }
  }, [_vm._v(" " + _vm._s((_vm$program3 = _vm.program) === null || _vm$program3 === void 0 ? void 0 : _vm$program3.stateText) + " ")])] : [_c('v-chip', {
    staticClass: "v-chip--noactive",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v(" " + _vm._s((_vm$program4 = _vm.program) === null || _vm$program4 === void 0 ? void 0 : _vm$program4.stateText) + " ")])]], 2)], 2), _c('h4', {
    staticClass: "tit ellip mt-10"
  }, [_vm._v(_vm._s((_vm$program5 = _vm.program) === null || _vm$program5 === void 0 ? void 0 : _vm$program5.name))]), _c('v-row', {
    key: _vm.unit._id,
    staticClass: "page-text page-text--lg grey--text mt-12 mt-md-16",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "pr-8"
  }, [_vm._v(_vm._s(_vm.date) + " (" + _vm._s((_vm$unit = _vm.unit) === null || _vm$unit === void 0 ? void 0 : _vm$unit.day) + ")")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', [_vm._v(_vm._s((_vm$unit2 = _vm.unit) === null || _vm$unit2 === void 0 ? void 0 : _vm$unit2.timeStart) + " ~ " + _vm._s((_vm$unit3 = _vm.unit) === null || _vm$unit3 === void 0 ? void 0 : _vm$unit3.timeEnd))])])], 1), _c('v-divider', {
    staticClass: "mt-12 mt-md-16"
  }), _c('p', {
    staticClass: "page-text page-text--lg grey--text mt-12 mt-md-16"
  }, [_vm._v(_vm._s((_vm$program6 = _vm.program) === null || _vm$program6 === void 0 ? void 0 : (_vm$program6$applicat = _vm$program6.application) === null || _vm$program6$applicat === void 0 ? void 0 : _vm$program6$applicat.stateText) + " (총 교육인원 " + _vm._s((_vm$program7 = _vm.program) === null || _vm$program7 === void 0 ? void 0 : (_vm$program7$capacity = _vm$program7.capacity) === null || _vm$program7$capacity === void 0 ? void 0 : (_vm$program7$capacity2 = _vm$program7$capacity.format) === null || _vm$program7$capacity2 === void 0 ? void 0 : _vm$program7$capacity2.call(_vm$program7$capacity)) + "명 / 현재 참여 " + _vm._s(((_vm$program8 = _vm.program) === null || _vm$program8 === void 0 ? void 0 : (_vm$program8$meta = _vm$program8.meta) === null || _vm$program8$meta === void 0 ? void 0 : (_vm$program8$meta$sum = _vm$program8$meta.summary) === null || _vm$program8$meta$sum === void 0 ? void 0 : _vm$program8$meta$sum.applications__active) || 0) + "명)")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }