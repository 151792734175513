var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.small ? _c('section', {
    staticClass: "page-section"
  }, [_c('v-container', {
    class: _vm.large ? 'container--lg' : ''
  }, [_vm.title ? [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_vm.subtitle ? [_c('div', {
    staticClass: "page-text page-text--lg primary--text line-height-1 mb-12 mb-md-16"
  }, [_vm._v(" " + _vm._s(_vm.subtitle) + " ")])] : _vm._e(), _c('h4', {
    staticClass: "tit tit--lg line-height-1"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 2)] : _vm._e(), _vm._t("default")], 2)], 1) : _c('section', {
    staticClass: "page-section--sm"
  }, [_vm.title ? _c('div', {
    class: _vm.smallTitleSpacing
  }, [_c('h4', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }