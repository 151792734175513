<template>
    <client-page>
        <sub-visual sh="프로그램" tabActive="프로그램 신청" />

        <page-section title="프로그램 신청" class="page-section--first page-section--last">
            <program-list-nav />

            <v-row class="my-n20 my-md-n30">
                <template v-for="program in programs">
                    <v-col cols="6" md="4" class="py-20 py-md-30" :key="program._id">
                        <program-list-item v-bind="{ program }" />
                    </v-col>
                </template>
            </v-row>

            <div class="v-pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="20" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initProgram, PROGRAM_STATES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import ProgramListNav from "@/components/client/programs/list/program-list-nav.vue";
import ProgramListItem from "@/components/client/programs/list/program-list-item.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        ProgramListNav,
        ProgramListItem,
    },
    props: {
        state: { type: String, default: null },
    },
    data: () => ({
        programs: [],

        limit: 20,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            switch (this.state) {
                case PROGRAM_STATES.FINISHED.value: {
                    query.state = this.state;
                    break;
                }
                case PROGRAM_STATES.ON_GOING.value: {
                    query.state = [PROGRAM_STATES.ON_GOING.value, PROGRAM_STATES.UPCOMING.value];
                    break;
                }
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.programs = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, programs } = await api.v1.programs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.programs = programs.map(initProgram);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
