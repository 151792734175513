<template>
    <div class="table-responsive">
        <v-simple-table class="table--primary table--primary--large" style="min-width: 576px">
            <thead>
                <tr>
                    <th style="width: 8%">번호</th>
                    <th style="width: 10%">구분</th>
                    <th style="width: 50%">내용</th>
                    <th style="width: 20%">신청기간</th>
                    <th style="width: 12%">상태</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="program in items" :key="program._id">
                    <td>{{ program.index }}</td>
                    <td>{{ program.categoryName }}</td>
                    <td>
                        <router-link :to="`${$route.path}/${program?._id}`" class="text-left ellip">{{ program.title }}</router-link>
                    </td>
                    <service-support-duration :value="program" />
                    <td>
                        <v-chip color="primary-light" label v-if="program.status == '모집중'"><span class="primary--text">{{ program.status }}</span></v-chip>
                        <v-chip color="primary-dark" label v-else><span class="white--text">{{ program.status }}</span></v-chip>
                        <!-- <span v-if="program.status == '모집중'" class="color-primary">{{ program.status }}</span>
                        <span v-else>{{ program.status }}</span> -->
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import { SERVICE_PROGRAM_CATEGORIES } from "@/assets/variables";
import ServiceSupportDuration from "./service-support-duration.vue";
export default {
    components: {
        ServiceSupportDuration,
    },
    props: ["programs", "category", "baseIndex"],
    computed: {
        items() {
            return this.programs.map((program, index) => ({
                ...program,
                index: this.baseIndex - index,
                categoryName: SERVICE_PROGRAM_CATEGORIES[program.category]?.text,
            }));
        },
    },
};
</script>

<style></style>
