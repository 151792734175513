var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "100%",
      "max-width": "800",
      "content-class": "v-dialog--primary"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-sheet', {
    staticClass: "v-dialog--primary__head"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('i', {
    staticClass: "icon icon-close"
  })])], 1), _c('v-sheet', {
    staticClass: "v-dialog--primary__body"
  }, [_c('h4', {
    staticClass: "tit mb-16 mb-md-20"
  }, [_vm._v("개인정보처리방침")]), _c('v-divider', {
    staticClass: "grey darken-4 mb-16 mb-md-20",
    staticStyle: {
      "border-top-width": "2px"
    }
  }), _c('v-card', {
    attrs: {
      "outlined": "",
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "pa-12"
  }, [_c('terms-component', {
    attrs: {
      "code": "privacy-policy"
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }