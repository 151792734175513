var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tit-primary', {
    attrs: {
      "sc": _vm.sc,
      "tit": _vm.tit,
      "titTxt": _vm.titTxt
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }