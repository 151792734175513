<template>
    <section v-if="!small" class="page-section">
        <v-container :class="large ? 'container--lg' : ''">
            <template v-if="title">
                <div class="tit-wrap tit-wrap--lg text-center">
                    <template v-if="subtitle">
                        <div class="page-text page-text--lg primary--text line-height-1 mb-12 mb-md-16">
                            {{ subtitle }}
                        </div>
                    </template>
                    <h4 class="tit tit--lg line-height-1">
                        {{ title }}
                    </h4>
                </div>
            </template>
            <slot />
        </v-container>
    </section>
    <section v-else class="page-section--sm">
        <div v-if="title" :class="smallTitleSpacing">
            <h4 class="tit">{{ title }}</h4>
        </div>
        <slot />
    </section>
</template>

<script>
export default {
    props: {
        title: { type: String, default: null },
        subtitle: { type: String, default: null },
        small: { type: Boolean, default: false },
        large: { type: Boolean, default: false },
        TitleSpacing: { type: String, default: "mb-20 mb-md-40" },
        smallTitleSpacing: { type: String, default: "mb-12 mb-md-20" },
    },
    components: {},
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped>
.page-section {
    min-height: calc(100vh - 300px);
    padding: 50px 0;
    &--first {
        padding-top: 80px;
    }
    &--last {
        padding-bottom: 80px;
    }
    &--sm {
        margin-bottom: 40px;
        &--last {
            margin-bottom: 0 !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .page-section {
        padding: 60px 0;
        &--first {
            padding-top: 120px;
        }
        &--last {
            padding-bottom: 120px;
        }
        &--sm {
            margin-bottom: 80px;
        }
    }
}
@media (min-width: 1024px) {
    .page-section {
        padding: 80px 0;
        &--first {
            padding-top: 100px;
        }
        &--last {
            padding-bottom: 160px;
        }
        &--sm {
            margin-bottom: 100px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>