<template>
    <v-tabs v-model="placeIndex" v-bind="$attrs" class="v-tabs--primary" @change="emit">
        <template v-for="{ text, value } in items">
            <v-tab :key="value" v-bind="{ value }" exact>{{ text }}</v-tab>
        </template>
    </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
    components: {},
    data: () => ({
        placeIndex: 0,
    }),
    computed: {
        ...mapState(["setting"]),
        items() {
            return [{ text: "전체", value: null }, ...(this.setting?.rental?.equipment?.places || []).map((text) => ({ text, value: text }))];
        },
        place() {
            return this.items[this.placeIndex]?.value;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        items() {
            this.sync();
        },
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.placeIndex = this.items.findIndex((item) => item.value == (this.$route.query.place ?? null));
        },

        emit() {
            if (this.items.length <= 1) return;

            const { ...query } = this.$route.query;

            delete query.page;
            query.place = this.place;
            if (!query.place) delete query.place;

            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>