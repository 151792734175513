var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "프로그램",
      "tabActive": "프로그램 안내"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "프로그램 안내"
    }
  }, [_c('v-row', {
    staticClass: "my-n20 my-md-n30"
  }, _vm._l(_vm.program, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "py-20 py-md-30",
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4"
      }
    }, [_c('v-layout', {
      attrs: {
        "fill-height": "",
        "justify-space-between": "",
        "column": ""
      }
    }, [_c('div', [_c('v-img', {
      staticClass: "w-100 rounded mb-20 md-md-40",
      attrs: {
        "src": item.image,
        "max-width": "440"
      }
    }), _c('h4', {
      staticClass: "tit mb-8 mb-md-16"
    }, [_vm._v(_vm._s(item.title))]), _c('ul', {
      staticClass: "page-text page-text--lg line-height-15 word-keep-all mb-n4 mb-md-n10"
    }, _vm._l(item.info, function (detail) {
      return _c('li', {
        key: detail,
        staticClass: "mb-4 mb-md-10"
      }, [_vm._v(_vm._s(detail))]);
    }), 0)], 1), item.link ? _c('div', {
      staticClass: "mt-12 mt-sm-20 mt-md-40"
    }, [item.blank ? _c('v-btn', {
      attrs: {
        "href": item.link,
        "target": "_blank",
        "rounded": "",
        "outlined": "",
        "color": "secondary"
      }
    }, [_vm._v("신청하기")]) : _c('v-btn', {
      attrs: {
        "to": item.link,
        "rounded": "",
        "outlined": "",
        "color": "secondary"
      }
    }, [_vm._v("신청하기")])], 1) : _vm._e()])], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }