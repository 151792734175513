<template>
  <div
    class="rounded pa-20 pa-md-30 py-lg-80 mb-30 mb-lg-60"
    style="background-color: #f7f8fa"
  >
    <v-row class="row--large" justify="center">
      <v-col cols="12" md="6" lg="auto" class="mb-20 mb-md-0">
        <div class="mb-10 mb-lg-20">
          <v-row>
            <v-col cols="6" md="4" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon1-1.svg"
                tit="회로설계"
                sc="(거버 / BOm)"
              >
              </sub-icon-card>
            </v-col>
            <v-col cols="6" md="4" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon1-2.svg"
                tit="기업 컨설팅"
              >
              </sub-icon-card>
            </v-col>
            <v-col cols="12" md="4" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon1-3.svg"
                tit="표면실장 공정"
              >
              </sub-icon-card>
            </v-col>
          </v-row>
        </div>
        <div class="head-priamry rounded" style="background-color: #6f97f3">
          <p class="page-text--sm">KEA 엔지니어</p>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="auto" class="mb-20 mb-md-0">
        <div class="mb-10 mb-lg-20">
          <v-row>
            <v-col cols="6" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon2-1.svg"
                tit="자삽 수삽"
              >
              </sub-icon-card>
            </v-col>
            <v-col cols="6" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon2-2.svg"
                tit="조립"
                sc="(케이스)"
              >
              </sub-icon-card>
            </v-col>
          </v-row>
        </div>
        <div class="head-priamry rounded" style="background-color: #4377eb">
          <p class="page-text--sm">KEA 장비오퍼레이터</p>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="auto" class="mb-20 mb-md-0">
        <div class="mb-10 mb-lg-20">
          <v-row>
            <v-col cols="6" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon3-1.svg"
                tit="고객평가"
                sc="(검수)"
              >
              </sub-icon-card>
            </v-col>
            <v-col cols="6" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon3-2.svg"
                tit="테스트"
              >
              </sub-icon-card>
            </v-col>
          </v-row>
        </div>
        <div class="head-priamry rounded" style="background-color: #4377eb">
          <p class="page-text--sm">오픈디버깅랩/ 측정평가룸</p>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="auto">
        <div class="mb-10 mb-lg-20">
          <v-row>
            <v-col cols="6" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon4-1.svg"
                tit="환류"
                sc="(고객반응분석)"
              >
              </sub-icon-card>
            </v-col>
            <v-col cols="6" lg="auto">
              <sub-icon-card
                icon="/images/sub/service/support/s1-icon4-2.svg"
                tit="유통지원"
              >
              </sub-icon-card>
            </v-col>
          </v-row>
        </div>
        <div class="head-priamry rounded" style="background-color: #2e60ef">
          <p class="page-text--sm">플랫폼</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubIconCard from "../sub/sub-icon-card.vue";
export default {
  components: {
    SubIconCard,
  },
};
</script>

<style></style>
