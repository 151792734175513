import { render, staticRenderFns } from "./FacilitiesPage.vue?vue&type=template&id=cb76103c&scoped=true"
import script from "./FacilitiesPage.vue?vue&type=script&lang=js"
export * from "./FacilitiesPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb76103c",
  null
  
)

export default component.exports