<template>
    <div role="table" class="board-table board-table--input">
        <div role="rowgroup">
            <input-row label="지원서비스명">
                <span>{{ programTitle }}</span>
            </input-row>
            <input-row label="기업명" pointer>
                <input-item v-model="applicant.companyName" @input="sync" />
            </input-row>
            <input-row label="대표자명" pointer>
                <input-item v-model="applicant.ceoName" @input="sync" />
            </input-row>
            <input-row label="사업자등록번호" pointer>
                <input-item v-model="applicant.businessNumber" @input="sync" />
            </input-row>
            <input-row label="기업설립일" pointer>
                <v-row class="row--small">
                    <v-col cols="4">
                        <input-birth class="w-100" mode="년" v-model="applicant.establishedY" @input="sync" />
                    </v-col>
                    <v-col cols="4">
                        <input-birth class="w-100" mode="월" v-model="applicant.establishedM" @input="sync" />
                    </v-col>
                    <v-col cols="4">
                        <input-birth class="w-100" mode="일" v-model="applicant.establishedD" :year="applicant.establishedY" :month="applicant.establishedM" @input="sync" />
                    </v-col>
                </v-row>
            </input-row>
            <input-row label="연락처" pointer>
                <v-row align="center" class="row--x-small">
                    <v-col>
                        <input class="w-100" v-model="applicant.phone1" type="tel" name="phone1" maxlength="4" @input="sync" />
                    </v-col>
                    <v-col cols="auto">-</v-col>
                    <v-col>
                        <input class="w-100" v-model="applicant.phone2" type="tel" name="phone2" maxlength="4" @input="sync" />
                    </v-col>
                    <v-col cols="auto">-</v-col>
                    <v-col>
                        <input class="w-100" v-model="applicant.phone3" type="tel" name="phone3" maxlength="4" @input="sync" />
                    </v-col>
                </v-row>
                <!-- <div class="form-tel">
                    <input class="input" v-model="applicant.phone1" type="tel" name="phone1" maxlength="4" @input="sync" />
                    <span class="d-inline-block mx-4 mx-lg-10">-</span>
                    <input class="input" v-model="applicant.phone2" type="tel" name="phone2" maxlength="4" @input="sync" />
                    <span class="d-inline-block mx-4 mx-lg-10">-</span>
                    <input class="input" v-model="applicant.phone3" type="tel" name="phone3" maxlength="4" @input="sync" />
                </div> -->
            </input-row>
            <input-row label="이메일" pointer>
                <input-email v-model="applicant" @input="sync" />
            </input-row>
            <input-row label="소재지" pointer>
                <input-address v-model="applicant" @input="sync" />
            </input-row>
            <input-row label="홈페이지 주소">
                <input-item v-model="applicant.website" @input="sync" />
            </input-row>
            <input-row label="담당자명/직책">
                <div class="row row--small">
                    <div class="col-12 col-md-6">
                        <input-item v-model="applicant.workerName" @input="sync" />
                    </div>
                    <div class="col-12 col-md-6">
                        <input-item v-model="applicant.workerTitle" @input="sync" />
                    </div>
                </div>
            </input-row>
            <input-row label="주요생산제품">
                <input-item v-model="applicant.mainProduct" @input="sync" />
            </input-row>
            <input-row label="파일첨부" pointer>
                <v-row align="center" class="row--x-small">
                    <v-col cols="auto">
                        <v-file-btn v-model="files" class="input-btn min-w-80px min-w-lg-100px color-white" color="grey-dark" multiple elevation="0">파일첨부</v-file-btn>
                    </v-col>
                    <v-col>
                        <v-chip-group v-if="files.length" v-model="files" multiple column>
                            <v-chip v-for="file in files" :key="file.name" :value="file" close>{{ file.name }}</v-chip>
                        </v-chip-group>
                        <span v-else>첨부된 파일이 없습니다.</span>
                    </v-col>
                </v-row>
            </input-row>
        </div>
    </div>
</template>

<script>
import { SERVICE_PROGRAM_CATEGORIES } from "@/assets/variables";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";
import InputRow from "@/components/client/dumb/input-row.vue";
import InputItem from "@/components/client/dumb/input-item.vue";
import InputBirth from "@/components/client/dumb/input-birth.vue";
import InputEmail from "@/components/client/dumb/input-email.vue";
import InputAddress from "@/components/client/dumb/input-address.vue";
export default {
    components: {
        VFileBtn,
        InputRow,
        InputItem,
        InputBirth,
        InputEmail,
        InputAddress,
    },
    props: ["user", "value", "program"],
    computed: {
        establishedAt() {
            const { establishedY, establishedM, establishedD } = this.applicant;
            if (establishedY && establishedM && establishedD) return `${establishedY}-${String(establishedM).padStart(2, "0")}-${String(establishedD).padStart(2, "0")}`;
        },
        phone() {
            const { phone1, phone2, phone3 } = this.applicant;

            return `${phone1}-${phone2}-${phone3}`;
        },
        email() {
            const { email1, email2 } = this.applicant;

            return `${email1}@${email2}`;
        },
        computedApplication() {
            const { establishedAt, phone, email } = this;
            return { ...this.applicant, establishedAt, email, phone };
        },
        programTitle() {
            return this.program?.title;
        },
        isTotalSupport() {
            return this.program.category == SERVICE_PROGRAM_CATEGORIES.TOTAL.value;
        },
    },
    data() {
        return {
            applicant: {},
            files: [],
        };
    },
    methods: {
        init() {
            const { _id: _user, companyName, ceoName, businessNumber, establishedAt, phone, email, postcode, address1, address2, website, workerName, workerTitle, mainProduct } = this.user;

            const [establishedY, establishedM, establishedD] = (establishedAt?.toDate?.()?.split?.("-") || []).map((value) => +value);
            const [phone1, phone2, phone3] = phone?.split("-");
            const [email1, email2] = email?.split("@");

            this.applicant = {
                _user,
                companyName,
                ceoName,
                businessNumber,
                establishedY,
                establishedM,
                establishedD,
                phone1,
                phone2,
                phone3,
                email1,
                email2,
                postcode,
                address1,
                address2,
                website,
                workerName,
                workerTitle,
                mainProduct,
            };
            this.sync();
        },
        sync() {
            this.$emit("input", this.computedApplication);
        },
    },
    watch: {
        user() {
            if (this.user) this.init();
        },
        files() {
            this.$emit("files", this.files);
        },
    },
};
</script>

<style></style>
