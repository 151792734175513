var render = function render(){
  var _vm$program, _vm$program$category, _vm$program2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-table board-table--input",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "이름",
      "pointer": ""
    }
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.name,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "name", $$v);
      },
      expression: "applicant.name"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "학교/학과/과정",
      "pointer": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-item', {
    attrs: {
      "placeholder": "학교"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.affiliation,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "affiliation", $$v);
      },
      expression: "applicant.affiliation"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-item', {
    attrs: {
      "placeholder": "학과"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.department,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "department", $$v);
      },
      expression: "applicant.department"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-item', {
    attrs: {
      "placeholder": "과정"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.courseName,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "courseName", $$v);
      },
      expression: "applicant.courseName"
    }
  })], 1)], 1)], 1), _c('input-row', {
    attrs: {
      "label": "국가연구자번호"
    }
  }, [_c('input-item', {
    attrs: {
      "placeholder": "과학기술인등록번호"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.researcherNo,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "researcherNo", $$v);
      },
      expression: "applicant.researcherNo"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "생년월일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-birth', {
    staticClass: "w-100",
    attrs: {
      "mode": "년"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.bornY,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "bornY", $$v);
      },
      expression: "applicant.bornY"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-birth', {
    staticClass: "w-100",
    attrs: {
      "mode": "월"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.bornM,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "bornM", $$v);
      },
      expression: "applicant.bornM"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-birth', {
    staticClass: "w-100",
    attrs: {
      "mode": "일",
      "year": _vm.applicant.bornY,
      "month": _vm.applicant.bornM
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.bornD,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "bornD", $$v);
      },
      expression: "applicant.bornD"
    }
  })], 1)], 1)], 1), _c('input-row', {
    attrs: {
      "label": "연락처",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.phone1,
      expression: "applicant.phone1"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "tel",
      "name": "phone1",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.applicant.phone1
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "phone1", $event.target.value);
      }, _vm.sync]
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.phone2,
      expression: "applicant.phone2"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "tel",
      "name": "phone2",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.applicant.phone2
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "phone2", $event.target.value);
      }, _vm.sync]
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.phone3,
      expression: "applicant.phone3"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "tel",
      "name": "phone3",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.applicant.phone3
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "phone3", $event.target.value);
      }, _vm.sync]
    }
  })])], 1)], 1), _c('input-row', {
    attrs: {
      "label": "이메일",
      "pointer": ""
    }
  }, [_c('input-email', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant,
      callback: function ($$v) {
        _vm.applicant = $$v;
      },
      expression: "applicant"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "신청교육과정명"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$category = _vm$program.category) === null || _vm$program$category === void 0 ? void 0 : _vm$program$category.name) + " > " + _vm._s((_vm$program2 = _vm.program) === null || _vm$program2 === void 0 ? void 0 : _vm$program2.name))])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }