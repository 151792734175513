var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "프로그램",
      "tabActive": "프로그램 신청"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "프로그램 신청"
    }
  }, [_c('program-list-nav'), _c('v-row', {
    staticClass: "my-n20 my-md-n30"
  }, [_vm._l(_vm.programs, function (program) {
    return [_c('v-col', {
      key: program._id,
      staticClass: "py-20 py-md-30",
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('program-list-item', _vm._b({}, 'program-list-item', {
      program
    }, false))], 1)];
  })], 2), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }