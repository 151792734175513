var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "게시판",
      "tabActive": "1:1문의"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "1:1문의"
    }
  }, [_c('inquiry-input')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }