var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rounded pa-20 pa-md-30 py-lg-80 mb-30 mb-lg-60",
    staticStyle: {
      "background-color": "#f7f8fa"
    }
  }, [_c('v-row', {
    staticClass: "row--large",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-20 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-10 mb-lg-20"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon1-1.svg",
      "tit": "회로설계",
      "sc": "(거버 / BOm)"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon1-2.svg",
      "tit": "기업 컨설팅"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon1-3.svg",
      "tit": "표면실장 공정"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "head-priamry rounded",
    staticStyle: {
      "background-color": "#6f97f3"
    }
  }, [_c('p', {
    staticClass: "page-text--sm"
  }, [_vm._v("KEA 엔지니어")])])]), _c('v-col', {
    staticClass: "mb-20 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-10 mb-lg-20"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon2-1.svg",
      "tit": "자삽 수삽"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon2-2.svg",
      "tit": "조립",
      "sc": "(케이스)"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "head-priamry rounded",
    staticStyle: {
      "background-color": "#4377eb"
    }
  }, [_c('p', {
    staticClass: "page-text--sm"
  }, [_vm._v("KEA 장비오퍼레이터")])])]), _c('v-col', {
    staticClass: "mb-20 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-10 mb-lg-20"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon3-1.svg",
      "tit": "고객평가",
      "sc": "(검수)"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon3-2.svg",
      "tit": "테스트"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "head-priamry rounded",
    staticStyle: {
      "background-color": "#4377eb"
    }
  }, [_c('p', {
    staticClass: "page-text--sm"
  }, [_vm._v("오픈디버깅랩/ 측정평가룸")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-10 mb-lg-20"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon4-1.svg",
      "tit": "환류",
      "sc": "(고객반응분석)"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('sub-icon-card', {
    attrs: {
      "icon": "/images/sub/service/support/s1-icon4-2.svg",
      "tit": "유통지원"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "head-priamry rounded",
    staticStyle: {
      "background-color": "#2e60ef"
    }
  }, [_c('p', {
    staticClass: "page-text--sm"
  }, [_vm._v("플랫폼")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }