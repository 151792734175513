var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('v-simple-table', {
    staticClass: "table--primary table--primary--large",
    staticStyle: {
      "min-width": "576px"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "8%"
    }
  }, [_vm._v("번호")]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("구분")]), _c('th', {
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v("내용")]), _c('th', {
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v("신청기간")]), _c('th', {
    staticStyle: {
      "width": "12%"
    }
  }, [_vm._v("상태")])])]), _c('tbody', _vm._l(_vm.items, function (program) {
    return _c('tr', {
      key: program._id
    }, [_c('td', [_vm._v(_vm._s(program.index))]), _c('td', [_vm._v(_vm._s(program.categoryName))]), _c('td', [_c('router-link', {
      staticClass: "text-left ellip",
      attrs: {
        "to": `${_vm.$route.path}/${program === null || program === void 0 ? void 0 : program._id}`
      }
    }, [_vm._v(_vm._s(program.title))])], 1), _c('service-support-duration', {
      attrs: {
        "value": program
      }
    }), _c('td', [program.status == '모집중' ? _c('v-chip', {
      attrs: {
        "color": "primary-light",
        "label": ""
      }
    }, [_c('span', {
      staticClass: "primary--text"
    }, [_vm._v(_vm._s(program.status))])]) : _c('v-chip', {
      attrs: {
        "color": "primary-dark",
        "label": ""
      }
    }, [_c('span', {
      staticClass: "white--text"
    }, [_vm._v(_vm._s(program.status))])])], 1)], 1);
  }), 0)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }