<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual visual="제조지원·교육"></sub-visual>
        <!-- 내비게이션 -->
        <service-navigation-row />

        <section class="section">
            <v-container>
                <service-program-tit/>
                <div class="board-container">
                    <service-program-input v-model="applicant" v-bind="{ user, program }" />
                    <!-- <div class="board-buttons board-buttons--center">
                        <div class="right">
                            <a class="button button--border-primary" @click="$router.go(-1)"><span>취소</span></a>
                            <a class="button button--primary" @click="save"><span>신청하기</span></a>
                        </div>
                    </div> -->
                    <div class="button-group">
                        <v-btn large outlined rounded color="grey" elevation="0" @click="$router.go(-1)" class="min-w-120px min-w-lg-200px"><span class="color-grey">취소</span></v-btn>
                        <v-btn large rounded color="primary" elevation="0" @click="save" class="min-w-120px min-w-lg-200px"><span>신청하기</span></v-btn>
                    </div>
                </div>
            </v-container>
        </section>
        
    </client-page>
</template>

<script>
import api from "@/api";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ServiceProgramTit from "@/components/client/service/service-program-tit.vue";
import ServiceProgramInput from "@/components/client/service/service-program-input.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";

export default {
    props: ["_program"],
    components: {
        SubVisual,
        ClientPage,
        ServiceProgramInput,
        ServiceNavigationRow,
        ServiceProgramTit,
    },
    data() {
        return {
            user: null,
            program: {},
            applicant: {},
        };
    },
    methods: {
        async init() {
            try {
                const { program } = await api.v1.programs.get({ _id: this._program });
                this.program = program;

                const { user } = await api.v1.me.get();
                this.user = user;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async save() {
            try {
                const { _program, applicant } = this;

                await api.v1.me.programs.applications.post({ _program, applicant });
                alert("신청이 완료되었습니다.");

                this.$router.push(this.$route.path.replace("/apply", "/complete"));
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        if (this.$store.state.payload._user) this.init();
        else {
            alert("권한이 없습니다.");
            this.$router.go(-1);
        }
    },
};
</script>
