<template>
    <div class="h-100 icon-card rounded page-text--sm color-grey pa-20 pb-lg-36 pt-lg-58 d-flex flex-column align-center text-center">
        <v-img :src="icon" contain max-height="59" class="mb-6 mb-lg-10"></v-img>
        <p>{{tit}}</p>
        <p v-if="sc">{{sc}}</p>
    </div>
</template>

<script>

export default{
    props : {
        icon : {type :String, default : ""},
        tit : {type :String, default : ""},
        sc : {type :String, default : ""},
    },
	components: {

	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style scoped>
.icon-card{
    width: 100%;
    border: 1px solid #6f97f3;
    background-color: #fff;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-card{
        width: 186px;
    }
}
@media (min-width:1200px){
}
</style>