var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.email1,
      expression: "applicant.email1"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "name": "email1"
    },
    domProps: {
      "value": _vm.applicant.email1
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "email1", $event.target.value);
      }
    }
  })]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("@")]), _c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.email2,
      expression: "applicant.email2"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "name": "email2",
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.applicant.email2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "email2", $event.target.value);
      }
    }
  })]), _c('v-col', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selected,
      expression: "selected"
    }],
    staticClass: "w-100",
    on: {
      "input": _vm.syncEmail2,
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selected = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("직접입력")]), _c('option', {
    attrs: {
      "value": "naver.com"
    }
  }, [_vm._v("naver.com")]), _c('option', {
    attrs: {
      "value": "daum.net"
    }
  }, [_vm._v("daum.net")]), _c('option', {
    attrs: {
      "value": "google.com"
    }
  }, [_vm._v("google.com")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }