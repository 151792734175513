var render = function render(){
  var _vm$subject, _vm$subject$thumb, _vm$subject2, _vm$subject3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "rental-subject-card v-card--noactive",
    attrs: {
      "flat": ""
    }
  }, 'v-card', {
    to: _vm.to
  }, false), [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-img', {
    staticClass: "rounded border w-100",
    attrs: {
      "aspect-ratio": 1 / 1,
      "src": (_vm$subject = _vm.subject) === null || _vm$subject === void 0 ? void 0 : (_vm$subject$thumb = _vm$subject.thumb) === null || _vm$subject$thumb === void 0 ? void 0 : _vm$subject$thumb.url,
      "max-width": "324"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "z-index": "0",
            "opacity": ".1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "pl-lg-20"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('p', {
    staticClass: "page-text font-weight-semibold secondary--text mb-4",
    class: _vm.isListItem ? 'page-text--sm' : ''
  }, [_vm._v(" " + _vm._s(_vm.subject.code) + " ")]), _c('h4', {
    staticClass: "tit",
    class: _vm.isListItem ? 'line-height-15' : 'tit--md'
  }, [_vm._v(" " + _vm._s((_vm$subject2 = _vm.subject) === null || _vm$subject2 === void 0 ? void 0 : _vm$subject2.name) + " ")])]), _vm.isListItem && !_vm.readonly ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-end": ""
    }
  }, [_vm.disabled ? [_c('v-btn', {
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "secondary",
      "disabled": ""
    }
  }, [_vm._v(" 사용예약이 중지된 설비입니다 ")])] : !_vm.isRentableForUser ? [_c('v-btn', {
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "secondary",
      "disabled": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.notRentableMessage) + " ")])] : _c('v-btn', {
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "secondary"
    }
  }, [_vm._v("사용예약")])], 2)], 1) : _vm._e()], 1), _vm.isListItem ? _c('v-divider', {
    staticClass: "mt-16 mt-md-20"
  }) : _vm._e(), _c('div', {
    staticClass: "mb-n4 mt-16 mt-md-24"
  }, [_vm._l(_vm.descriptions, function (_ref) {
    var title = _ref.title,
      info = _ref.info;
    return _c('info-row-inline', _vm._b({
      key: title,
      class: {
        'white-space-pre-line': _vm.whiteSpacePreLine
      }
    }, 'info-row-inline', {
      title,
      info
    }, false));
  }), _c('p', {
    class: {
      'page-text page-text--lg grey--text': true,
      'white-space-pre-line': _vm.whiteSpacePreLine
    }
  }, [_vm._v(_vm._s((_vm$subject3 = _vm.subject) === null || _vm$subject3 === void 0 ? void 0 : _vm$subject3.desc))])], 2)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }