<template>
    <v-text-field v-model="query.name" placeholder="검색어를 입력해주세요." hide-details outlined class="search-input" @keydown.enter="emit">
        <template #append>
            <v-btn icon text color="secondary" @click="emit">
                <i class="icon icon-search" />
            </v-btn>
        </template>
    </v-text-field>
</template>

<script>
const initQuery = (query = {}) => ({
    ...query,
    name: query.name ?? null,
});

export default {
    props: {
        type: { type: String, default: "" },
    },
    data: () => ({
        query: initQuery(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            if (!query.name) delete query.name;

            this.$router.push({ query });
        },
    },
};
</script>