<template>
    <div>
        <v-row class="row--small">
            <v-col sm="10">
                <input class="w-100" v-model="inputData.postcode" type="text" readonly placeholder="우편번호" outlined hide-details @click="$refs.daumPostcode.open()" />
            </v-col>
            <v-col cols="auto" sm="2">
                <v-btn block elevation="0" dark color="grey-dark"
                @click="$refs.daumPostcode.open()" class="w-100 h-100 input-btn"><span>검색</span></v-btn>
            </v-col>
            <v-col cols="12">
                <input class="w-100" v-model="inputData.address1" type="text" readonly placeholder="기본 주소를 입력해주세요." outlined hide-details @click="$refs.daumPostcode.open()" />
            </v-col>
            <v-col cols="12">
                <input class="w-100" v-model="inputData.address2" type="text" placeholder="상세 주소를 입력해주세요." outlined hide-details />
            </v-col>
        </v-row>
        <daum-postcode ref="daumPostcode" @change="({ postcode, address }) => Object.assign(inputData, { postcode, address1: address })"></daum-postcode>   
    </div>
    <!-- <div class="form-address">
        <div class="form-address__postcode">
            <input class="input" v-model="inputData.postcode" type="text" readonly placeholder="우편번호" outlined hide-details @click="$refs.daumPostcode.open()" />
            <button class="button button--primary" @click="$refs.daumPostcode.open()">검색</button>
        </div>
        <input class="input" v-model="inputData.address1" type="text" readonly placeholder="기본 주소를 입력해주세요." outlined hide-details @click="$refs.daumPostcode.open()" />
        <input class="input" v-model="inputData.address2" type="text" placeholder="상세 주소를 입력해주세요." outlined hide-details />
        <daum-postcode ref="daumPostcode" @change="({ postcode, address }) => Object.assign(inputData, { postcode, address1: address })"></daum-postcode>
    </div> -->
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
export default {
    components: { DaumPostcode },
    props: ["value"],
    data() {
        return {
            inputData: {
                postcode: null,
                address1: null,
                address2: null,
            },
        };
    },
    methods: {
        init() {
            const { postcode, address1, address2 } = this.value;
            this.inputData = { postcode, address1, address2 };
        },
        sync() {
            const newValue = JSON.parse(JSON.stringify(this.value));
            Object.assignDefined(newValue, this.inputData);

            this.$emit("input", newValue);
        },
    },
    watch: {
        "value.postcode"() {
            if (this.value.postcode !== this.inputData.postcode) this.inputData.postcode = this.value.postcode;
        },
        "value.address1"() {
            if (this.value.address1 !== this.inputData.address1) this.inputData.address1 = this.value.address1;
        },
        "value.address2"() {
            if (this.value.address2 !== this.inputData.address2) this.inputData.address2 = this.value.address2;
        },
        "inputData.postcode"() {
            if (this.inputData.postcode !== this.value.postcode) this.sync();
        },
        "inputData.address1"() {
            if (this.inputData.address1 !== this.value.address1) this.sync();
        },
        "inputData.address2"() {
            if (this.inputData.address2 !== this.value.address2) this.sync();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style></style>
