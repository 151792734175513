var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "제조지원·교육"
    }
  }), _c('service-navigation-row'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('service-support-category', {
    attrs: {
      "category": _vm.category
    }
  }), _c('service-support-title', {
    attrs: {
      "category": _vm.category
    }
  }), _c('div', [_c('div', {
    staticClass: "card card--outline text-center pa-7 pa-md-16"
  }, [_c('h3', {
    staticClass: "text-28 text-md-22 mb-10"
  }, [_vm._v(_vm._s(_vm.title) + " 신청이 완료 되었습니다.")]), _c('div', {
    staticClass: "page-text color-gray-2"
  }, [_vm._l(_vm.description, function (row, i) {
    return _c('p', {
      key: `description-${i}`
    }, [_vm._v(_vm._s(row))]);
  }), _c('p', {
    staticClass: "color-primary"
  }, [_vm._v("관련문의 : 한국전자정보통신산업진흥회(yes@gokea.org)")])], 2)]), _c('div', {
    staticClass: "board-buttons board-buttons--center"
  }, [_c('button', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.go
    }
  }, [_vm._v("확인")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }