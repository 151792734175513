<template>
    <v-btn v-bind="$attrs" text color="grey darken-4" class="btn-idx w-lg-100 justify-start" @click="$emit('click')">
        <span :class="'btn-idx__idx '+idxStlye">{{idx}}</span>
        <span class="btn-idx__label">{{label}}</span>
    </v-btn>
</template>

<script>
export default{
    props : {
        idx: { type: String, default: "" },
        idxStlye: { type: String, default: "" },
        label: { type: String, default: "" },
    },
	components: {
	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep{
    .btn-idx{
        &__idx{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            font-size: 1.4rem;
            font-weight: 700;
            margin-right: 6px;
        }
        &__label{
            display: block;
            font-size: 1.6rem;
            font-weight: 400;
            color: var(--v-grey-darken4) !important;
            line-height: 1.35;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    ::v-deep{
        .btn-idx{
            &__idx{
                width: 28px;
                height: 28px;
                font-size: 1.6rem;
                margin-right: 8px;
            }
            &__label{
                font-size: 1.8rem;
            }
        }
    }
}
@media (min-width:1024px){
    ::v-deep{
        .btn-idx{
            &__label{
                max-width: 150px;
                text-align: left;
                white-space: normal;
                word-break: keep-all;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>