var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100 icon-card rounded page-text--sm color-grey pa-20 pb-lg-36 pt-lg-58 d-flex flex-column align-center text-center"
  }, [_c('v-img', {
    staticClass: "mb-6 mb-lg-10",
    attrs: {
      "src": _vm.icon,
      "contain": "",
      "max-height": "59"
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tit))]), _vm.sc ? _c('p', [_vm._v(_vm._s(_vm.sc))]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }