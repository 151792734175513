<template>
    <v-dialog v-model="show" width="100%" max-width="800" content-class="v-dialog--primary">
        <v-sheet class="v-dialog--primary__head">
            <v-btn icon text @click="close"><i class="icon icon-close"></i></v-btn>
        </v-sheet>
        <v-sheet class="v-dialog--primary__body">
            <h4 class="tit mb-16 mb-md-20">개인정보처리방침</h4>
            <v-divider class="grey darken-4 mb-16 mb-md-20" style="border-top-width: 2px;"/>
            <v-card outlined rounded="sm">
                <div class="pa-12">
                    <terms-component code="privacy-policy" />
                </div>
            </v-card>
        </v-sheet>
    </v-dialog>
</template>

<script>
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        TermsComponent,
    },
    data() {
        return {
            show: false,
        }
    },
    methods:{
        open(){
            this.show = true
        },
        close(){
            this.show = false
        }
    }
}
</script>

<style lang="scss" scoped>
</style>