var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasDates ? _c('td', [_vm._v(_vm._s(_vm.startsAt) + " - " + _vm._s(_vm.endsAt))]) : _c('td', [_vm._v("-")]);

}
var staticRenderFns = []

export { render, staticRenderFns }