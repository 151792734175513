var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rounded pa-20 pa-md-30 py-lg-80 mb-30 mb-lg-60",
    staticStyle: {
      "background-color": "#f7f8fa"
    }
  }, [_c('v-img', {
    staticClass: "d-none d-md-block mx-auto",
    attrs: {
      "src": "/images/sub/service/support/s1-img.png",
      "max-width": "1060",
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none mx-auto",
    attrs: {
      "src": "/images/sub/service/support/s1-img-mo.png",
      "max-width": "565",
      "contain": ""
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }