<template>
    <client-page>

        <sub-visual sh="소개" tabActive="입주현황" />

        <page-section title="입주현황" class="page-section--first page-section--last">
            <page-section small title="입주신청 개요">
                <v-divider class="grey darken-4 mb-12 mb-md-20" style="border-top-width: 2px;" />
                <v-sheet v-for="item in info" :key="item.title" class="mt-6 mt-md-14">
                    <v-row class="row--x-small">
                        <v-col cols="auto">
                            <p class="page-text page-text--lg font-weight-medium grey--text text--darken-3">{{item.title}}</p>
                        </v-col>
                        <v-col>
                            <p class="page-text page-text--lg grey--text">{{item.info}}</p>
                        </v-col>
                    </v-row>
                </v-sheet>
            </page-section>
            <page-section small title="지원내용">
                <v-row>
                    <v-col cols="12" sm="4" v-for="item in support" :key="item.icon">
                        <v-card flat rounded color="grey lighten-5" class="h-100">
                            <v-layout column align-center justify-center fill-height class="pa-20 pa-md-30 pa-lg-40 text-center word-keep-all">
                                <v-img :src="item.icon" width="50" max-height="50" :aspect-ratio="1 / 1" contain class="mb-12 mb-md-24" />
                                <p v-html="item.info" class="page-text page-text--lg line-height-15"></p>
                                <p v-if="item.caption" class="page-text grey--text mt-4">
                                    {{item.caption}}
                                </p>
                            </v-layout>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>
            <page-section small title="입주기업 현황">
                
                <!-- 입주기업 리스트 -->
                <company-list />

            </page-section>
            <page-section small class="page-section--sm--last">
                <v-layout justify-center>
                    <v-btn :href="href" target="_blank" x-large rounded color="secondary">입주신청</v-btn>
                </v-layout>
            </page-section>
        </page-section>

    </client-page>
</template>

<script>
import api from "@/api";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import CompanyList from "@/components/client/company/CompanyList.vue";
export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        CompanyList,
    },
    data() {
        return {
            info : [
                { title : "신청대상", info : "스마트소형가전 관련 기업으로 서울소재 7년 미만 스타트업, 중소기업", },                
                { title : "모집기간", info : "매년 상반기(2~3월), 하반기(7~8월/필요시)", },                
                { title : "입주위치", info : "서울시 용산구 원효상가 6동, 서울 용산시제품제작소 내", },                
                { title : "입주기간", info : "최초 6개월 경과 후 평가에 따라 1년까지 연장", },                
                { title : "문의처", info : "yes@gokea.org / 02-718-9966(서울 용산시제품제작소)", },
            ],
            support : [
                {
                    icon : "/images/sub/about/current/current-icon.svg",
                    info: "입주공간 4인실(28m²), <br class='d-none d-lg-block' />6인실(40m²) 제공",
                },
                {
                    icon : "/images/sub/about/current/current-icon2.svg",
                    info: " 1인작업공간 사용가능, 세미나룸, 라운지, <br class='d-none d-lg-block' />오픈조립장, 코워킹 스페이스 회의룸",
                },
                {
                    icon : "/images/sub/about/current/current-icon3.svg",
                    info: "스마트 소형가전 제품 공동개발 및 <br class='d-none d-lg-block' />제조지원 프로그램 운영",
                    caption : "* 제품개발, 초도양산, 전문교육 등 상시지원",
                },
            ],

            href: null
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            let { manages } = await api.v1.manage.gets({ params: { code: "currentLink" }});
            this.href = manages[0].link || "https://yfactory.startup-plus.kr/cms_for_bcb/process/tenant/program/list.do?show_no=8359&check_no=8354&c_relation=19&c_relation2=19"
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
