<template>
    <div class="board-container">
        <notification-list-filter v-model="query" />
        <table class="board-list mt-12 mt-md-20">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width: 6%">번호</th>
                    <th style="width: 64%">게시물 제목이 옵니다.</th>
                    <th style="width: 15%">작성자</th>
                    <th style="width: 15%">작성일</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="notification in fixed" :key="notification._id" class="board-list__info-row">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        <i class="icon icon-info"></i>
                    </td>
                    <td align="center" class="board-list__tit" @click="$router.push(`${$route.path}/${notification._id}`)">
                        <div class="ellip">{{ notification.subject }}</div>
                    </td>
                    <td align="center" class="board-list__txt">관리자</td>
                    <td align="center" class="board-list__txt">
                        {{ notification.createdAt.toDate() }}
                    </td>
                </tr>
                <tr v-for="(notification, index) in notifications" :key="notification._id">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ (page - 1) * limit + index + 1 }}
                    </td>
                    <td align="center" class="board-list__tit" @click="$router.push(`${$route.path}/${notification._id}`)">
                        <div class="ellip">{{ notification.subject }}</div>
                    </td>
                    <td align="center" class="board-list__txt">관리자</td>
                    <td align="center" class="board-list__txt">
                        {{ notification.createdAt.toDate() }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="20" />
        </div>
    </div>
</template>

<script>
import api from "@/api";

import NotificationListFilter from "./notification-list-filter.vue";

export default {
    components: {
        NotificationListFilter,
    },
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        fixed: [],
        notifications: [],

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        query: {
            isNotice: false,
            sites: "blacksmith",
        },

        loading: false,
    }),
    computed: {
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        params() {
            return { ...this.query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { notifications: fixed } = await api.v1.center.notifications.gets({
                    headers: { limit: 0 },
                    params: { isNotice: true, sites: "blacksmith" },
                });

                this.fixed = fixed;

                const { skip, limit, params } = this;
                let { summary, notifications } = await api.v1.center.notifications.gets({
                    headers: { skip, limit },
                    params,
                });

                this.notifications = notifications;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
