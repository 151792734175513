<template>
    <v-sheet>
        <table-primary caption>
            <table-row title="제목">
                <v-text-field v-model="question.subject" placeholder="제목을 입력하세요." outlined hide-details />
            </table-row>
            <table-row title="첨부파일">
                <v-file-input v-model="file" placeholder="파일을 첨부하세요." outlined hide-details />
            </table-row>
            <table-row title="문의내용">
                <naver-smarteditor v-model="question.content" @input="$emit('input', question)"></naver-smarteditor>
            </table-row>
        </table-primary>
        <div class="v-btn--group">
            <v-row justify="center" class="row--x-small">
                <v-col cols="auto">
                    <v-btn large outlined rounded color="grey lighten-4" @click="$router.go(-1)">
                        <span class="grey--text">취소</span>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn @click="save" large rounded dark color="secondary">
                        <span>작성하기</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import api from "@/api";
import TablePrimary from "@/components/dumb/table-primary.vue";
import TableRow from "@/components/dumb/table-row.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        TablePrimary,
        TableRow,
        NaverSmarteditor
    },
    data() {
        return {
            question: {
                subject: null,
                content: null,
            },
            file: null,
        };
    },
    methods:{
        async save() {
            try{
                let { question } = await api.v1.center.questions.post(this.question);
                if(this.file) await api.v1.center.questions.files.post({ _question: question._id }, this.file);
                alert("작성이 완료됐습니다.");
                this.$router.go(-1);
            } catch(err) {
                alert('로그인 후 이용가능합니다.');
            }
        }
    }
};
</script>
