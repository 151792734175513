<template>
    <v-sheet>
        <template v-if="isOnHoliday">
            <p class="page-text grey--text">운영하지 않는 날짜입니다</p>
        </template>
        <template v-else-if="isOnBusiness">
            <template v-if="subject.rental.type == RENTAL_TYPES.BY_DATE.value">
                <schedule-date
                    v-model="schedules"
                    v-bind="{
                        subject,

                        blockages,
                        schedulesOnCloud,

                        limitationMet,

                        _user,
                        date,

                        loading,
                    }"
                    @input="emit"
                />
            </template>
            <template v-if="subject.rental.type == RENTAL_TYPES.BY_HOUR.value">
                <v-row no-gutters>
                    <template v-for="(hour, index) in subject.rental.businessHours">
                        <schedule-hour
                            v-model="schedules"
                            v-bind="{
                                subject,

                                blockages,
                                schedulesOnCloud,

                                limitationMet,

                                _user,
                                date,
                                hour,

                                loading,
                            }"
                            :key="index"
                            @input="emit"
                        />
                    </template>
                </v-row>
            </template>
            <template v-if="subject.rental.type == RENTAL_TYPES.BY_TIME.value">
                <v-row no-gutters>
                    <template v-for="(time, index) in businessTimes">
                        <schedule-time
                            v-model="schedules"
                            v-bind="{
                                subject,

                                blockages,
                                schedulesOnCloud,

                                limitationMet,

                                _user,
                                date,
                                time,

                                loading,
                            }"
                            :key="index"
                            @input="emit"
                        />
                    </template>
                </v-row>
            </template>
        </template>
        <template v-else>
            <p class="page-text grey--text">운영하지 않는 요일입니다</p>
        </template>
    </v-sheet>
</template>

<script>
import dayjs from "dayjs";
import { initRentalSubject, RENTAL_TYPES } from "@/assets/variables";

import ScheduleDate from "./schedules/schedule-date.vue";
import ScheduleHour from "./schedules/schedule-hour.vue";
import ScheduleTime from "./schedules/schedule-time.vue";

export default {
    components: {
        ScheduleDate,
        ScheduleHour,
        ScheduleTime,
    },
    props: {
        value: { type: Array, default: () => [] }, // schedules

        date: { type: String, default: Date.now().toDate() },
        subject: { type: Object, default: initRentalSubject },

        blockages: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        schedules: [],

        RENTAL_TYPES,
    }),
    computed: {
        _user() {
            return this.$store.state.payload?._user;
        },
        isOnHoliday() {
            return (this.subject.rental.holidays || []).some((date) => dayjs(this.date).isSame(date, "date"));
        },
        isOnBusiness() {
            return (this.subject.rental.businessDays || []).includes(dayjs(this.date).day());
        },
        businessTimes() {
            const dateDay = this.$dayjs(this.date).day();
            return this.subject.rental.businessTimes.filter(({ day }) => day == dateDay);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.schedules = [...this.value];
        },
        emit() {
            this.$emit("input", this.schedules);
        },
    },
};
</script>



<style lang="scss" scoped>
::v-deep .v-btn:not(.v-btn--fab, .v-btn--icon).v-size--x-large {
    margin-top: 10px;
    height: 56px;
    border-radius: 16px;
    padding: 0 20px;
    justify-content: space-between;
    font-weight: 400;
    &.v-btn--active {
        background-color: var(--v-secondary-base);
        font-weight: 700;
        &::before {
            display: none;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep .v-btn:not(.v-btn--fab, .v-btn--icon).v-size--x-large {
        height: 74px;
        border-radius: 24px;
        padding: 0 36px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
