var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "tab-list tab-list--inline tab-list--underline justify-start mb-60 mb-md-90 mb-lg-120"
  }, [_c('li', {
    class: {
      on: _vm.category == 'unit'
    }
  }, [_c('router-link', {
    staticClass: "button",
    attrs: {
      "to": "/service/supports/unit"
    }
  }, [_c('span', [_vm._v("상시 지원 신청")])])], 1), _c('li', {
    class: {
      on: _vm.category == 'total'
    }
  }, [_c('router-link', {
    staticClass: "button",
    attrs: {
      "to": "/service/supports/total"
    }
  }, [_c('span', [_vm._v("공모 지원 신청")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }