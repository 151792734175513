<template>
    <client-page>
        <sub-visual sh="프로그램" tabActive="프로그램 안내" />

        <page-section title="프로그램 안내" class="page-section--first page-section--last">
            <v-row class="my-n20 my-md-n30">
                <v-col v-for="item in program" :key="item.title" cols="12" sm="6" md="4" class="py-20 py-md-30">
                    <v-layout fill-height justify-space-between column>
                        <div>
                            <v-img :src="item.image" max-width="440" class="w-100 rounded mb-20 md-md-40" />
                            <h4 class="tit mb-8 mb-md-16">{{ item.title }}</h4>
                            <ul class="page-text page-text--lg line-height-15 word-keep-all mb-n4 mb-md-n10">
                                <li v-for="detail in item.info" :key="detail" class="mb-4 mb-md-10">{{ detail }}</li>
                            </ul>
                        </div>
                        <div v-if="item.link" class="mt-12 mt-sm-20 mt-md-40">
                            <v-btn v-if="item.blank" :href="item.link" target="_blank" rounded outlined color="secondary">신청하기</v-btn>
                            <v-btn v-else :to="item.link" rounded outlined color="secondary">신청하기</v-btn>
                        </div>
                    </v-layout>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import api from '@/api';
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data() {
        return {
            program: [
                {
                    title: "장비운영교육",
                    image: "/images/sub/program/information/information-img.jpg",
                    info: ["[비기너클래스] 입문자 대상 온라인 장비교육 진행", "[미들클래스] 자기주도적 장비활용 프로젝트", "[전문제조장비 클래스] 시장판매 제품 제조목적의 장비 교육"],
                    link: "/program/list",
                },
                {
                    title: "기술제조기반 교육 지원",
                    image: "/images/sub/program/information/information-img2.jpg",
                    info: ["석박사 전문인력(예비창업자), 하드웨어 중소-스타트업을 대상으로 전자제조+데이터 융합 교육 지원"],
                    link: "/program/list",
                },
                {
                    title: "전자제조 초도양산",
                    image: "/images/sub/program/information/information-img3.jpg",
                    info: ["서울 용산시제품제작소의 전자제조 장비와 전문엔지니어가 공동개발, 전자보드 제조지원", "회로설계 → 시제품보드생산 → 펌웨어개발 → 초도양산"],
                    link: "https://yfactory.startup-plus.kr/cms_for_bcb/process/propose/program/list.do?show_no=8361&check_no=8354&c_relation=10&c_relation2=9",
                    blank: true,
                },
                {
                    title: "3D모델링지원",
                    image: "/images/sub/program/information/information-img4.jpg",
                    info: ["[직접지원] 디자인, 기구설계, 금형설계 기업과 공동으로 진행", "[간접지원] 사출제작 : 금형설계 완료 후 전문기업에 의뢰"],
                    link: "https://yfactory.startup-plus.kr/cms_for_bcb/process/propose/program/list.do?show_no=8361&check_no=8354&c_relation=10&c_relation2=9",
                    blank: true,
                },
                {
                    title: "투자 및 마케팅 지원",
                    image: "/images/sub/program/information/information-img5.jpg",
                    info: ["[피칭경연] VC와 협력하여 제품 컨설팅, 평가, 수상 지원", "[홍보마케팅] e홍보물, 영상제작, 온라인 플랫폼 입점 지원"],
                    link: "https://yfactory.startup-plus.kr/cms_for_bcb/process/propose/program/list.do?show_no=8361&check_no=8354&c_relation=10&c_relation2=9",
                    blank: true,
                },
            ],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { manages } = await api.v1.manage.gets({ params: { code: 'program' }});
            manages.forEach((manage) => {
            const matchingCard = this.program.find((programItem) => programItem.title === manage.subject);
            if (matchingCard) {
                matchingCard.link = manage.link;
            }
        });

        }
    }
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    ::v-deep {
        .v-btn.v-btn--outlined.secondary--text:hover {
            background-color: var(--v-secondary-base) !important;
            color: #fff !important;
        }
    }
}
@media (min-width: 1200px) {
}
</style>
