<template>
    <div role="table" class="board-table board-table--input">
        <div role="rowgroup">
            <input-row label="이름" pointer>
                <input-item v-model="applicant.name" @input="sync" />
            </input-row>
            <input-row label="학교/학과/과정" pointer>
                <v-row>
                    <v-col cols="4"> <input-item v-model="applicant.affiliation" placeholder="학교" @input="sync" /> </v-col>
                    <v-col cols="4"> <input-item v-model="applicant.department" placeholder="학과" @input="sync" /> </v-col>
                    <v-col cols="4"> <input-item v-model="applicant.courseName" placeholder="과정" @input="sync" /> </v-col>
                </v-row>
            </input-row>
            <input-row label="국가연구자번호">
                <input-item v-model="applicant.researcherNo" placeholder="과학기술인등록번호" @input="sync" />
            </input-row>
            <input-row label="생년월일" pointer>
                <!-- <div class="form-birth">
                    <input-birth mode="년" v-model="applicant.bornY" @input="sync" />
                    <input-birth mode="월" v-model="applicant.bornM" @input="sync" />
                    <input-birth mode="일" v-model="applicant.bornD" :year="applicant.bornY" :month="applicant.bornM" @input="sync" />
                </div> -->
                <v-row class="row--small">
                    <v-col cols="4">
                        <input-birth class="w-100" mode="년" v-model="applicant.bornY" @input="sync" />
                    </v-col>
                    <v-col cols="4">
                        <input-birth class="w-100" mode="월" v-model="applicant.bornM" @input="sync" />
                    </v-col>
                    <v-col cols="4">
                        <input-birth class="w-100" mode="일" v-model="applicant.bornD" :year="applicant.bornY" :month="applicant.bornM" @input="sync" />
                    </v-col>
                </v-row>
            </input-row>
            <input-row label="연락처" pointer>
                <!-- <div class="form-tel">
                    <input class="input" v-model="applicant.phone1" type="tel" name="phone1" maxlength="4" @input="sync" />
                    <span class="text"></span>
                    <input class="input" v-model="applicant.phone2" type="tel" name="phone2" maxlength="4" @input="sync" />
                    <span class="text"></span>
                    <input class="input" v-model="applicant.phone3" type="tel" name="phone3" maxlength="4" @input="sync" />
                </div> -->
                <v-row align="center" class="row--x-small">
                    <v-col>
                        <input class="w-100" v-model="applicant.phone1" type="tel" name="phone1" maxlength="4" @input="sync" />
                    </v-col>
                    <v-col cols="auto">-</v-col>
                    <v-col>
                        <input class="w-100" v-model="applicant.phone2" type="tel" name="phone2" maxlength="4" @input="sync" />
                    </v-col>
                    <v-col cols="auto">-</v-col>
                    <v-col>
                        <input class="w-100" v-model="applicant.phone3" type="tel" name="phone3" maxlength="4" @input="sync" />
                    </v-col>
                </v-row>
            </input-row>
            <input-row label="이메일" pointer>
                <input-email v-model="applicant" @input="sync" />
            </input-row>
            <input-row label="신청교육과정명">
                <span>{{ program?.category?.name }} > {{ program?.name }}</span>
            </input-row>
        </div>
    </div>
</template>

<script>
import InputRow from "@/components/client/dumb/input-row.vue";
import InputItem from "@/components/client/dumb/input-item.vue";
import InputBirth from "@/components/client/dumb/input-birth.vue";
import InputEmail from "@/components/client/dumb/input-email.vue";
export default {
    components: {
        InputRow,
        InputItem,
        InputBirth,
        InputEmail,
    },
    props: ["user", "value", "program"],
    data() {
        return {
            applicant: {},
        };
    },
    computed: {
        bornAt() {
            const { bornY, bornM, bornD } = this.applicant;
            return `${bornY}-${bornM}-${bornD}`;
        },
        phone() {
            const { phone1, phone2, phone3 } = this.applicant;

            return `${phone1}-${phone2}-${phone3}`;
        },
        email() {
            const { email1, email2 } = this.applicant;

            return `${email1}@${email2}`;
        },
        computedApplication() {
            const { bornAt, phone, email } = this;
            return { ...this.applicant, bornAt, email, phone };
        },
    },
    watch: {
        user() {
            if (this.user) this.init();
        },
    },
    methods: {
        init() {
            const { user } = this;

            const [phone1, phone2, phone3] = user.phone?.split("-");
            const [email1, email2] = user.email?.split("@");
            const [bornY, bornM, bornD] = user?.bornAt?.toDate?.()?.split?.("-") || [].map((value) => +value);

            this.applicant = {
                _user: user._id,
                name: user.name,
                affiliation: user.affiliation,
                department: "",
                courseName: "",
                researcherNo: "",
                bornY,
                bornM,
                bornD,
                phone1,
                phone2,
                phone3,
                email1,
                email2,
            };
            this.sync();
        },
        sync() {
            this.$emit("input", this.computedApplication);
        },
    },
};
</script>

<style></style>
