var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', _vm._b({
    staticClass: "v-tabs--primary",
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.placeIndex,
      callback: function ($$v) {
        _vm.placeIndex = $$v;
      },
      expression: "placeIndex"
    }
  }, 'v-tabs', _vm.$attrs, false), [_vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
      value = _ref.value;
    return [_c('v-tab', _vm._b({
      key: value,
      attrs: {
        "exact": ""
      }
    }, 'v-tab', {
      value
    }, false), [_vm._v(_vm._s(text))])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }