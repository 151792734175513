var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-table board-table--input",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "지원서비스명"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.programTitle))])]), _c('input-row', {
    attrs: {
      "label": "기업명",
      "pointer": ""
    }
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "companyName", $$v);
      },
      expression: "applicant.companyName"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "대표자명",
      "pointer": ""
    }
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.ceoName,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "ceoName", $$v);
      },
      expression: "applicant.ceoName"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "사업자등록번호",
      "pointer": ""
    }
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.businessNumber,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "businessNumber", $$v);
      },
      expression: "applicant.businessNumber"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "기업설립일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-birth', {
    staticClass: "w-100",
    attrs: {
      "mode": "년"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.establishedY,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "establishedY", $$v);
      },
      expression: "applicant.establishedY"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-birth', {
    staticClass: "w-100",
    attrs: {
      "mode": "월"
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.establishedM,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "establishedM", $$v);
      },
      expression: "applicant.establishedM"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('input-birth', {
    staticClass: "w-100",
    attrs: {
      "mode": "일",
      "year": _vm.applicant.establishedY,
      "month": _vm.applicant.establishedM
    },
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.establishedD,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "establishedD", $$v);
      },
      expression: "applicant.establishedD"
    }
  })], 1)], 1)], 1), _c('input-row', {
    attrs: {
      "label": "연락처",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.phone1,
      expression: "applicant.phone1"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "tel",
      "name": "phone1",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.applicant.phone1
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "phone1", $event.target.value);
      }, _vm.sync]
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.phone2,
      expression: "applicant.phone2"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "tel",
      "name": "phone2",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.applicant.phone2
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "phone2", $event.target.value);
      }, _vm.sync]
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.applicant.phone3,
      expression: "applicant.phone3"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "tel",
      "name": "phone3",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.applicant.phone3
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.applicant, "phone3", $event.target.value);
      }, _vm.sync]
    }
  })])], 1)], 1), _c('input-row', {
    attrs: {
      "label": "이메일",
      "pointer": ""
    }
  }, [_c('input-email', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant,
      callback: function ($$v) {
        _vm.applicant = $$v;
      },
      expression: "applicant"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "소재지",
      "pointer": ""
    }
  }, [_c('input-address', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant,
      callback: function ($$v) {
        _vm.applicant = $$v;
      },
      expression: "applicant"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "홈페이지 주소"
    }
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.website,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "website", $$v);
      },
      expression: "applicant.website"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "담당자명/직책"
    }
  }, [_c('div', {
    staticClass: "row row--small"
  }, [_c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.workerName,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "workerName", $$v);
      },
      expression: "applicant.workerName"
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.workerTitle,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "workerTitle", $$v);
      },
      expression: "applicant.workerTitle"
    }
  })], 1)])]), _c('input-row', {
    attrs: {
      "label": "주요생산제품"
    }
  }, [_c('input-item', {
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.applicant.mainProduct,
      callback: function ($$v) {
        _vm.$set(_vm.applicant, "mainProduct", $$v);
      },
      expression: "applicant.mainProduct"
    }
  })], 1), _c('input-row', {
    attrs: {
      "label": "파일첨부",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-file-btn', {
    staticClass: "input-btn min-w-80px min-w-lg-100px color-white",
    attrs: {
      "color": "grey-dark",
      "multiple": "",
      "elevation": "0"
    },
    model: {
      value: _vm.files,
      callback: function ($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }, [_vm._v("파일첨부")])], 1), _c('v-col', [_vm.files.length ? _c('v-chip-group', {
    attrs: {
      "multiple": "",
      "column": ""
    },
    model: {
      value: _vm.files,
      callback: function ($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }, _vm._l(_vm.files, function (file) {
    return _c('v-chip', {
      key: file.name,
      attrs: {
        "value": file,
        "close": ""
      }
    }, [_vm._v(_vm._s(file.name))]);
  }), 1) : _c('span', [_vm._v("첨부된 파일이 없습니다.")])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }