<template>
    <v-sheet>
        <page-section small>
            <div class="floor-image">
                <v-img src="/images/sub/about/facilities/facilities-img.svg" max-width="1360" :aspect-ratio="1360 / 350" class="w-100"/>
                <div class="floor-image__btns">
                    <button v-for="(imageTab, index) in imageTabs" :key="index"
                    :style="'width: '+imageTab.width+'%; height: '+imageTab.height+'%; top: '+imageTab.top+'%; left: '+imageTab.left+'%;'"
                    @click="activeTabIndex = index"></button>
                </div>
            </div>
        </page-section>
        <page-section small class="page-section--sm--last">
            <v-row class="row--x-large">
                <v-col cols="12" lg="auto">
                    <v-sheet class="w-lg-420px">
                        <div class="w-100 grey lighten-5 mb-16 mb-lg-24 py-8 px-10" style="border-radius: 999px">
                            <v-row no-gutters align="center">
                                <v-col cols="auto">
                                    <i class="icon icon-error" />
                                </v-col>
                                <v-col>
                                    <p class="page-text grey--text text--lighten-1 pl-4">해당 위치를 클릭하시면 상세정보를 보실 수 있습니다.</p>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row class="flex-lg-row-reverse">
                            <v-col cols="12" lg="6">
                                <v-row class="row--small">
                                    <v-col v-for="(info, index) in infos" :key="index" cols="auto" lg="12">
                                        <btn-idx disabled :idx="info.idx" :label="info.label" idxStlye="primary lighten-5 primary--text" />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-row class="row--small">
                                    <v-col v-for="(tab, index) in tabs" :key="index" cols="auto" lg="12">
                                        <btn-idx :idx="tab.idx" :label="tab.label" idxStlye="primary white--text" @click="activeTabIndex = index" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col lg="auto" class="d-none d-lg-block">
                    <v-divider vertical />
                </v-col>
                <v-col cols="12" lg="">
                    <ul class="tab-contents">
                        <li v-for="(content, index) in contents" :key="index" :class="{ on: activeTabIndex === index }">
                            <template v-if="Array.isArray(content.image)">
                                <v-row>
                                    <v-col cols="6" v-for="image in content.image" :key="image">
                                        <v-img :key="image" :src="image" max-width="420" :aspect-ratio="1 / 1" class="w-100 rounded-sm" />
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-img :src="content.image" max-width="820" :aspect-ratio="820 / 480" class="w-100 rounded-sm" />
                            </template>
                            <v-divider class="secondary border-2 w-40px my-20 my-md-40" />
                            <h4 class="font-size-20 font-size-md-26 font-size-lg-36 font-weight-medium mb-12 mb-md-20">{{content.title}}</h4>
                            <p v-html="content.info" class="page-text page-text--lg line-height-15 grey--text word-keep-all"></p>
                            <v-card v-if="content.details" flat rounded="xs" class="grey lighten-5 mt-12 mt-md-20">
                                <div class="pa-16 pa-md-24">
                                    <p class="page-text page-text--lg font-weight-bold secondary--text line-height-1 mb-8 mb-md-16">
                                        보유장비
                                    </p>
                                    <p class="page-text line-height-15 grey--text word-keep-all">
                                        {{content.details}}
                                    </p>
                                </div>
                            </v-card>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </page-section>
    </v-sheet>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";
import BtnIdx from "@/components/client/sub/btn-idx.vue";
export default {
    components: {
        PageSection,
        BtnIdx,
    },
    data() {
        return {
            activeTabIndex: 0,
            imageTabs: [
                { width: '7.352941176', height: '28', top: '2', left: '28.16176471' },
                { width: '12.79411765', height: '13.14286', top: '2', left: '35.51470588' },
                { width: '7.352941176', height: '13.14286', top: '2', left: '48.30882353' },
                { width: '12.20588235', height: '10.28571', top: '43.71428571', left: '45.29411765' },
                { width: '14.11764706', height: '11.42857', top: '28.85714286', left: '43.38235294' },
                { width: '3.382352941', height: '25.14286', top: '28.85714286', left: '57.5' },
                { width: '14.11764706', height: '19.42857', top: '66', left: '38.23529412' },
            ],
            tabs: [
                { idx: 'A', label: '소형금속가공 / 3D모델링지원룸' },
                { idx: 'B', label: '3D 프린터룸' },
                { idx: 'C', label: '섬유가공룸' },
                { idx: 'D', label: 'UV프린터룸' },
                { idx: 'E', label: '레이저 장비룸' },
                { idx: 'F', label: '교육실' },
                { idx: 'G', label: '목공장비룸' },
            ],
            infos: [
                { idx: '1', label: '스튜디오' },
                { idx: '2', label: '입주기업 미팅라운지' },
                { idx: '3', label: '입주기업 공용 OA' },
                { idx: '4', label: '운영사무실' },
                { idx: '5', label: '회의실' },
                { idx: '6', label: '208호' },
                { idx: '7', label: '207호' },
                { idx: '8', label: '206호' },
                { idx: '9', label: '205호' },
                { idx: '10', label: '201호' },
                { idx: '11', label: '202호' },
                { idx: '12', label: '203호' },
                { idx: '13', label: '204호' },
                { idx: '14', label: '기자재실' },
            ],
            contents: [
                {
                    title: '소형금속가공 / 3D모델링지원룸',
                    image: ["/images/sub/about/facilities/facilities-2f-1-2.jpg", "/images/sub/about/facilities/facilities-2f-1-1.jpg"],
                    info: '소형 금속공예제품을 제작할 수 있는 드릴프레스, 밀링, 밴드쏘, 그라인더 보유<br /> 스타트업·중소기업의 전자제품 목업을 지원하는 3D모델링장비 보유',
                    // details: '소형밀링머신, 소형선반머신, 드릴프레스, 금속용 밴드쏘, 금속용 그라인더, 금속용 밴드쏘, TIG용접기, MIG용접기, 플라즈마절단기, 용접용 테이블, 플라즈마절단용 테이블, 드릴프레스, 고속절단기, 배연기, 공구작업테이블',
                },
                {
                    title: '3D프린터룸',
                    image: '/images/sub/about/facilities/facilities-2f-2.jpg',
                    info: '개인이 직접 시제품을 제작할 수 있는 FDM 3D프린터, 대형 FDM 3D프린터, 소형 PCB 프린터 보유',
                    // details: 'FDM 3D프린터, 3dwox 7, SLA 3D프린터, UV경화기',
                },
                {
                    title: '섬유가공룸',
                    image: '/images/sub/about/facilities/facilities-2f-3.jpg',
                    info: '공업용재봉틀, 오버로크기, 공업사절기 보유',
                    // details: '오버로크, 공업용재봉틀, 가정용재봉틀, CNC자수기, CNC비닐커터, 자동사절기',
                },
                {
                    title: 'UV프린터룸',
                    image: '/images/sub/about/facilities/facilities-2f-4.jpg',
                    info: 'UV프린터, 메탈레이저마킹기, 전자제어(인두기) 보유',
                    // details: '레이저커팅기, 진공성형기, UV평판프린터',
                },
                {
                    title: '레이저 장비룸',
                    image: '/images/sub/about/facilities/facilities-2f-5.jpg',
                    info: '레이저커팅기, 레이저마킹기, 진공성형기 보유',
                    // details: '메탈마킹레이저, 전기물레, 전기가마',
                },
                {
                    title: '교육실',
                    image: '/images/sub/about/facilities/facilities-2f-6.jpg',
                    info: '메이커 활동공간과 더불어 메이커 관련 교육, 행사, 특강 등 다양한 행사 및 프로그램을 진행하는 다목적 이용 공간입니다.',
                },
                {
                    title: '목공장비룸',
                    image: '/images/sub/about/facilities/facilities-2f-7.jpg',
                    info: '테이블쏘, 밴드쏘, 각도절단기, 드릴프레스, 스크롤쏘, 선반, 벨트샌더 보유',
                    // details: 'CNC절삭가공기, 드릴링머신, 연마기, 스크롤쏘, 테이블쏘, 전동절단톱, 밴드쏘, 수압대패',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.floor-image{
    position: relative;
    &__btns{
        button{
            display: block;
            position: absolute;
            // opacity: 0.5;
            // &:nth-child(1){
            //     background: red;
            // }
            // &:nth-child(2){
            //     background: orange;
            // }
            // &:nth-child(3){
            //     background: yellow;
            // }
            // &:nth-child(4){
            //     background: green;
            // }
            // &:nth-child(5){
            //     background: blue;
            // }
            // &:nth-child(6){
            //     background: navy;
            // }
            // &:nth-child(7){
            //     background: violet;
            // }
        }
    }
}
</style>