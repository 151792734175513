var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('v-row', {
    staticClass: "row--xx-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-md-100px",
    attrs: {
      "items": _vm.filterItems,
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-md-240px",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "placeholder": "검색어를 입력하세요."
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "input-btn h-100",
    attrs: {
      "dark": "",
      "color": "grey"
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v("검색")])], 1)], 1), _c('table', {
    staticClass: "board-list mt-12 mt-md-20"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.proposalList, function (proposal, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + index + 1) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.view(proposal);
        }
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(proposal.subject))])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(proposal.user.name) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(proposal.createdAt.toDate()) + " ")])]);
  }), 0)]), _c('v-layout', {
    staticClass: "mt-24",
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('span', {
    staticClass: "font-size-14",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/community/proposal?mode=input');
      }
    }
  }, [_vm._v("문의하기")])])], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "6%"
    }
  }, [_vm._v("번호")]), _c('th', {
    staticStyle: {
      "width": "64%"
    }
  }, [_vm._v("게시물 제목이 옵니다.")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("작성일")])])]);

}]

export { render, staticRenderFns }