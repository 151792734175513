var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('notification-list-filter', {
    model: {
      value: _vm.query,
      callback: function ($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }), _c('table', {
    staticClass: "board-list mt-12 mt-md-20"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.fixed, function (notification) {
    return _c('tr', {
      key: notification._id,
      staticClass: "board-list__info-row"
    }, [_vm._m(1, true), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(notification.subject))])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v("관리자")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")])]);
  }), _vm._l(_vm.notifications, function (notification, index) {
    return _c('tr', {
      key: notification._id
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + index + 1) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(notification.subject))])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v("관리자")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 20
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "6%"
    }
  }, [_vm._v("번호")]), _c('th', {
    staticStyle: {
      "width": "64%"
    }
  }, [_vm._v("게시물 제목이 옵니다.")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("작성일")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_c('i', {
    staticClass: "icon icon-info"
  })]);

}]

export { render, staticRenderFns }