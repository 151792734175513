<template></template>

<script>
import api from "@/api";
import { initRentalLimitation, initRentalSubject, RENTAL_LIMITATION_TARGET_TYPES, RENTAL_LIMITATION_TYPES, RENTAL_SCHEDULE_STATES, RENTAL_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Boolean, default: false },

        date: { type: String },
        subject: { type: Object, default: initRentalSubject },
        schedules: { type: Array, default: () => [] },
    },
    data: () => ({
        limitation__site: initRentalLimitation(),
        countOnCloud: 0,
    }),
    computed: {
        limitation() {
            return initRentalLimitation(this.subject.limitationPerDate ?? this.limitation__site);
        },
        dateStart() {
            return this.$dayjs(this.date).startOf("day").toDate();
        },
        dateEnd() {
            return this.$dayjs(this.date).endOf("day").toDate();
        },
        countMaximum() {
            switch (this.subject.rental.type) {
                case RENTAL_TYPES.BY_DATE.value: {
                    return this.limitation.dates;
                }
                case RENTAL_TYPES.BY_HOUR.value: {
                    return this.limitation.hours;
                }
                case RENTAL_TYPES.BY_TIME.value: {
                    return this.limitation.times;
                }
                default: {
                    return undefined;
                }
            }
        },
        countSelected() {
            if (this.limitation) {
                return this.schedules.reduce((count, { date }) => {
                    let isAfterStart = !this.$dayjs(date).isBefore(this.dateStart, "date");
                    let isBeforeEnd = !this.$dayjs(date).isAfter(this.dateEnd, "date");
                    if (isAfterStart && isBeforeEnd) count += 1;
                    return count;
                }, 0);
            } else return 0;
        },
        limitationMet() {
            if (this.countMaximum) {
                if (this.countMaximum <= this.countSelected + this.countOnCloud) return true;
            }
            return false;
        },
    },
    mounted() {
        this.getLimitation().then(this.getSchedules);
    },
    watch: {
        "subject._id"() {
            this.getLimitation();
        },
        dateStart() {
            this.getSchedules();
        },
        limitationMet() {
            if (this.value != this.limitationMet) this.$emit("input", this.limitationMet);
        },
    },
    methods: {
        async getLimitation() {
            const { limitation } = await api.v1.rental.limitations.get({
                type: RENTAL_LIMITATION_TYPES.PER_DATE.value,
                targetType: RENTAL_LIMITATION_TARGET_TYPES.ENTIRE_ASSET.value,
            });
            this.limitation__site = initRentalLimitation(limitation);
        },

        async getSchedules() {
            if (this.limitation._id && this.$store.state.payload?._user) {
                let { APPLIED, APPROVED } = RENTAL_SCHEDULE_STATES;

                let { summary } = await api.v1.me.rental.schedules.gets({
                    params: {
                        dates: [this.dateStart, this.dateEnd],
                        state: [APPLIED.value, APPROVED.value],
                        rentalType: this.subject.rental.type,
                        onlyCount: true,
                    },
                });

                this.countOnCloud = summary?.totalCount || 0;
            }
        },
    },
};
</script>

<style></style>
